<template>
  <!-- discount code -->
  <b-card
    no-body
    :border-variant="color"
    header="Coupon Code"
    :header-bg-variant="color"
  >
    <b-card-text v-if="!discount || !discount.id" class="m-3">
      Enter a promotion or discount code below if you have one.
      <hr />
      <b-form-group label="Code">
        <b-input-group>
          <b-form-input
            type="text"
            v-model.lazy="code"
            :state="!codeValid && codeErrorMessage ? false : null"
            placeholder="Enter Code"
            :disabled="searchCode"
            @input="
              codeValid = false;
              codeErrorMessage = null;
            "
          ></b-form-input>
          <b-input-group-append v-show="!searchCode">
            <b-button
              variant="info"
              :disabled="!code || !code.length"
              @click.prevent="checkCode"
              >Check Code</b-button
            >
          </b-input-group-append>

          <b-input-group-append v-show="searchCode">
            <b-input-group-text>
              <b-icon icon="arrow-repeat" animation="spin"></b-icon>
            </b-input-group-text>
          </b-input-group-append>

          <b-form-invalid-feedback v-if="!codeValid">
            {{ codeErrorMessage }}
          </b-form-invalid-feedback>
        </b-input-group>
      </b-form-group>
    </b-card-text>

    <b-card-text v-if="discount && discount.id" class="m-3">
      <b-row>
        <b-col>
          <h3>
            {{ discount.discount.name }}
            <button
              type="button"
              class="btn btn-sm btn-danger"
              @click="clearDiscount()"
            >
              <span class="glyphicon glyphicon-remove"></span> Remove
            </button>
          </h3>
          <hr />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          {{ discount.discount.percent }}% off your subscription!
          <div v-if="discount.discount.startdate !== null">
            This code will create a subscription that will be valid from
            {{ discount.discount.startdate }} to {{ discount.discount.enddate }}
          </div>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>
<script>
import AccountService from '@/services/AccountService'

export default {
  name: 'Info',
  props: {
    discount: {
      type: Object,
      default: null
    },
    subscriptiontype_id: Number,
    balance: {
      type: Number,
      default: 0
    },
    color: {
      type: String,
      default: 'default'
    }
  },
  data: () => {
    return {
      code: null,
      codeValid: true,
      codeErrorMessage: null,
      searchCode: false
    }
  },
  methods: {
    checkCode () {
      if (this.searchCode || !this.code || !this.code.length) {
        return false
      }
      this.searchCode = true
      AccountService.checkCode(this.code, this.subscriptiontype_id, +this.balance).then(
        (response) => {
          // this.discount = response.data
          this.codeValid = true
          this.$emit('discountApplied', response.data)
        }).catch((err) => {
        this.codeValid = false
        this.codeErrorMessage = err.response.data.error[0]
      }).finally(() => {
        this.searchCode = false
      })
    },
    clearDiscount () {
      this.codeValid = false
      this.$emit('discountApplied', {})
    }
  }
}
</script>
