<style scoped>
.wrapper {
  width: 100%;
  padding: 5vh 0;
  text-align: center;
}
.loading-spinner {
  width: 3rem;
  height: 3rem;
}
</style>
<template>
  <div v-if="loading" class="wrapper">
    <b-spinner variant="success" class="loading-spinner"></b-spinner>
  </div>
</template>
<script>
export default {
  name: 'Loading',
  props: {
    loading: {
      type: [Boolean, Number],
      default: false
    }
  }
}
</script>
