
<template>
  <div>
    <b-button
      variant="success"
      type="button"
      @click.prevent="openModal = true"
    >
      {{ !subscription || !subscription.current ? 'Choose' : 'Switch My' }} Subscription
    </b-button>

    <b-modal
      id="subscription-change-modal"
      v-model="openModal"
      no-close-on-backdrop
      no-close-on-esc
      title="Change My Subscription"
      ok-title="Change Subscription"
      ok-variant="success"
      :ok-disabled="loading || !isValid"
      size="xl"
      @ok="confirmChange"
      @show="init()"
    >
      <Loading :loading="loading"></Loading>
      <div v-if="!loading">
        <b-card
          v-if="subscriptiontype && subscriptiontype.id"
          no-body
        >
          <b-card-header>
            <h4 class="m-0">New Subscription Info:</h4>
          </b-card-header>
          <b-card-text class="m-3">
            <SubscriptionTypeInfo :subscriptiontype="subscriptiontype"></SubscriptionTypeInfo>
          </b-card-text>
        </b-card>

        <Breakdown
          :subscription="newsubscription"
          :discount="discount"
          :loading="calculating"
          class="mt-3"
        ></Breakdown>

        <!-- type selection if we arrive here without one selected already -->
        <SelectType
          v-if="!subscriptiontype || !subscriptiontype.id"
          :types="subscriptiontypes"
          :selected="subscriptiontype"
          @select="setSubscriptionType"
        ></SelectType>

        <DiscountCode
          v-if="(amountDue > 0 || discount) && subscriptiontype && subscriptiontype.id"
          :discount="discount"
          :subscriptiontype_id="subscriptiontype.id"
          :balance="amountDue"
          class="mt-3"
          @discountApplied="applyDiscount"
        ></DiscountCode>

        <b-row class="my-3 mx-1">
          <b-col
            class="alert p-3"
            :class="(amountDue > 0) ? 'alert-danger' : 'alert-success'"
          >
            <h3>Amount Due: {{ amountDueFormatted }}</h3>
          </b-col>
        </b-row>

        <b-card v-if="amountDue > 0">
          <BillingList
            :states="states"
            :countries="countries"
            :selected="billing"
            @select="setBilling"
          ></BillingList>
        </b-card>
      </div>
    </b-modal>

    <ChangeConfirm
      v-if="newsubscription && confirm"
      :open="openConfirm"
      :subscription="newsubscription"
      @refresh="$emit('refresh')"
      @close="openConfirm = false"
    ></ChangeConfirm>
  </div>
</template>

<script>
import SubscriptionTypeInfo from '@/components/subscriptiontypes/Info'
import SelectType from '@/components/subscriptions/SelectType'
import DiscountCode from '@/components/billing/DiscountCode'
import BillingList from '@/components/billing/List'
import ChangeConfirm from '@/components/subscriptions/ChangeConfirm'
import SubscriptionService from '@/services/SubscriptionService'
import Loading from '@/components/widget/Loading'
import Breakdown from '@/components/billing/Breakdown'

export default {
  name: 'Change',
  props: {
    subscription: Object,
    subscriptiontype: {
      type: Object,
      default () { return null }
    },
    status: {
      type: Object,
      default () { return null }
    },
    types: Array,
    states: Array,
    countries: Array
  },
  components: {
    SubscriptionTypeInfo,
    SelectType,
    DiscountCode,
    BillingList,
    ChangeConfirm,
    Loading,
    Breakdown
  },
  data () {
    return {
      openModal: false,
      openConfirm: false,
      confirm: false,
      loading: false,
      calculating: false,
      discount: null,
      newsubscription: null,
      billing: {}
    }
  },

  methods: {
    init (skipLoader) {
      if (!skipLoader) {
        this.loading = true
      }
      this.calculating = true
      SubscriptionService.changeInit(this.subscription ? this.subscription.id : null, this.subscriptiontype.id, this.discount).then(
        (response) => {
          this.newsubscription = response.data
        }
      ).catch((error) => {
        this.$aim.error(error.response)
      }).finally(() => {
        this.loading = false
        this.calculating = false
      })
    },

    confirmChange () {
      if (this.isValid) {
        this.newsubscription.billing = this.billing
        if (!this.newsubscription.subscriptiontype) {
          this.newsubscription.subscriptiontype = this.subscriptiontype
        }
        if (this.discount) {
          this.newsubscription.discount = this.discount
        }
        this.confirm = true
        this.$nextTick(() => {
          this.openConfirm = true
        })
      }
    },

    setBilling (info) {
      this.billing = info
    },

    setSubscriptionType (type) {
      this.newsubscription.subscriptiontype = type
      this.$nextTick(() => {
        this.init(true)
      })
    },

    applyDiscount (discount) {
      this.discount = discount
      this.$nextTick(() => {
        this.init(true)
      })
    }
  },

  computed: {
    /** @returns {Number} */
    amountDue () {
      let amt = 0
      if (this.newsubscription && this.discount && this.discount.id > 0) {
        amt = parseFloat(this.discount.balancedue.amount)
      } else if (this.newsubscription) {
        amt = parseFloat(this.newsubscription.billingamount)
      } else {
        amt = this.subscriptiontype.amount
      }
      return amt
    },

    /** @returns {String} */
    amountDueFormatted () {
      let amt = 0
      if (this.newsubscription && this.discount && this.discount.id > 0) {
        if (this.discount.balancedue.amount > 0) {
          amt = this.discount.balancedue.localcurrencyformatted
        } else {
          amt = this.discount.subscriptiontype.localcurrencyformatted
        }
      } else if (this.newsubscription && this.newsubscription.localbillingamount) {
        amt = this.newsubscription.localbillingamount.localcurrencyformatted
      } else {
        amt = this.subscriptiontype.localcurrencyformatted
      }
      return amt
    },

    isValid () {
      return (this.newsubscription && this.newsubscription.subscriptiontype_id > 0 && (this.amountDue <= 0 || (this.billing && this.billing.id)))
    }
  }
}
</script>
