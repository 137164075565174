<template>
  <b-row class="h-100 w-100" align-h="center" >
    <b-col class="text-center h-100 w-100 mx-auto" no-gutters align-self="center">
      <a href="//sbnbroadcasting.com/">
        <img
          src="/img/inactive.png"
          alt="Network Disabled"
          style="height: 100vh;"
        ></a>
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'Inactive'
}
</script>
