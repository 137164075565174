<template>
  <component id="app" :is="layout" class="container-fluid">
    <notifications
      group="errors"
      :ignoreDuplicates="true"
      :max="1"
      role="status"
    ></notifications>
    <notifications
      group="notices"
      :ignoreDuplicates="true"
      :max="1"
      role="status"
    ></notifications>
    <transition :name="transitionName">
      <router-view />
    </transition>
  </component>
</template>

<script>
// import UserService from '@/services/UserService'
// import LivestreamService from '@/services/LivestreamService'
// import { bus } from '@/main'

export default {
  name: 'app',
  data () {
    return {
      transitionName: null,
      intervalTimer: 180000 // 3 minutes
    }
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || 'default') + '-layout'
    }
  },

  created () {
    /* COMMENTED OUT ISSUE 106331 :: REJECTED */
    // this.popup()
    // this.timer()

    // bus.$on('loggedIn', () => {
    //   this.popup()
    // })
  },

  methods: {
    /* COMMENTED OUT ISSUE 106331 :: REJECTED */
    // popup () {
    //   UserService.getUserInfo().then(
    //     user => {
    //       if (user && user.apikey !== null) {
    //         LivestreamService.getSchedule({ now: true }).then(
    //           (response) => {
    //             let viewed = UserService.getStorage('viewedstreams')

    //             if (!viewed || viewed.length === 0) {
    //               viewed = []
    //             }

    //             if (response.data.results.length > 0) {
    //               let first = true
    //               response.data.results.every(e => {
    //                 if (!viewed.includes(e.id)) {
    //                   viewed.push(e.id)
    //                   this.$bvModal.msgBoxConfirm(e.name, {
    //                     title: 'Event is now available to view',
    //                     size: 'md',
    //                     okTitle: 'Watch Now',
    //                     okVariant: 'success',
    //                     cancelTitle: 'No Thanks',
    //                     bodyClass: 'h4',
    //                     hideBackdrop: !first,
    //                     contentClass: 'biggerModal'
    //                   }).then(
    //                     (value) => {
    //                       if (value) {
    //                         window.location.href = e.livehref
    //                         return false
    //                       } else {
    //                         return true
    //                       }
    //                     }
    //                   )
    //                   first = false
    //                 }
    //                 return true
    //               })

    //               localStorage.setItem('viewedstreams', JSON.stringify(viewed))
    //             }
    //           }
    //         )
    //       }
    //     }
    //   )
    // },

    // timer () {
    //   const timer = setInterval(() => {
    //     const currentRoute = this.$route.path
    //     if (!currentRoute.includes('video/watch') && !currentRoute.includes('live')) {
    //       this.popup()
    //     }
    //   }, this.intervalTimer)

    //   this.$once('hook:beforeDestroy', () => {
    //     clearInterval(timer)
    //   })
    // }
  },

  // then, in the parent component,
  // watch the `$route` to determine the transition to use
  watch: {
    '$route' (to, from) {
      const toDepth = to.path.split('/').length
      const fromDepth = from.path.split('/').length
      this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
    }
  }

}
</script>
<style lang="scss">
@import "./app.scss";
</style>
