<template>
  <b-container
    fluid
    class="mx-0 px-0"
  >
    <b-row
      v-if="ccdisabled"
      class="text-center"
      align-v="center"
    >
      <b-col cols="2">
        <b-img
          v-if="creditcardtype"
          :src="`/img/cc/sm/${creditcardtype}.png`"
          alt="Card Type"
        ></b-img>
      </b-col>
      <b-col cols="5">
        <b-icon icon="credit-card"></b-icon>
        <strong class="ml-2">{{ cc.number }}</strong>
      </b-col>
      <b-col cols="3">
        <b-icon icon="calendar"></b-icon>
        <strong class="ml-2">{{ cc.expiry }}</strong>
      </b-col>
      <!-- <b-col>
        <b-button
          type="button"
          variant="danger"
          @click.prevent="editCard"
        >
          <b-icon icon="pencil"></b-icon>
          Edit
        </b-button>
      </b-col> -->
    </b-row>

    <b-row v-else>
      <b-col
        cols="12"
        md="6"
      >
        <b-form-group>
          <label class="control-label">Card Number *</label>
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <b-img
                  v-if="creditcardtype"
                  :src="`/img/cc/sm/${creditcardtype}.png`"
                  style="max-width: 34px; height: auto"
                  alt="Card Type"
                ></b-img>
                <b-icon
                  v-else
                  icon="credit-card"
                ></b-icon>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              v-cardformat:formatCardNumber
              @input="setCardType()"
              @paste="setCardTypeOnPaste"
              v-model="cc.number"
              @blur="validateCardNumber()"
              :state="isRegistration ? !(cardErrors.cardNumber !== null || $v.cc.number.$error) : ((cardErrors.cardNumber !== null || $v.cc.number.$error) ? false : null)"
              placeholder="#### #### #### ####"
              autocomplete="cc-number"
            ></b-form-input>
            <b-form-invalid-feedback v-if="cardErrors.cardNumber">
              {{ cardErrors.cardNumber }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="$v.cc.number.$error && !isRegistration">
              Card number is required
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        md="3"
      >
        <b-form-group
          description="MM / YYYY"
        >
          <label class="control-label">Expiration *</label>
          <b-input-group>
            <b-form-input
              v-model="cc.expiry"
              v-cardformat:formatCardExpiry
              @blur="validateCardExpiry()"
              @input="cardErrors.cardExpiry = null"
              :state="isRegistration ? !(cardErrors.cardExpiry !== null || $v.cc.expiry.$error) : ((cardErrors.cardExpiry !== null || $v.cc.expiry.$error) ? false : null)"
              placeholder="MM / YYYY"
              autocomplete="cc-exp"
            ></b-form-input>
            <b-form-invalid-feedback v-if="cardErrors.cardExpiry">
              {{ cardErrors.cardExpiry }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="$v.cc.expiry.$error && !isRegistration">
              Expiration is required
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="6"
        md="3"
      >
        <b-form-group>
          <label class="control-label">CVC Code *</label>
          <b-input-group>
            <b-form-input
              v-model="cc.cvc"
              v-cardformat:formatCardCVC
              @blur="validateCardCVC()"
              @input="cardErrors.cardCvc = null"
              :state="isRegistration ? !(cardErrors.cardCvc !== null || $v.cc.cvc.$error) : ((cardErrors.cardCvc !== null || $v.cc.cvc.$error) ? false : null)"
              placeholder="CVC"
              autocomplete="cc-csc"
            ></b-form-input>
            <b-form-invalid-feedback v-if="cardErrors.cardCvc">
              {{ cardErrors.cardCvc }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="$v.cc.cvc.$error && !isRegistration">
              CVC is required
            </b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <PasswordVerify
      :open="verification.open"
      :request="verification.payloadRequest"
      @payload="setPayload"
    ></PasswordVerify>
  </b-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import PasswordVerify from '@/components/widget/PasswordVerify'

export default {
  name: 'CreditCardForm',
  components: {
    PasswordVerify
  },
  props: {
    billing: {
      type: Object,
      default: () => { return null }
    },
    isRegistration: {
      type: Boolean,
      default: false
    }
  },

  data: () => {
    return {
      verification: {
        open: false,
        payloadRequest: {
          type: 'cc',
          id: 0
        },
        payload: {},
        verified: false
      },
      cc: {
        number: null,
        expiry: null,
        cvc: null
      },
      ccdisabled: false,
      creditcardtype: null,
      cardErrors: {
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null
      },
      cardBrand: null
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      if (this.billing) {
        this.cc.number = 'XXXX-XXXX-XXXX-' + this.billing.last_four
        this.cc.expiry = this.billing.expmonth + ' / ' + this.billing.expyear
        this.ccdisabled = true
        this.creditcardtype = this.billing.creditcardtype
        this.verification.payloadRequest.id = this.billing.id
      }

      if (this.isRegistration) {
        this.$v.cc.$touch()
      }
    },

    setCardType () {
      this.creditcardtype = this.determineCardBrand(this.cc.number)
    },

    setCardTypeOnPaste (event) {
      // Accessing the clipboard data directly
      const clipboardData = event.clipboardData || window.clipboardData
      const pastedData = clipboardData.getData('text')
      this.creditcardtype = this.determineCardBrand(pastedData)
    },

    determineCardBrand (cardNumber) {
      // Remove all non-digit characters from the number
      const cleanNumber = cardNumber.replace(/\D/g, '')

      if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(cleanNumber)) { // Visa
        return 'Visa'
      } else if (/^5[1-5][0-9]{14}$/.test(cleanNumber)) { // MasterCard
        return 'MasterCard'
      } else if (/^3[47][0-9]{13}$/.test(cleanNumber)) { // American Express
        return 'American Express'
      } else if (/^6(?:011|5[0-9]{2})[0-9]{12}$/.test(cleanNumber)) { // Discover
        return 'Discover'
      } else if (/^3(?:0[0-5]|[68][0-9])[0-9]{11}$/.test(cleanNumber)) { // Diners Club
        return 'Diners Club'
      } else if (/^(?:2131|1800|35\d{3})\d{11}$/.test(cleanNumber)) { // JCB
        return 'JCB'
      }

      // If no match, return null or 'Unknown'
      return null
    },

    isValidCreditCardNumber (number) {
      const sanitizedNumber = number.toString().replace(/\D/g, '')
      return this.luhnCheck(sanitizedNumber)
    },

    luhnCheck (number) {
      let sum = 0
      let alternate = false
      for (let i = number.length - 1; i >= 0; i--) {
        let n = parseInt(number.charAt(i), 10)
        if (alternate) {
          n *= 2
          if (n > 9) {
            n -= 9
          }
        }
        sum += n
        alternate = !alternate
      }
      return (sum % 10 === 0)
    },

    validateCardNumber () {
      this.$v.cc.number.$touch()
      this.cardErrors.cardNumber = null
      if (!this.isValidCreditCardNumber(this.cc.number)) {
        this.cardErrors.cardNumber = 'Invalid Credit Card Number'
      }
    },

    validateCardExpiry () {
      this.$v.cc.expiry.$touch()
      this.cardErrors.cardExpiry = null
      if (!this.$cardFormat.validateCardExpiry(this.cc.expiry)) {
        this.cardErrors.cardExpiry = 'Invalid Expiration Date'
      }
    },

    validateCardCVC () {
      this.$v.cc.cvc.$touch()
      this.cardErrors.cardCvc = null
      if (!this.$cardFormat.validateCardCVC(this.cc.cvc)) {
        this.cardErrors.cardCvc = 'Invalid CVC'
      }
    },

    editCard () {
      this.verification.open = true
    },

    setPayload (info) {
      this.verification.verified = info.success
      if (this.verification.verified) {
        this.verification.payload = info.payload
        if (this.verification.payload.number) {
          this.cc.number = atob(this.verification.payload.number)
          this.ccdisabled = false
        }
      }
    },

    emitCard (cc, ccdisabled) {
      const info = {
        number: null,
        expmonth: null,
        expyear: null,
        cvc: null,
        creditcardtype: null,
        valid: false,
        cardedit: 1
      }
      info.creditcardtype = this.creditcardtype
      if (this.isValidCreditCardNumber(cc.number)) {
        info.number = this.cc.number.replace(/\s/g, '')
      }
      if (this.$cardFormat.validateCardExpiry(cc.expiry)) {
        const parts = (cc.expiry.replace(/\s/g, '')).split('/')
        info.expmonth = +parts[0]
        info.expyear = (parts[1].length === 2) ? parts[1] : +(parts[1]).slice(-2)
      }
      if (this.$cardFormat.validateCardCVC(cc.cvc)) {
        info.cvc = cc.cvc
      }
      if (info.number !== null && info.expmonth !== null && info.expyear !== null && info.cvc !== null && !this.$v.$invalid) {
        info.valid = true
      }

      if (ccdisabled) {
        info.cardedit = 0
      }
      this.$emit('update', (info) || null)
    }
  },

  watch: {
    cc: {
      deep: true,
      handler (n) {
        this.emitCard(n, this.ccdisabled)
      }
    },

    ccdisabled (n) {
      this.emitCard(this.cc, n)
    }
  },

  mixins: [validationMixin],

  validations: {
    cc: {
      number: {
        required
      },
      expiry: {
        required
      },
      cvc: {
        required
      }
    }
  }
}
</script>
