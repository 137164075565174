<template>
  <b-container fluid>
    <div v-if="showloading && (!checkedLive || !checkedPremiere)">
      <b-col class="text-center"
        ><b-spinner></b-spinner><br />Loading Upcoming events<br /><br
      /></b-col>
    </div>
    <div v-show="liveCount > 0 || premiereCount > 0">
      <div class="mb-4">
        <b-button-group style="width: 100%">
          <b-button
            @click="streamList = 'live'"
            :class="streamList == 'live' ? 'front-strip' : ''"
            class="stream-list-btn"
          >Livestreams ({{ liveCount }})</b-button>
          <b-button
            @click="streamList = 'premiere'"
            :class="streamList == 'premiere' ? 'front-strip' : ''"
            class="stream-list-btn"
          >Premieres ({{ premiereCount }})</b-button>
        </b-button-group>
        <div v-show="streamList == 'live'" class="stream-list py-4">
          <Livestream v-show="liveCount > 0" :limit="limit" :channelId="channel" @listCount="setCount($event, 0)"></Livestream>
          <h4 v-show="liveCount == 0" class="text-center">No Scheduled Livestreams at this time</h4>
        </div>
        <div v-show="streamList == 'premiere'" class="stream-list py-4">
          <Premiere v-show="premiereCount > 0" :limit="limit" :channelId="channel" @listCount="setCount($event, 1)"></Premiere>
          <h4 v-show="premiereCount == 0" class="text-center">No Scheduled Premieres at this time</h4>
        </div>
      </div>
    </div>
    <div v-show="liveCount == 0 && premiereCount == 0 && checkedLive && checkedPremiere && showNoResults">
      <h2 class="text-center">No scheduled events found</h2>
    </div>
  </b-container>
</template>
<script>
import Premiere from '@/components/schedule/Premiere'
import Livestream from '@/components/schedule/Livestream'

export default {
  components: {
    Premiere,
    Livestream
  },
  data () {
    return {
      showStreams: true,
      streamList: 0,
      liveCount: 0,
      premiereCount: 0,
      checkedLive: false,
      checkedPremiere: false
    }
  },
  props: {
    channel: {
      type: Number,
      default: 0
    },
    limit: {
      type: Number,
      default: 0
    },
    showNoResults: {
      type: Boolean,
      default: false
    },
    showloading: {
      type: Boolean,
      default: false
    }
  },
  mounted: function () {
    this.streamList = 'live'
  },
  methods: {
    setCount (count, type) {
      if (type === 0) {
        this.liveCount = count
        if (count === 0) {
          this.streamList = 'premiere'
        }
        this.checkedLive = true
      } else if (type === 1) {
        this.premiereCount = count
        this.checkedPremiere = true
      }
    }
  }
}
</script>
