<template>
  <div>
    <div v-if="video && !loading">
      <watch-video :video="video"></watch-video>
    </div>
    <div v-if="loading" class="text-center">
      <b-spinner></b-spinner><br />Loading Videos<br /><br />
    </div>
  </div>
</template>
<script>
import WatchVideo from '@/components/video-list/WatchVideo.vue'
import VideoService from '@/services/VideoService'
export default {
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  components: {
    WatchVideo
  },
  data: () => {
    return {
      video: null,
      loading: false
    }
  },
  created () {
    this.init()
  },
  methods: {
    init: function () {
      this.loading = true
      VideoService.fetch(this.id).then(
        (response) => {
          this.video = response.data
        }
      ).catch(
        (err) => {
          this.$aim.error(err.response)
        }
      ).finally(() => { this.loading = false })
    }
  }
}
</script>
