import Vue from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Notifications from 'vue-notification'
import Default from './layouts/Default'
import VueCardFormat from 'vue-credit-card-validation'
import aim from './plugins/aim'

// bootstrap 4
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'video.js/dist/video-js.css'

import UserService from '@/services/UserService'

Vue.config.productionTip = false

Vue.use(Notifications)
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCardFormat)
Vue.use(aim)

Vue.component('default-layout', Default)

export const bus = new Vue()

let apiUrl = 'https://admin.sbnbroadcasting.com/api/v1/'
let hostName = window.location.hostname
if (typeof process !== 'undefined' && process.env.NODE_ENV === 'development') {
  apiUrl = 'https://thespinnetwork.dev.aimitservices.com/api/v1/'
} else if (typeof process !== 'undefined' && process.env.NODE_ENV === 'staging') {
  apiUrl = process.env.VUE_APP_STAGING_API_URL
  hostName = process.env.VUE_APP_NETWORK_URL
}

// allow switching of network based GET param in staging/development
if (typeof process !== 'undefined' && (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging')) {
  // check if we want to "switch" networks based on a GET PARAM
  const params = new URLSearchParams(window.location.search)
  if (params.get('NETWORK_URL')) {
    hostName = params.get('NETWORK_URL')
    localStorage.setItem('hostName', hostName)
  } else {
    const lsHost = localStorage.getItem('hostName')
    if (lsHost) {
      hostName = lsHost
    }
  }
}

// set api url
Vue.axios.defaults.baseURL = apiUrl
// set  axios defaults to always set content type to JSON by default.
Vue.axios.defaults.headers.common['Content-Type'] = 'application/json'
Vue.axios.defaults.headers.common.Accept = 'application/json'

// Set our NETWORK header to identify which spin network this is
Vue.axios.defaults.headers.common.Network = hostName

// enable xdebug on api calls in development
if (process.env.NODE_ENV === 'development') {
  Vue.axios.defaults.params = {}
  Vue.axios.defaults.params.XDEBUG_SESSION = 'mobile'
}

// nothing defined here (when this.$route.path is other than "/")
// intercept all the axios request
Vue.axios.interceptors.response.use(
  // handle response here
  (response) => {
    return Promise.resolve(response)
  },
  (err) => {
    if (typeof err.response === 'undefined') {
      throw new Error('An internal error has occurred.')
    } else {
      // KNOW WHY THIS EXISTS AND WHAT IT'S FOR BEFORE TRYING TO IMPLEMENT
      // const unAuthErrorCode = [401, 403]
      // if (unAuthErrorCode.includes(err.response.status)) {
      //   // this.logout()
      //   return Promise.reject(err.response)
      // } else if (err.response.status === 425) {
      //   // efish service suspended or cancelled
      //   this.$router.push('/suspended')
      // } else {
      throw err
      // }
    }
  }
)

// Ensuring this call completes before we allow Vue to do anything
UserService.getUserInfo().then(() => {
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
})

/* eslint-disable-next-line */
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1)
}
