<template>
  <!-- <div>
    NO JUMBOTRON?
  </div> -->
  <b-container v-if="networkInfo && groupnum" class="mb-5" role="region" aria-label="f a q">
    <b-row>
      <b-col>
        <h1
          id="main-title"
          class="text-uppercase background-lines double-lines text-lg grey"
        >
          <span>FAQ {{ networkInfo.groupnum }}</span>
        </h1>
      </b-col>
    </b-row>
    <!-- General/Main/Subscribers -->
    <b-row v-if="groupnum == 1">
      <b-col>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              HOW DO SUBSCRIPTIONS WORK ON YOUR VIDEO NETWORK?
            </h3>
          </template>
          <b-card-text>
            <p>
              There are two different types of subscriptions: Month to Month and
              once a year annual fee.
            </p>
            <ul>
              <li>
                Month to month: The subscriber pays a ${{ monthlyfee }} monthly
                payment. This subscription can be cancelled at anytime, and will
                auto renew monthly.
              </li>
              <li>
                Yearly: The subscriber pays ${{ yearlyfee }} for one year of
                access. This subscription can be cancelled at any time, and will
                auto renew annually.
              </li>
              <li v-if="eventfee">
                Event: The subscriber pays ${{ eventfee }} for access for
                {{ duration }} day<span v-if="duration > 1">s</span>. Usually
                designed for a special event. This subscription can be cancelled
                at any time, it will not auto renew.
              </li>
            </ul>
            <p>
              These subscriptions give you access to the video network to enjoy
              on demand and live stream video content.
            </p>
            <p>
              If you wish to cancel your subscription, please login and go to
              'My Subscription' page. There you can click 'Cancel My
              Subscription'.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">CAN MY FAMILY SHARE ONE SUBSCRIPTION?</h3>
          </template>
          <b-card-text>
            <p>
              Immediate family members <i>that live in the same house</i> can
              share a subscription (spouses, students, and siblings.)
              Grandparents, aunts and uncles, and family friends are responsible
              for purchasing their own subscriptions.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              HOW LONG DOES IT TAKE FOR A VIDEO TO BE VIEWABLE ONCE IT HAS BEEN
              UPLOADED?
            </h3>
          </template>
          <b-card-text>
            <p>
              One of our biggest priorities is to ensure videos are viewable to
              our subscribers as quickly as possible. In most cases your video
              will be online immediately.
            </p>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <!-- Videographers/Production Companies -->
    <b-row v-if="groupnum == 2">
      <b-col>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              ONCE I UPLOAD A VIDEO TO SPiN, WHO OWNS THE CONTENT?
            </h3>
          </template>
          <b-card-text>
            <p>
              Not SPiN. We neither acquire nor retain ownership of any video
              uploaded to the network. All content is owned by either the
              organization that uploads the video or the production company who
              uploads the video on behalf of that organization. The terms of
              video/content ownership for 3rd party videographers and production
              companies is between the channel owner and the person/organization
              uploading video on their behalf.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              CAN I UPLOAD A VIDEO THAT CONTAINS A MASTER RECORDING?
            </h3>
          </template>
          <b-card-text>
            <p>
              Not at this time, as the use of a master recording requires an
              additional license.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              ARE VIDEOS OF CHEER, DANCE AND WINTERGUARD PERFORMANCES ALLOWED TO
              BE POSTED ON THE SITE?
            </h3>
          </template>
          <b-card-text>
            <p>
              Not at this time. Any performance that involves the use of a
              master recording is currently prohibited.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">WHO IS ABLE TO UPLOAD VIDEOS?</h3>
          </template>
          <b-card-text>
            <p>
              Professional videographers, production companies, organization
              channel administrators, and ensemble directors.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              IS THERE AN APPROVAL PROCESS ONCE VIDEOS ARE UPLOADED?
            </h3>
          </template>
          <b-card-text>
            <p>
              No. Once a video is uploaded to SPiN and the compositions have
              been cleared by the publishers, the video is made viewable to
              subscribers. However, each time a new video is uploaded to SPiN,
              the uploader is given the option to make the video “Public” or
              “Private”. If the uploader chooses to make the video private, only
              the Director of the performing ensemble will have access to that
              particular video.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">WHAT VIDEO FORMATS CAN BE UPLOADED?</h3>
          </template>
          <b-card-text>
            <p>
              Most video formats and codecs are supported - Contact Us if you
              find your video is incompatible.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">ARE THERE VIDEO SIZE LIMITATIONS?</h3>
          </template>
          <b-card-text>
            <p>
              There is no size restriction of video files uploaded, but consider
              videos should be one composition per video.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              IF I LIVE STREAM AN EVENT ON BEHALF OF A CHANNEL, WILL A VIDEO OF
              THAT EVENT AUTOMATICALLY BECOME AVAILABLE ON SPiN WHICH IS ABLE TO
              BE VIEWED BY?
            </h3>
          </template>
          <b-card-text>
            <p>
              No - you will need to upload a video of the live stream in order
              to list all composition and ensemble information.
            </p>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <!-- Channel Owners - Schools -->
    <b-row v-if="groupnum == 3">
      <b-col>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              IF MY SCHOOL SIGNS UP FOR A FREE SPiN CHANNEL, DO THE DIRECTORS OF
              ALL OF OUR ENSEMBLES HAVE FULL ADMINISTRATIVE RIGHTS?
            </h3>
          </template>
          <b-card-text>
            <p>
              No. Each organization has one dedicated Channel Administrator.
              However, each Director associated with an ensemble in your
              organization is given a Director's subscription/login at no
              additional cost. The Director is then able to login and upload,
              and view all videos associated with his or her school's channel.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">WHO IS ABLE TO UPLOAD VIDEOS?</h3>
          </template>
          <b-card-text>
            <p>
              Professional videographers, production companies, organization
              channel administrators, and ensemble directors.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">WHAT VIDEO FORMATS CAN BE UPLOADED?</h3>
          </template>
          <b-card-text>
            <p>
              Most video formats and codecs are supported - Contact Us if you
              find your video is incompatible.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">ARE THERE VIDEO SIZE LIMITATIONS?</h3>
          </template>
          <b-card-text>
            <p>
              There is no size restriction of video files uploaded, but consider
              videos should be one composition per video.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              IF I AM THE HOST OF AN EVENT, CAN I UPLOAD VIDEOS OF ALL OF THE
              PERFORMING ENSEMBLES?
            </h3>
          </template>
          <b-card-text>
            <p>
              Yes. If you are the host of the event, you can upload videos of
              all of the performing ensembles regardless of whether they have a
              channel on SPiN or not.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              WHY AM I BEING ASKED TO UPLOAD A PICTURE OR LOGO OF MY
              ORGANIZATION WHEN I CREATE MY CHANNEL?
            </h3>
          </template>
          <b-card-text>
            <p>
              This is optional and certainly not required. Once you upload your
              picture or logo it appears at the top of your channel and provides
              a personalized touch for your subscribers. You can change this
              image/logo as often as you’d like.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              AM I ABLE TO ADD AND/OR DELETE MY VIDEOS AT MY DISCRETION?
            </h3>
          </template>
          <b-card-text>
            <p>
              Yes. The Channel Administrator can add or delete videos at any
              time.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              ONCE I UPLOAD A VIDEO TO SPiN, WHO OWNS THE CONTENT?
            </h3>
          </template>
          <b-card-text>
            <p>
              Not SPiN. We neither acquire or retain ownership of any video
              uploaded to the network. All content is owned by the organization
              that uploaded the video.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              WHY DO SOME VIDEOS TAKE LONGER TO CLEAR THAN OTHERS?
            </h3>
          </template>
          <b-card-text>
            <p>
              The SPiN Network tries very hard to get your performances cleared
              as quickly as possible. Some publishers take longer to clear songs
              than others.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              ARE THERE LIMITS TO HOW MANY ENSEMBLES CAN BE ASSOCIATED WITH ONE
              ORGANIZATION?
            </h3>
          </template>
          <b-card-text>
            <p>
              No - we encourage schools and state music education associations
              to include all of their performing ensembles.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              IF I LIVE STREAM AN EVENT ON MY CHANNEL, WILL A VIDEO OF THAT
              EVENT AUTOMATICALLY BECOME AVAILABLE?
            </h3>
          </template>
          <b-card-text>
            <p>
              No - you will need to upload a video of the live stream in order
              to list all composition and ensemble information.
            </p>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <!-- Channel Owners - MEAs -->
    <b-row v-if="groupnum == 4">
      <b-col>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              IF MY ORGANIZATION SIGNS UP FOR A FREE SPiN CHANNEL, DO THE
              DIRECTORS OF ALL OF OUR PARTICIPATING ENSEMBLES GET A
              SUBSCRIPTION?
            </h3>
          </template>
          <b-card-text>
            <p>
              Yes! Music Education Association Channels have one dedicated
              Channel Administrator, and they can designate can designate four
              additional administrative users. Additionally one Director
              associated with each of your participating ensembles participating
              in your organization will receive a subscriber to view the videos.
              If the Director’s school creates a free Channel on SPiN, he or she
              is given a Basis subscriptionThe Director is then able to login
              and upload, and view all videos associated with his or her
              school’s channel.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">WHO IS ABLE TO UPLOAD VIDEOS?</h3>
          </template>
          <b-card-text>
            <p>
              Professional videographers, production companies, organization
              channel administrators, and ensemble directors.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">WHAT VIDEO FORMATS CAN BE UPLOADED?</h3>
          </template>
          <b-card-text>
            <p>
              Most video formats and codecs are supported - Contact Us if you
              find your video is incompatible.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">ARE THERE VIDEO SIZE LIMITATIONS?</h3>
          </template>
          <b-card-text>
            <p>
              There is no size restriction of video files uploaded, but consider
              videos should be one composition per video.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              IF I AM THE HOST OF AN EVENT, CAN I UPLOAD VIDEOS OF ALL OF THE
              PERFORMING ENSEMBLES?
            </h3>
          </template>
          <b-card-text>
            <p>
              Yes. If you are the host of the event, you can upload videos of
              all of the performing ensembles regardless of whether they have a
              channel on SPiN or not.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              WHY AM I BEING ASKED TO UPLOAD A PICTURE OR LOGO OF MY
              ORGANIZATION WHEN I CREATE MY CHANNEL?
            </h3>
          </template>
          <b-card-text>
            <p>
              This is optional and certainly not required. Once you upload your
              picture or logo it appears at the top of your channel and provides
              a personalized touch for your subscribers.. You can change this
              image/logo as often as you’d like.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              AM I ABLE TO ADD AND/OR DELETE MY VIDEOS AT MY DISCRETION?
            </h3>
          </template>
          <b-card-text>
            <p>
              Yes. The Channel Administrator can add or delete videos at any
              time.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              ONCE I UPLOAD A VIDEO TO SPiN, WHO OWNS THE CONTENT?
            </h3>
          </template>
          <b-card-text>
            <p>
              Not SPiN. We neither acquire or retain ownership of any video
              uploaded to the network. All content is owned by the organization
              that uploaded the video.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              WHY DO SOME VIDEOS TAKE LONGER TO CLEAR THAN OTHERS?
            </h3>
          </template>
          <b-card-text>
            <p>
              The SPiN Network tries very hard to get your performances cleared
              as quickly as possible. Some publishers take longer to clear songs
              than others.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              ARE THERE LIMITS TO HOW MANY ENSEMBLES CAN BE ASSOCIATED WITH ONE
              ORGANIZATION?
            </h3>
          </template>
          <b-card-text>
            <p>
              No - we encourage schools and state music education associations
              to include all of their performing ensembles.
            </p>
          </b-card-text>
        </b-card>
        <b-card>
          <template #header>
            <h3 class="text-primary">
              IF I LIVE STREAM AN EVENT ON MY CHANNEL, WILL A VIDEO OF THAT
              EVENT AUTOMATICALLY BECOME AVAILABLE?
            </h3>
          </template>
          <b-card-text>
            <p>
              No - you will need to upload a video of the live stream in order
              to list all composition and ensemble information.
            </p>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import UserService from '@/services/UserService'

export default {
  name: 'FAQ',
  data () {
    return {
      networkInfo: null,
      groupnum: 1,
      monthlyfee: null,
      yearlyfee: null,
      eventfee: null,
      duration: null
    }
  },
  async mounted () {
    await this.getNetworkInfo()
    this.monthlyfee = this.networkInfo.subscriptiontypes[1].amount
    this.yearlyfee = this.networkInfo.subscriptiontypes[0].amount
    this.eventfee = this.networkInfo.subscriptiontypes[2].amount
    this.duration = this.networkInfo.subscriptiontypes[2].durationdays // DEVNOTE: what duration am I supposed to use? AC: Using [2] as this will be the daily/edited version of their passes
  },
  methods: {
    async getNetworkInfo () {
      this.networkInfo = await UserService.getNetworkInfo()
    }
  }
}
</script>

<style scoped>
.card {
  margin-bottom: 7px;
}
</style>
