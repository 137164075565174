<template>
  <div role="region" aria-label="video">
    <b-overlay
      :show="
        loading ||
        videoOptions.sources.length == 0 ||
        video == null ||
        video.id == null
      "
    >
      <b-row v-if="videoOptions.sources.length > 0 && video && video.id">
        <b-col cols="12">
          <video-player
            :options="videoOptions"
            :viewedInterval="30"
            :updateInterval="30"
            :id="video.id"
          ></video-player>
        </b-col>
        <b-col cols="12">
          <b-row class="video-watch-info">
            <!-- <div class="float-right social-shareable-links">
              <-- FB share link
              <a href="javascript:void(0)" socialshare socialshare-provider="facebook" socialshare-hashtags="spinnetwork" socialshare-url="<[video.shareableLink]>">
                <img src="/img/icons8-facebook-old-50.png">
              </a>
              <-- Twitter share link
              <a href="javascript:void(0)" socialshare socialshare-provider="twitter" socialshare-hashtags="spinnetwork" socialshare-url="<[video.shareableLink]>">
                <img src="/img/icons8-twitter-50.png">
              </a>
              <-- email share link
              <a href="javascript:void(0)" socialshare socialshare-provider="email" socialshare-subject="<[video.video.title]>" socialshare-body="Check out this awesome video: '<[video.shareableLink]>'">
                <img src="/img/icons8-email-50.png">
              </a>
              <span get-shareable-link class="pull-right" link-url="video.shareableLink" button-class="'btn-success'" button-text="''" title-text="'Video Link:'"></span>
            </div> -->
            <b-col cols="12" class="mt-2">
              <h2>
                {{ video.video.title }}
                <b-icon
                  :icon="video.favorite ? 'heart-fill' : 'heart'"
                  style="font-size: 0.8em"
                  variant="danger"
                  @click="favorite()"
                ></b-icon>
                <b-icon
                  class="ml-4"
                  icon="share-fill"
                  style="font-size: 0.65em; padding: 4px"
                  @click="sharebox = !sharebox"
                ></b-icon>
              </h2>
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-btn @click="clearVideo()"
                ><b-icon icon="caret-up"></b-icon> Back to Video List</b-btn
              >
            </b-col>
            <b-col cols="12" class="mt-2">
              <b-collapse id="share-box" class="pb-2" v-model="sharebox">
                <b-input-group>
                  <b-form-input
                    v-bind:value="video.shareableLink"
                    disabled
                  ></b-form-input>
                  <b-input-group-append @click="copy()"
                    ><b-input-group-text style="background: #28a745"
                      ><b-icon
                        icon="clipboard"
                        color="white"
                      ></b-icon></b-input-group-text
                  ></b-input-group-append>
                </b-input-group>
              </b-collapse>
              <div class="video-description">
                <p class="m-0">{{ video.video.description }}</p>
              </div>
              <h4>
                Performed By:
                <span
                  v-if="
                    video.video.ensemble.organization.name !==
                    video.video.ensemble.name
                  "
                >
                  {{ video.video.ensemble.organization.name }} -
                </span>
                {{ video.video.ensemble.name }}
              </h4>
              <span
                v-if="video.video.programlink && video.video.programlink !== ''"
              >
                <a :href="video.video.programlink" target="_blank"
                  >Program Link</a
                >
              </span>
              <div
                v-for="vc in video.video.ownVideocomposition"
                :key="vc.id"
                class="video-comp-list"
              >
                <p class="mb-0">
                  <b v-if="vc.composition_id > 0">{{ vc.composition.title }}</b>
                  <b v-if="vc.composition_id == null">{{ vc.newtitle }}</b>
                </p>
                <ul
                  class="list-inline mb-0 small"
                  v-if="(vc.composition && vc.composition.sharedComposer.length > 0)"
                >
                  <li class="list-inline-item">Written by:</li>
                  <li
                    class="list-inline-item"
                    v-for="(co, key) in vc.composition.sharedComposer"
                    :key="key"
                  >
                    {{ co.name
                    }}<span
                      v-show="
                        key !==
                        Object.keys(vc.composition.sharedComposer).length - 1
                      "
                      >,
                    </span>
                  </li>
                </ul>
                <ul class="list-inline mb-0 small" v-if="(vc.composition_id == null && vc.newcomposer !== null)">
                  <li class="list-inline-item">Written by:</li>
                  <li class="list-inline-item">{{ vc.newcomposer }}</li>
                </ul>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>
<script>
import VideoPlayer from '@/components/widget/VideoPlayer'
import VideoService from '@/services/VideoService'
import { bus } from '@/main'

export default {
  props: {
    video: {
      type: Object,
      default () { return {} }
    }
  },
  components: {
    VideoPlayer
  },
  data () {
    return {
      videoOptions: {
        disablePictureInPicture: true,
        controls: true,
        autoplay: true,
        sources: []
      },
      sharebox: false,
      loading: false
    }
  },
  mounted () {
    this.setVideo()
  },
  methods: {
    setVideo () {
      if (this.video) {
        this.videoOptions.sources.push(
          {
            src: this.video.playlist_link,
            type: 'application/x-mpegURL'
          }
        )
      }
    },
    clearVideo () {
      if (this.$route.name === 'Watch Video') {
        this.$router.push('/video/channel')
      } else {
        bus.$emit('clearVideo')
      }
    },
    favorite () {
      VideoService.favorite(this.video.id).then(
        (response) => {
          if (response.data) {
            this.$emit('update-video-favorite-status', this.video.id)
          }
        }
      ).catch(error => {
        this.$aim.error(error.response)
      })
    },
    copy () {
      const copyElement = document.createElement('textarea')
      copyElement.style.position = 'fixed'
      copyElement.style.opacity = '0'
      copyElement.textContent = decodeURI(this.video.shareableLink)
      const body = document.getElementsByTagName('body')[0]
      body.appendChild(copyElement)
      copyElement.select()
      document.execCommand('copy')
      body.removeChild(copyElement)
    }
  }
}
</script>
