<template>
  <b-button
    v-if="record && record.id"
    @click.prevent="openModal = true"
  >
    <slot></slot>
    <b-modal
      v-model="openModal"
      id="credit-card-delete-modal"
      size="md"
      no-stacking
      title="Delete Payment Information"
      auto-focus-button="cancel"
      ok-variant="danger"
      @ok="deleteCreditCard"
    >
      <Loading :loading="loading"></Loading>
      <b-card v-if="!loading">
        <h4>Are you sure you want to delete this billing information?</h4>
      </b-card>
    </b-modal>
  </b-button>

</template>
<script>
import BillingService from '@/services/BillingService'
import Loading from '@/components/widget/Loading'

export default {
  name: 'CreditCardDelete',
  components: {
    Loading
  },
  props: {
    record: Object
  },
  data () {
    return {
      openModal: false,
      loading: false
    }
  },
  methods: {
    deleteCreditCard (e) {
      if (e) {
        e.preventDefault()
      }
      if (this.loading) {
        return false
      }
      this.loading = true
      BillingService.delete(this.record.id).then(
        (response) => {
          this.$aim.notify(response)
        }
      ).catch(
        (error) => {
          this.$aim.error(error.response)
        }
      ).finally(() => {
        this.loading = false
        this.$emit('refresh')
        this.close()
      })
    },

    close () {
      this.$nextTick(() => {
        this.$bvModal.hide('credit-card-delete-modal')
      })
    }
  }
}
</script>
