<template>
  <div class="container-fluid" role="region" aria-label="account information">
    <div class="row">
      <div class="col-12">
        <b-spinner v-if="loading" label="'Loading Account Info'"></b-spinner>
        <div class="form-wrapper" v-show="!loading">
          <fieldset>
            <legend>Edit Account</legend>

            <b-card class="mb-4">
              <template #header>
                <div style="font-size: 1.2em">User Account Information</div>
              </template>
              <b-card-text>
                <div class="row">
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label" for="username"
                        >User Name (5 minimum):</label
                      >
                      <b-input-group>
                        <b-form-input
                          type="text"
                          v-model="account.user.username"
                          :disabled="!editUsername"
                          :state="
                            !$v.account.user.username.$error && !usernameTaken
                              ? null
                              : false
                          "
                          @blur="$v.account.user.username.$touch()"
                          @input="checkUsername()"
                          minlength="5"
                          placeholder="Desired Username"
                          autocomplete="username"
                        ></b-form-input>
                        <b-input-group-append>
                          <b-input-group-text v-if="editUsername">
                            <b-icon
                              icon="arrow-repeat"
                              :animation="searchUsername ? 'spin' : ''"
                            ></b-icon>
                          </b-input-group-text>
                          <button
                            v-else
                            class="btn btn-danger"
                            @click.prevent="editUsername = !editUsername"
                          >
                            <!-- v-show="!searchUsername && !editUsername" -->
                            <b-icon icon="pencil-square"></b-icon>
                          </button>
                        </b-input-group-append>
                        <b-form-invalid-feedback
                          v-for="err in getErrors('user', 'username', [
                            'required',
                            'minLength',
                          ])"
                          :key="err.id"
                        >
                          {{ err }}
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback v-if="usernameTaken">
                          Username is taken
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label" for="password"
                        >Password:</label
                      >
                      <div v-show="!editPassword">
                        <button
                          class="btn btn-danger"
                          @click.prevent="editPassword = !editPassword"
                          v-show="!editPassword"
                        >
                          <b-icon icon="pencil-square"></b-icon> Edit
                        </button>
                      </div>
                      <b-input-group>
                        <b-form-input
                          v-show="editPassword"
                          type="password"
                          v-model="account.user.password"
                          :disabled="searchUsername"
                          :state="
                            !$v.account.user.password.$error ? null : false
                          "
                          @blur="$v.account.user.password.$touch()"
                          name="password"
                          id="password"
                          required
                          minlength="6"
                          placeholder="New Password"
                          autocomplete="new-password"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                          v-for="err in getErrors('user', 'password', [
                            'required',
                            'minLength',
                          ])"
                          :key="err.id"
                        >
                          {{ err }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div v-show="editPassword" class="form-group">
                      <label class="control-label">Password Confirm:</label>
                      <b-input-group>
                        <b-form-input
                          type="password"
                          v-model="account.user.password2"
                          :disabled="searchUsername"
                          :state="
                            !$v.account.user.password2.$error ? null : false
                          "
                          @blur="$v.account.user.password2.$touch()"
                          name="password2"
                          id="password2"
                          required
                          placeholder="New Password Confirm"
                          autocomplete="new-password"
                        >
                        </b-form-input>
                        <b-form-invalid-feedback
                          v-for="err in getErrors('user', 'password2', [
                            'required',
                            'sameAsPassword',
                          ])"
                          :key="err.id"
                        >
                          {{ err }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                  </div>
                </div>
              </b-card-text>
            </b-card>

            <b-card>
              <template #header>
                <div style="font-size: 1.2em">
                  Personal Information
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="editInfo = !editInfo"
                  >
                    <b-icon icon="pencil-square"></b-icon> Edit
                  </b-button>
                </div>
              </template>
              <b-card-text>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label" for="firstname"
                        >First Name</label
                      >
                      <b-input-group>
                        <b-form-input
                          type="text"
                          v-model="account.user.firstname"
                          name="firstname"
                          id="firstname"
                          :state="
                            !$v.account.user.firstname.$error ? null : false
                          "
                          @blur="$v.account.user.firstname.$touch()"
                          :disabled="loading || !editInfo"
                          placeholder="First"
                          autocomplete="given-name"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          v-for="err in getErrors('user', 'firstname', [
                            'required',
                          ])"
                          :key="err.id"
                        >
                          {{ err }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label" for="lastname"
                        >Last Name:</label
                      >
                      <b-input-group>
                        <b-form-input
                          type="text"
                          v-model="account.user.lastname"
                          name="lastname"
                          id="lastname"
                          :state="
                            !$v.account.user.lastname.$error ? null : false
                          "
                          @blur="$v.account.user.lastname.$touch()"
                          :disabled="loading || !editInfo"
                          placeholder="Last"
                          autocomplete="family-name"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          v-for="err in getErrors('user', 'lastname', [
                            'required',
                          ])"
                          :key="err.id"
                        >
                          {{ err }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label class="control-label" for="email"
                        >Email Address:</label
                      >
                      <b-input-group>
                        <b-form-input
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Email"
                          v-model="account.user.email"
                          :state="
                            !$v.account.user.email.$error && !emailTaken
                              ? null
                              : false
                          "
                          @blur="$v.account.user.email.$touch()"
                          debounce="700"
                          @input="checkEmail()"
                          :disabled="loading || !editInfo"
                          autocomplete="email"
                        ></b-form-input>
                        <b-input-group-append v-show="searchEmail">
                          <b-input-group-text>
                            <b-icon
                              icon="arrow-repeat"
                              animation="spin"
                            ></b-icon>
                          </b-input-group-text>
                        </b-input-group-append>
                        <b-form-invalid-feedback v-if="emailTaken">
                          This email is taken
                        </b-form-invalid-feedback>
                        <b-form-invalid-feedback
                          v-for="err in getErrors('user', 'email', [
                            'required',
                            'email',
                          ])"
                          :key="err.id"
                        >
                          {{ err }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                  </div>
                  <div class="col-sm-2">
                    <div class="form-group">
                      <label class="control-label" for="countrycode"
                        >Phone Country</label
                      >
                      <b-form-group>
                        <b-form-select
                          v-model="account.user.countrycode_id"
                          :options="countryCodes"
                          :disabled="loading || !editInfo"
                          value-field="id"
                          text-field="text"
                          :state="
                            !$v.account.user.countrycode_id.$error
                              ? null
                              : false
                          "
                          @input="$v.account.user.countrycode_id.$touch()"
                        >
                          <template #text-field> </template>
                          <template #first>
                            <b-form-select-option :value="null" disabled
                              >Country Code</b-form-select-option
                            >
                          </template>
                        </b-form-select>
                        <b-form-invalid-feedback
                          v-for="err in getErrors('user', 'countrycode_id', [
                            'required',
                          ])"
                          :key="err.id"
                        >
                          {{ err }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="form-group">
                      <label class="control-label" for="phone">Phone:</label>
                      <div>
                        <b-input-group>
                          <b-form-input
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Phone"
                            v-model="account.user.phone"
                            :state="
                              !$v.account.user.phone.$error ? null : false
                            "
                            @blur="$v.account.user.phone.$touch()"
                            :disabled="loading || !editInfo"
                            autocomplete="tel-national"
                          ></b-form-input>
                          <b-form-invalid-feedback
                            v-for="err in getErrors('user', 'phone', [
                              'required',
                              'cellPhone',
                            ])"
                            :key="err.id"
                          >
                            {{ err }}
                          </b-form-invalid-feedback>
                        </b-input-group>
                      </div>
                    </div>
                  </div>
                </div>
                <AddressForm
                  :mainaddress="startingAdddress"
                  :disabled="loading || !editInfo"
                  :states="states"
                  :countries="countries"
                  @isValid="setValidAdress"
                  @dataChange="setAdress"
                ></AddressForm>
              </b-card-text>
            </b-card>
            <div class="custom-control custom-checkbox mt-4">
              <input
                type="checkbox"
                class="custom-control-input"
                id="promo"
                v-model="account.user.promo"
              />
              <label class="custom-control-label" for="promo">
                I wish to receive email notifications when new videos are uploaded or new live stream events are addded to the network.
                I also understand that from time to time I may receive newsletters and promotional material from SBN Broadcasting.
                These email preferences can be changed at any time by accessing the "my account" section of the network.
              </label>
            </div>
          </fieldset>
          <div class="row mt-4">
            <div class="col-xs-12 mx-auto">
              <button
                class="btn btn-success"
                @click="save()"
                :disabled="
                  !$v.$anyDirty ||
                  $v.account.$anyError ||
                  loading ||
                  !validAddress
                "
              >
                <!-- :disabled="accountForm.$invalid || accountForm.$pristine || loading" -->
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AccountService from '@/services/AccountService'
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
  minLength,
  sameAs
} from 'vuelidate/lib/validators'
import AddressForm from '@/components/forms/Address'

const cellPhone = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  } else {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)
  }
}

export default {
  components: {
    AddressForm
  },
  data: () => {
    return {
      loading: false,
      editUsername: false,
      editPassword: false,
      editInfo: false,
      searchZip: false,
      searchUsername: false,
      searchEmail: false,
      usernameTaken: false,
      emailTaken: false,
      account: {
        user: {
          username: null,
          password: null,
          password2: null,
          firstname: null,
          lastname: null,
          email: null,
          countrycode_id: null,
          phone: null,
          promo: true
        },
        address: {
          address1: null,
          address2: null,
          zip: null,
          city: null,
          state: null,
          country_id: null
        }
      },
      states: [],
      countries: [],
      startingAdddress: null,
      validAddress: false
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    init: function () {
      this.loading = true
      AccountService.init().then(
        (response) => {
          if (response && response.data && response.data.user) {
            this.account.user = response.data.user
            this.startingAdddress = response.data.address
            this.states = response.data.states
            this.countries = response.data.countries
          } else {
            alert('Failed to load account info! ' + response.data.error[0])
          }
        }
      ).catch(
        (error) => {
          alert('Failed to load account info! ' + error.data.error[0])
        }
      ).finally(() => {
        this.loading = false
        this.editUsername = false
        this.editPassword = false
        this.editInfo = false
      })
    },
    save: function () {
      if (this.loading || !this.validAddress) {
        return
      }

      this.loading = true
      AccountService.save(this.account).then(
        (response) => {
          if (response && response.data && !response.data.error) {
            this.$v.$reset()
            this.init()
          } else {
            alert('Failed to save account info! ' + response.data.error[0])
          }
        },
        (error) => {
          console.dir(error)
          alert('Failed to save account info! ' + error.data.error[0])
        }
      ).finally(() => {
        this.loading = false
      })
    },
    getErrors: function (form, field, validators) {
      const err = []
      if (!this.$v.account[form][field].$dirty) return err

      validators.forEach(e => {
        if (!this.$v.account[form][field][e]) {
          e === 'required' && err.push('This field is required')
          e === 'sameAsPassword' && err.push('Passwords do not match')
          e === 'minLength' && err.push(field.capitalize() + ' must be at least ' + this.$v.account[form][field].$params.minLength.min + ' characters')
          e === 'email' && err.push('Please enter a valid email')
          e === 'cellPhone' && err.push('Please enter a valid phone number')
        }
      })

      return err
    },

    checkUsername: function () {
      if (this.loading || this.$v.account.user.username.$invalid) {
        this.usernameTaken = false
        return
      }
      this.searchUsername = true
      AccountService.checkUsername(this.account.user.username).then(
        (response) => {
          this.usernameTaken = !response.data.exists
        },
        (error) => {
          console.log(error)
          alert('Failed to look up username!')
        }
      ).finally(() => {
        this.searchUsername = false
      })
    },

    checkEmail: function () {
      if (this.loading || this.$v.account.user.email.$invalid) {
        this.emailTaken = false
        return
      }
      this.searchEmail = true
      AccountService.checkEmail(this.account.user.email).then(
        (response) => {
          this.emailTaken = !response.data.result
        },
        (error) => {
          console.log(error)
          alert('Failed to look up username!')
        }
      ).finally(() => {
        this.searchEmail = false
      })
    },

    setValidAdress: function (valid) {
      this.validAddress = valid
    },

    setAdress: function (address) {
      this.account.address = address
    }
  },
  computed: {
    countryCodes: function () {
      const codes = []
      this.countries.forEach(e => {
        codes.push({ id: e.id, text: e.isothreeletter + ' - +' + e.countrycode })
      })
      return codes
    }
  },
  mixins: [validationMixin],
  validations: {
    account: {
      user: {
        username: {
          required,
          minLength: minLength(5)
        },
        password: {
          required,
          minLength: minLength(6)
        },
        password2: {
          required,
          sameAsPassword: sameAs('password')
        },
        firstname: {
          required
        },
        lastname: {
          required
        },
        email: {
          required,
          email
        },
        phone: {
          required,
          minLength: minLength(10),
          cellPhone
        },
        countrycode_id: {
          required
        }
      },
      address: {
        address1: {
          required
        },
        zip: {
          required,
          minLength: minLength(5)
        },
        city: {
          required
        },
        state: {
          required
        },
        country_id: {
          required
        }
      }
    }
  }
}
</script>
<style lang="css">
.control-label {
  font-weight: bold;
}
.spacer {
  line-height: 1.5;
  height: 1rem;
  margin-bottom: 0.65rem;
  display: inline-block;
}
</style>
