<template>
  <b-container v-if="networkInfo" role="region" aria-label="privacy policy">
    <b-row>
      <b-col>
        <h1
          id="main-title"
          class="text-uppercase text-center text-lg"
        ><span>Privacy Policy</span></h1>
      </b-col>
    </b-row>
    <!-- custom content for this network -->
    <b-row>
      <b-col>
        <p v-if="lastupdate">
          Last Revised: {{ lastupdate }}
        </p>
        <p>
          Tres&oacute;na Solutions, LLC doing business as SBN Broadcasting (<b>"us," "our,"</b> and <b>"we"</b>) respect
          your privacy and are committed to protecting it through our compliance with this policy. This policy describes
          the types of information we may collect from you or that you may provide when you visit this website
          {{ networkInfo.name }} (the <b>"Website"</b>) and our practices for collecting, using, maintaining, protecting, and
          disclosing that information.
        </p>
        <p>
          This policy applies to information we collect:
        </p>
        <div style="margin-left:3%;">
          <p>On this Website.</p>
          <p>In email, text, and other electronic messages between you and this Website.</p>
          <p>
            When you interact with our advertising and applications on third-party websites and services, if those
            applications or advertising include links to this policy.
          </p>
          <p>When you provide us information by calling us.</p>
          <p>When you use a mobile application, API, or similar other tools that link to this Privacy Policy</p>
        </div>
        <p>It does not apply to information collected by: </p>
        <div style="margin-left:3%;">
          <p>
            Us offline or through any other means, including on any other website operated by us, including
            www.sbnbroadcasting.com, or any third party, (including our affiliates and subsidiaries); or
          </p>
          <p>
            Any third party, (including our affiliates and subsidiaries), including through any application or content
            (including advertising) that may link to or be accessible from or on the Website.
          </p>
        </div>
        <p>
          This Privacy Policy is part of the terms and conditions of the use of the Services ("Terms of Use"). You can
          find out more information regarding your rights and responsibilities in using the Services by reviewing
          our Terms of Use.
        </p>
        <p>
          Please read this policy carefully to understand our policies and practices regarding your information and how
          we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website.
          By accessing or using this Website, you agree to this privacy policy. This policy may change from time to time
          (see Changes to Our Privacy Policy). Your continued use of this Website after we make changes is deemed to be
          acceptance of those changes, so please check the policy periodically for updates.
        </p>
        <p>
        <ul>
          <li>This Privacy Policy explains:</li>
          <li>What information we collect and why we collect it.</li>
          <li>Use of cookies and similar technologies.</li>
          <li>How we use the information we collect.</li>
          <li>How we may disclose information we collect.</li>
          <li>Promotions, rewards programs and other programs.</li>
          <li>Your Rights and Choices.</li>
          <li>Information Security.</li>
          <li>Privacy of information about minors.</li>
          <li>Changes to this Privacy Policy.</li>
        </ul>
        </p>
        <p>
        <h3 class="text-center">INFORMATION WE COLLECT</h3>
        We receive and store information, including personal information, about you and your use of our Services. This
        information is gathered in a number of ways, including when you enter it on one of our Sites and from
        interactions with our Services. We collect two types of information: Personal Information and Non-Personal
        Information. "Personal Information" is information that identifies you or can reasonably be used to uniquely
        identify you. "Non-Personal Information" is all other information&mdash;it does not identify you. When you
        interact
        with us, we may ask you to supply us with Personal Information so that we can provide, enhance and personalize
        our Services and marketing efforts. We may combine the Personal Information you provide us with publicly
        available information about you as well as information from other sources.

        <h4
          style="text-decoration: underline;"
          class="m-3"
        >Information You Provide to Us</h4>
        We may collect information directly from you in a number of ways including through the Website or if you call or
        email us (e.g., by calling or emailing customer service). Our Services require you to become a subscriber and
        sign up for an account (an "Account"). When you do, we'll collect Personal Information like your name, email,
        credit card number, postal address, email address, phone number and other information. If you want to take
        advantage of any public sharing features we may offer, we might also ask you to create a publicly visible
        profile, which may include your name and photo and other information. The type of information that you provide
        to us will vary depending on the reason for the disclosure and Service provided, but may include things like:
        <ul>
          <li>Contact information, such as name, email address, postal address, and mobile number.</li>
          <li>Username (which may be your email address), password and security information to help us identify you.
          </li>
          <li>Age and date of birth.</li>
          <li>Other demographic information such as gender, job information, and zip code.</li>
          <li>Your preferences, such as favorite schools you would like to follow or preferred channels on this Website
            or others.</li>
          <li>Communications preferences, such as which newsletters you would like to receive.</li>
          <li>Search queries.</li>
          <li>Payment and identity verification information, such as a credit card number and related information.</li>
          <li>Contact information about others that you choose to provide (e.g., to refer a friend).</li>
          <li>Correspondence, images or other media you send to us.</li>
          <li>Comments or information that you leave on the Website or in a profile that are publicly visible.</li>
        </ul>
        <p>
          You also may be given opportunities to provide information to be published or displayed (hereinafter,
          <b>"posted"</b>) on public areas of the Website or transmitted to other users of the Website or third parties
          (collectively, <b>"User Contributions"</b>), including but not limited to information about what you watch and
          publicly viewable comments. Your User Contributions are posted on and transmitted to others at your own risk.
          Although we limit access to certain pages, please be aware that no security measures are perfect or
          impenetrable. Additionally, we cannot control the actions of other users of the Website with whom you may
          choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User
          Contributions will not be viewed by unauthorized persons.
        </p>
        <p>
          You may also be given the ability to post content to a social network service ("SNS") such as Facebook,
          Twitter, LinkedIn, or Instagram. If you choose to do this when given the opportunity, we will provide you
          information to such SNS. You acknowledge and agree that you are solely responsible for your use of each SNS
          and that it is your responsibility to review the terms of use and privacy policy of the third-party provider
          of such SNS. We will not be liable for: (i) the availability or accuracy of such SNS; (ii) the content,
          products, or services on or availability of such SNS; or (iii) your use of any SNS.
        </p>
        <h4
          style="text-decoration: underline;"
          class="m-3"
        >Information from Other Entities</h4>
        We also may receive information from other entities. Some of our Services may involve obtaining information from
        other entities such as credit reporting agencies, media companies, or companies that provide services or
        products to you such as devices or mobile device apps. In the case of some Services, we may receive this
        information based on an express or implied consent from you. In other cases, we may receive information that is
        publicly available.
        <h4
          style="text-decoration: underline;"
          class="m-3"
        >Information from Your Use of our Services</h4>
        We may collect information about the Services that you use and how you use them. This information includes:
        <div style="margin-left:3%;">
          <p>
            <b>Device information.</b> We may collect device-specific information (such as your hardware model,
            operating system version, unique device identifiers, and mobile network information including phone number).
            We may associate your device identifiers or phone number with your Account.
          </p>
          <p>
            <b>Log information.</b> When you use our Services or view content provided on the Website, we may
            automatically collect and store certain information in server logs. This may include:
          </p>
          <div style="margin-left:3%;">
            <p>Details of how you used our Service, including the content you have watched and the duration of time
              spent watching that content.</p>
            <p>Internet protocol address.</p>
            <p>
              Device event information such as crashes, system activity, hardware settings, browser type, browser
              language, the date and time of your request and referral URL.
            </p>
            <p>Cookies and other technology that may uniquely identify your browser or your Account (more information
              about cookies is below).</p>
          </div>
          <p>
            The information we collect automatically is only statistical data and does not include personal information,
            but we may maintain it or associate it
            with personal information we collect in other ways or receive from third parties. It helps us to improve our
            Website and to deliver a better and more
            personalized service, including by enabling us to:
          </p>
          <div style="margin-left:3%;">
            <p>Estimate our audience size and usage patterns.</p>
            <p>Store information about your preferences, allowing us to customize our Website according to your
              individual interests.</p>
            <p>Speed up your searches.</p>
            <p>Recognize you when you return to our Website.</p>
          </div>
          <p>
            <b>Interests and favorites.</b> We might collect data about your interests and favorites, such as the
            Services you use most often, information
            from your responses to questions, and teams you follow and other subjects or areas of interest to you. In
            addition to those you explicitly provide,
            your interests and favorites also might also be inferred or derived from other data we collect.
          </p>
          <p>
            <b>Content.</b> We collect the content of your files and communications when necessary to provide you with
            the Services you use. For example,
            if you provide us video, images or other media for sharing with others, we need to collect the content of
            that file or information to display it and
            store it. We also collect the content of messages you send to us, such as feedback, surveys and reviews you
            write, or questions and information you
            provide for customer support. When you contact us, such as for customer support, phone conversations or chat
            sessions with our representatives may
            be monitored and recorded.
          </p>
          <p>
            <b>Location information.</b> When you use a location-enabled Service, we may collect and process information
            about your actual location, like GPS
            signals sent by a mobile device. We also may use various technologies to determine location, such as sensor
            data from your device that may, for
            example, provide information on nearby Wi-Fi access points and cell towers.
          </p>
          <p>
            <b>Unique application numbers.</b> Certain Services might include a unique application number. This number
            and information about your installation
            (for example, the operating system type and application version number) may be sent to us when you install
            or uninstall that Service or when that
            Service periodically contacts our servers, such as for automatic updates.
          </p>
          <p>
            <b>Local storage.</b> We might collect and store information (including Personal Information) locally on
            your device using mechanisms such as
            browser web storage (including HTML5) and application data caches.
          </p>
        </div>
        <p>
        <h3 class="text-center">INFORMATION WE COLLECT THROUGH AUTOMATIC DATA COLLECTION TECHNOLOGIES</h3>
        <p>
          The technologies we use for automatic data collection may include:
        </p>
        <div style="margin-left:3%;">
          <p>
            <b>Cookies (or browser cookies).</b> A cookie is a small file placed on the hard drive of your computer. You
            may refuse to accept browser
            cookies by activating the appropriate setting on your browser. However, if you select this setting you may
            be unable to access certain parts of
            our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will
            issue cookies when you direct your
            browser to our Website.
          </p>
        </div>
        <p>
        <h3 class="text-center">HOW WE USE YOUR INFORMATION</h3>
        <p>
          We use information we collect from you for a number of purposes and in a number of ways.
        </p>
        <div style="margin-left:3%;">
          <p>
            We will use it for any purpose indicated at the time it is collected, e.g., if you enter a contest, we may
            use your information to contact you
            about the contest; if you purchase a subscription to the Website, we use the information to supply the
            Website and its content; if you sign up for
            a newsletter, we will use the information to send you the newsletter.
          </p>
          <p>
            We may use information we collect from you (alone or combined with other information) to better provide
            Services, improve our Website, understand
            your needs, improve our products, manage content and advertising, send you offers and information, make
            Service recommendations, maintain site
            security, comply with law, protect intellectual property, and measure the effectiveness of our Services. For
            example, certain content or advertising
            may be connected to particular Website pages or videos or otherwise connected to your use of the Services.
          </p>
        </div>
        <h4
          style="text-decoration: underline;"
          class="m-3"
        >Disclosure of Your Information</h4>
        <p>
          SBN Broadcasting is committed to maintaining your trust, and we want you to understand when and with whom we
          may share information collected about you.
        <ul>
          <li>We may share your Personal Information with our parent companies, subsidiaries, and affiliates.</li>
          <li>We may share your Personal Information with the organization (e.g., school, school district, music
            educators association, or other individual, government or legal entity) for whom this Website was built. The
            organization's name and branding features prominently on the Website.</li>
          <li>We share your information with third-party vendors who help us with specialized services such as customer
            support, email and text message deployment, business analytics, marketing, and data processing. These third
            parties are allowed to use your information to help us provide our Services. For example, we may share
            information with partners that analyze data regarding Website performance, provide applications and services
            such as automatic calendaring, subscription processing or email-related services.</li>
          <li>We reserve the right to transfer information about you to a successor organization if, for example, we
            transfer the ownership of some or all of our assets, transfer operations of a Website, enter bankruptcy, or
            merge with another organization. If such a transfer occurs, we will use reasonable efforts to direct the
            transferee to use Personal Information in a manner consistent with this Privacy Policy. Following such a
            sale or transfer, you may contact the entity to which we transferred your Personal Information with any
            inquiries concerning the processing of that information.</li>
          <li>We may share your Personal Information to fulfill the purpose for which you provide it, for any other
            purpose disclosed by us when you provide the information, and/or with your consent.</li>
        </ul>
        </p>
        <p>
          We may also disclose your personal information:
        <ul>
          <li>
            We may disclose information about our users, including contact information, to respond to subpoenas, court
            orders, legal process, and other law enforcement measures and regulatory requests, and to comply with other
            legal obligations, such as FCC requirements.
          </li>
          <li>
            To enforce or apply our <a href="/terms">terms of use</a> and other agreements, including for billing and
            collection purposes.
          </li>
          <li>
            If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of
            Tres&oacute;na
            Solutions, LLC, our customers, or others. This includes exchanging information with other companies and
            organizations for the purposes of fraud protection and credit risk reduction.
          </li>
        </ul>
        </p>
        <p>
          We share information that is Non-Personal Information and may do so for any reason. Likewise, we may share
          aggregated or anonymized information (information that has had all data identifying an individual removed)
          with third parties, including to help us develop content and services we hope you will find of interest. This
          sharing of anonymized or aggregated information will not include Personal Information.
        </p>
        <h4
          style="text-decoration: underline;"
          class="m-3"
        >Disclosure of Your Information</h4>
        <h4
          style="font-style: italic;"
          class="m-3"
        >Newsletters and Promotional Emails</h4>
        <p>
          We, and/or the organization (e.g., school, school district, music educators association, or other individual,
          government or legal entity) for whom this Website was built, may use your e-mail address or other methods to
          send you newsletters, as well as messages about new Website features, special offers, promotional
          announcements, consumer surveys and other correspondence concerning our Services. If you no longer want to
          receive marketing promotions from us, simply use your Account name and password to access your Account
          information on the Website and uncheck those items to unsubscribe or click on the unsubscribe link in our
          email communications. Please note that you cannot unsubscribe from certain correspondence from us that is not
          primarily marketing, including messages relating to your Account transactions.
        </p>
        <h4
          style="font-style: italic;"
          class="m-3"
        >Rewards Programs, Contests, Etc.</h4>
        <p>
          Enrolling in a SBN Broadcasting rewards program or contest is completely voluntary. When you enroll in a
          rewards program or contest, we will collect Personal Information about you that may include your name,
          address, email, phone number, images and/or other information. We store this information so that we can
          administer the program properly, process your rewards and winnings (if any), and communicate offers to you
          based on your interests. From time to time, we may ask you to supply other information about your household's
          consumer interests. Certain information may be required in order to participate in a rewards program or
          contest and other information may be voluntary. If you do not wish to provide any mandatory information, you
          may choose not to participate in any rewards program or contest.
        </p>
        <p>
          Each rewards program and contest may have additional terms and conditions or policies that supersede this
          Privacy Policy. Please review the Terms of Use and any terms and policies connected with each such program and
          contest.
        </p>
        <h4
          style="text-decoration: underline;"
          class="m-3"
        >Choices About How We Use and Disclose Your Information</h4>
        <p>
          We strive to provide you with choices regarding the personal information you provide to us. We have created
          mechanisms to provide you with the following control of your information:
        </p>
        <div style="margin-left:3%;">
          <p>
            <b>Cookies.</b> As we noted above, you may set your browser to block all cookies, including cookies
            associated with our Services, or to indicate when a cookie is being set by us. The Help portion of the
            toolbar on most browsers will tell you how to prevent your browser from accepting new cookies, how to have
            the browser notify you when you receive a new cookie, or how to disable cookies altogether. Please remember
            that if your browser is set not to accept any cookies, you likely will not be able to use SBN Broadcasting
            Services or the Website or enjoy full functionality of the Services.
          </p>
          <p>
            <b>Promotional Offers from the Company.</b> If you do not wish to have your email address used by us or the
            organization for whom this Website was built to promote our (or its) own or third parties' products or
            services, you can opt-out by checking the relevant box located on the form on which we collect your data or
            at any other time by logging into the Website and adjusting your user preferences in your Account profile by
            checking or unchecking the relevant boxes or by sending us an email stating your request to
            <a href="mailto:privacy@sbnbroadcasting.com">privacy@sbnbroadcasting.com</a>. If we have sent you a
            promotional email, you may send us a return email asking to be omitted from future email distributions. This
            opt out does not apply to information provided to you as a result of a product purchase, warranty
            registration, product service experience or other transactions.
          </p>
        </div>
        <p>
          We do not control third parties' collection or use of your information to serve interest-based advertising.
          However, these third parties may provide you with way to choose not to have your information collected or used
          in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative
          ("NAI") on the NAI's website.
        </p>
        <p>
          California residents may have additional personal information rights and choices. Please see
          <b>Your California Privacy Rights</b> (below) for more information.
        </p>
        <p>
          Nevada residents who wish to exercise their sale opt-out rights under Nevada Revised Statutes Chapter 603A may
          submit a request to this designated address:
        </p>
        <div style="margin-left:3%;">
          <p>
            Privacy Team<br>
            Tres&oacute;na Solutions, LLC<br>
            dba SBN Broadcasting<br>
            4720 E. Cotton Gin Loop #135<br>
            Phoenix, AZ 85040
          </p>
        </div>
        <p>
          However, please know we do not currently sell data triggering that statute's opt-out requirements.
        </p>
        <h4
          style="font-style: italic;"
          class="m-3"
        >Information You Share</h4>
        <p>
          Many of our Services let you share information with others. Remember that when you share information publicly,
          it may be indexable by search engines. Our Services provide you with different options on sharing and removing
          your content.
        </p>
        <h4
          style="font-style: italic;"
          class="m-3"
        >Accessing and Updating Your Personal Information.</h4>
        <p>
          You can review and change your personal information by logging into the Website and visiting <b>My Account, My
            Subscription,</b> and <b>My Credit Cards</b> pages of your Account.
        </p>
        <p>
          If you choose to share contact information with us, you have the opportunity to update that information on our
          Website. You also may update or amend your information by contacting us via email at
          <a href="mailto:support@sbnbroadcasting.com">support@sbnbroadcasting.com</a>. If you ask us to deactivate your
          Account, we will disable your Account within a reasonable period of time. Please note that we may retain some
          information about you in order to address legal and security concerns. For example, some of your information
          may remain in back-up storage even if you ask us to delete it. In some cases, you may be entitled under local
          laws to access or object to the processing of information that we hold relating to you. We cannot delete your
          personal information except by also deleting your Account.
        </p>
        <p>
          We may reject requests that are unreasonably repetitive, require disproportionate technical effort (for
          example, developing a new system or fundamentally changing an existing practice), risk the privacy of others,
          or would be impractical (for instance, requests concerning information residing on backup systems). We may
          also not accommodate a request to change information if we believe the change would violate any law or legal
          requirement or cause the information to be incorrect.
        </p>
        <p>
          Where we can provide information access and correction, we will do so for free, except where it would require
          a disproportionate effort. We aim to maintain our Services in a manner that protects information from
          accidental or malicious destruction. Because of this, after you delete information from our Services, we may
          not immediately delete residual copies from our active servers and may not remove information from our backup
          systems.
        </p>
        <p>
          Access, correction, or deletion requests can be made by contacting Customer Support as provided below.
        </p>
        <p>
          California residents may have additional personal information rights and choices. Please see <b>Your
            California Privacy Rights</b> (below) for more information.
        </p>
        <h4
          style="font-style: italic;"
          class="m-3"
        >Do Not Track</h4>
        <p>
          We do not use any technology to, nor do we, collect information about your online activities over time and
          across third-party websites or other online services (behavioral tracking).
        </p>
        <p>
        <h3 class="text-center">YOUR CALIFORNIA PRIVACY RIGHTS</h3>
        <p>
          This section is applicable to residents of California. If you are a resident of California, you have certain
          rights described below. The following do not apply to individuals who do not live in California on a permanent
          basis. <b>Tres&oacute;na Solutions, LLC d/b/a SBN Broadcasting does not sell our user's private
            information.</b>
        </p>
        <h4
          style="text-decoration: underline;"
          class="m-3"
        >Rights Provided by California Civil Code &sect; 1798.83</h4>
        <p>
          A California resident who has provided personal data to a business with whom he/she has established a business
          relationship for personal, family, or household purposes (a <b>"California Customer"</b>) may request
          information about whether the business has disclosed personal information to any third parties for the third
          parties' direct marketing purposes. In general, if the business has made such a disclosure of personal data,
          upon receipt of a request by a California Customer, the business is required to provide a list of all third
          parties to whom personal data was disclosed in the preceding calendar year, as well as a list of the
          categories of personal data that were disclosed. California Customers may request further information about
          our compliance with this law by mailing us at
        </p>
        <div style="margin-left:3%;">
          Privacy Team<br>
          Tres&oacute;na Solutions, LLC<br>
          dba SBN Broadcasting<br>
          4720 E. Cotton Gin Loop #135<br>
          Phoenix, AZ 85040
        </div>
        <p>
          or emailing <a href="mailto:privacy@sbnbroadcasting.com">privacy@sbnbroadcasting.com</a>. Please note that we
          are only required to respond to two requests per California Customer each year under Code &sect; 1798.83.
        </p>
        <h4
          style="text-decoration: underline;"
          class="m-3"
        >You have a Right to Know about Personal Information Collected,
          Disclosed, or Sold</h4>
        <p>
          California residents have the right to request that Tres&oacute;na Solutions, LLC disclose what personal
          information it collects, uses, discloses, and sells. This is called the "Right to Know". Under the Right to
          Know, you can request a listing of the types of personal information we have collected about you, the sources
          of that information, how we use the information (e.g., our business or commercial purposes for collecting or
          selling personal information), other individuals and business with whom we share personal information, and the
          specific pieces of personal information that we have collected about you. If you would like the above
          information, you may contact us through our webpage or customer service. Contact information is at the bottom
          of this section. When you make a request under your Right to Know, you can expect the following:
        <ul>
          <li>
            We will verify your identity. You will need to provide us the following: (i) your first and last name and
            email address; and (ii) describe your request with sufficient detail that allows us to properly understand,
            evaluate, and respond to it. We cannot respond to your request or provide you with personal information if
            we cannot verify your identity or authority to make the request and confirm the personal information relates
            to you.
          </li>
          <li>
            We will confirm our receipt of your request within 10 days. If you have not received a response within a few
            days after that, please let us know by contacting us at the webpage listed below.
          </li>
          <li>
            We will respond to your request within 45 days. If necessary, we may need an additional period of time, up
            to another 45 days, but we will reply either way within the first 45-day period and, if we need an
            extension, we will explain why.
          </li>
          <li>
            In certain cases, a Request to Know may be denied, for example, if we cannot verify your identity or if
            providing you the information could create an unreasonable risk to someone's security (for example, we do
            not want very sensitive information disclosed inappropriately). If we deny your request, we will explain why
            we denied it. If we deny a request, we will still try to provide you as much of the information as we can,
            but we will withhold the information subject to denial.
          </li>
        </ul>
        </p>
        <p>
          "Personal information," for purposes of this section regarding the rights of California residents, does not
          include publicly available information that is lawfully made available to the general public from federal,
          state, or local government records.
        </p>
        <p>
          Please note that Tres&oacute;na Solutions, LLC <b>does not sell</b> its customers personal information.
        </p>
        <h4
          style="text-decoration: underline;"
          class="m-3"
        >You have a Right to Request Deletion of Personal Information about You
        </h4>
        <p>
          California consumers have a right to request the deletion of their personal information collected or
          maintained by Tres&oacute;na Solutions, LLC. If you would like information about you to be deleted, you may
          contact us through our webpage or customer service. Contact information is at the bottom of this section. When
          you make a request for deletion, you can expect the following:
        <ul>
          <li>After you request deletion, you will need to confirm that you want your information deleted.</li>
          <li>
            We will verify your identity. You will need to provide us the following: (i) your first and last name and
            email address; and (ii) describe your request with sufficient detail that allows us to properly understand,
            evaluate, and respond to it. We cannot respond to your request or provide you with personal information if
            we cannot verify your identity or authority to make the request and confirm the personal information relates
            to you.
          </li>
          <li>
            We will confirm our receipt of your request within 10 days. If you have not received a response within a few
            days after that, please let us know by contacting us at the webpage or phone number listed below.
          </li>
          <li>
            We will respond to your request within 45 days. If necessary, we may need an additional period of time, up
            to another 45 days, but we will reply either way within the first 45-day period and, if we need an
            extension, we will explain why.
          </li>
          <li>
            In certain cases, a request for deletion may be denied, for example, if we cannot verify your identity, the
            law requires that we maintain the information (e.g., in case of a recall) or if we need the information for
            internal purposes such as keeping records of any warranties. If we deny your request, we will explain why we
            denied it, treat your request an "opt-out" of the sale of information (as described in our Notice of Right
            to Opt-Out), and delete any other information that is not protected from deletion.
          </li>
        </ul>
        </p>
        <h4
          style="text-decoration: underline;"
          class="m-3"
        >Right to Non-Discrimination for the Exercise of a Consumer's Privacy
          Rights</h4>
        <p>
          You have a right not to receive discriminatory treatment by Tres&oacute;na Solutions, LLC for exercising any
          of your
          privacy rights conferred by the CCPA. Tres&oacute;na Solutions, LLC will not discriminate against any
          California
          consumer because such person exercised any of the consumer's rights under CCPA, including, but not limited to:
        <ul>
          <li>Denying goods or services.</li>
          <li>
            Charging different prices or rates for goods and services, including through the use of discounts or other
            benefits or imposing penalties.
          </li>
          <li>
            We will confirm our receipt of your request within 10 days. If you have not received a response within a few
            days after that, please let us know by contacting us at the webpage listed below.
          </li>
          <li>Providing a different level or quality of goods or services.</li>
          <li>
            Suggesting that the consumer will receive a different price or rate for goods or services or a different
            level or quality of goods or services.
          </li>
        </ul>
        </p>
        <p>
          Tres&oacute;na Solutions, LLC may, however, charge a different price or rate, or provide a different level or
          quality of goods or services, if that difference is related to the value provided to you by your data.
        </p>
        <p>
          To submit a request to access or delete your personal information, please contact <a href="mailto:privacy@sbnbroadcasting.com">privacy@sbnbroadcasting.com</a>. You may also contact us to make
          any requests related to your personal information using our contact information below.
        </p>
        <h4 style="text-decoration: underline;">Authorized Agents</h4>
        <p>
          If you would like, you may designate an authorized agent to make a request under the CCPA on your behalf. We
          will deny requests from agents that do not submit proof of authorization from you. To verify that an
          authorized agent has authority to act for you, we may require a copy of a power of attorney or require that
          you provide the authorized agent with written permission and verify your own identity with us.
        </p>
        <h4 style="text-decoration: underline;">Contact Information</h4>
        <p>
          To make a consumer request described above, you may make a request via email to <a href="mailto:privacy@sbnbroadcasting.com">privacy@sbnbroadcasting.com</a> or write us at:
        </p>
        <div style="margin-left:3%;">
          Privacy Team<br>
          Tres&oacute;na Solutions, LLC<br>
          dba SBN Broadcasting<br>
          4720 E. Cotton Gin Loop #135<br>
          Phoenix, AZ 85040
        </div>
        <p>
        <h3 class="text-center">INFORMATION SECURITY</h3>
        <p>
          We take information security very seriously and use reasonable administrative, technical, physical and
          administrative measures to protect your Personal Information from unauthorized access. Unfortunately, no
          security system can be guaranteed to be 100% secure. Accordingly, we cannot guarantee the security of your
          information and cannot assume liability for improper access to it. By using our Services, including this
          Website and user interfaces, or providing information to us through any means, you agree that we can
          communicate with you electronically regarding security, privacy, and administrative issues relating to your
          use of the Services. You are responsible for maintaining the confidentiality of your Account access
          information and for restricting access to your computer or device through which you access your Account. If
          you disclose your password to anyone or share your Account and/or devices with other people, you take full
          responsibility for their actions. Where possible, users of public or shared devices should log out at the
          completion of each visit. If you sell or return a computer or device, you should log-out and deactivate the
          device and delete all information on the device by a process that ensures complete removal. If you fail to log
          out and deactivate your device, subsequent users may be able to access your Account information.
        </p>
        <p>
        <h3 class="text-center">LOCATION OF DATA AND DATA TRANSFERS</h3>
        <p>
          Our Services are solely intended for individuals located within the United States and its territories and are
          subject to U.S. law. If you are located outside of the United States and its territories, please do not use
          the Services. By utilizing the Services, you consent to the transfer of your data to the United States and its
          maintenance in the United States.
        </p>
        <p>
          We may transfer the Personal Information we collect about you to countries other than the country in which the
          information was originally collected. Those countries may not have the same data protection laws as the
          country in which you initially provided the information. When we transfer your information to other countries,
          we will protect that information as described in this Privacy Policy.
        </p>
        <p>
        <h3 class="text-center">USE OF THIS WEBSITE BY CHILDREN UNDER THE AGE OF THIRTEEN</h3>
        <p>
          We do not intentionally gather Personal Information about Site or App users who are under the age of 13.
          Furthermore, we do not intentionally allow Website users under the age of 18 (or older if the age of majority
          is more than 18). If you are a minor, please do not provide us any Personal Information or use the Services
          without receiving your parent's or guardian's permission. If a child under 13 submits Personal Information to
          Tres&oacute;na Solutions, LLC and we learn that the Personal Information is the information of a child under
          13, we will attempt to delete the information as soon as possible. If you believe that we might have any
          Personal Information from a child under 13, please contact us at
        </p>
        <div style="margin-left:3%;">
          Privacy Team<br>
          Tres&oacute;na Solutions, LLC<br>
          dba SBN Broadcasting<br>
          4720 E. Cotton Gin Loop #135<br>
          Phoenix, AZ 85040
        </div>
        <p>
          or email us at <a href="mailto:privacy@sbnbroadcasting.com">privacy@sbnbroadcasting.com</a>.
        </p>
        <p>
          California residents under 16 years of age may have additional rights regarding the collection and sale of
          their personal information. Please see Your California Privacy Rights (above) for more information.
        </p>
        <p>
        <h3 class="text-center">PRIVACY POLICY UPDATES</h3>
        <p>
          It is our policy to post any changes we make to our Privacy Policy on this page with a notice that the privacy
          policy has been updated on the Website home page. If we make material changes to how we treat our users'
          personal information, we will notify you by email to the email address specified in your Account and/or
          through a notice on the Website home page. You may also be required to consent to the updated privacy policy
          when you first log in to your Account after the policy has been updated. The date the privacy policy was last
          revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active
          and deliverable email address for you, and for periodically visiting our Website and this privacy policy to
          check for any changes.
        </p>
        <p>
        <h3 class="text-center">CONTACTING US</h3>
        <p>
          If you have questions concerning your Account or this Privacy Policy, including the treatment of your Personal
          Information, please contact us via email at <a href="mailto:privacy@sbnbroadcasting.com">privacy@sbnbroadcasting.com</a> or at the following:
        </p>
        <div style="margin-left:3%;">
          Privacy Team<br>
          Tres&oacute;na Solutions, LLC<br>
          dba SBN Broadcasting<br>
          4720 E. Cotton Gin Loop #135<br>
          Phoenix, AZ 85040
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import UserService from '@/services/UserService'
import NetworkService from '@/services/NetworkService'

export default {
  name: 'Privacy',
  data () {
    return {
      networkInfo: null,
      lastupdate: null
    }
  },
  async mounted () {
    await this.getNetworkInfo()
    await this.getLastUpdate()
  },
  methods: {
    async getNetworkInfo () {
      this.networkInfo = await UserService.getNetworkInfo()
    },
    async getLastUpdate () {
      const info = await NetworkService.getPolicyLastUpdated(10)
      const date = new Date(info.data.updated)
      this.lastupdate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
    }
  }
}
</script>
