<template>
  <div>
    <b-modal
      v-model="open"
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      :loading="loading"
    >
      <template #modal-header> Please Change Your Password </template>
      <b-overlay :show="loading">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="control-label" for="password">Password:</label>
              <b-input-group>
                <b-form-input
                  type="password"
                  v-model="user.password"
                  :state="!$v.user.password.$error ? null : false"
                  @blur="$v.user.password.$touch()"
                  name="password"
                  id="password"
                  required
                  minlength="6"
                  placeholder="New Password"
                  autocomplete="new-password"
                >
                </b-form-input>
                <b-form-invalid-feedback
                  v-for="err in getErrors('user', 'password', [
                    'required',
                    'minLength',
                  ])"
                  :key="err.id"
                >
                  {{ err }}
                </b-form-invalid-feedback>
              </b-input-group>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="control-label">Password Confirm:</label>
              <b-input-group>
                <b-form-input
                  type="password"
                  v-model="user.password2"
                  :state="!$v.user.password2.$error ? null : false"
                  @blur="$v.user.password2.$touch()"
                  name="password2"
                  id="password2"
                  required
                  placeholder="New Password Confirm"
                  autocomplete="new-password"
                >
                </b-form-input>
                <b-form-invalid-feedback
                  v-for="err in getErrors('user', 'password2', [
                    'required',
                    'sameAsPassword',
                  ])"
                  :key="err.id"
                >
                  {{ err }}
                </b-form-invalid-feedback>
              </b-input-group>
            </div>
          </div>
        </div>
      </b-overlay>
      <template #modal-footer>
        <b-button
          @click="submit()"
          :disabled="!$v.$anyDirty || $v.user.$anyError || loading"
          >Submit</b-button
        >
      </template>
    </b-modal>
  </div>
</template>
<script>
import UserService from '@/services/UserService'
import AccountService from '@/services/AccountService'
import { validationMixin } from 'vuelidate'
import {
  required,
  minLength,
  sameAs
} from 'vuelidate/lib/validators'
import { bus } from '@/main'

export default {
  data: () => {
    return {
      user: {
        password: null,
        password2: null
      },
      open: false,
      loading: false
    }
  },
  created () {
    this.init()
    // when user logs in, check again if we need a password change
    bus.$on('loggedIn', () => {
      this.init()
    })
  },
  methods: {
    init () {
      // check if the user is logged in and if the user needs a password change
      UserService.getUserInfo().then(
        (result) => {
          if (result && result.pwchange && result.pwchange === '1') {
            this.open = true
          }
        }
      ).catch(error => {
        this.$aim.error(error.response)
      })
    },
    submit () {
      this.loading = true
      // check form validity
      if (this.$v.$invalid || this.$v.user.$anyError) {
        this.loading = false
        this.$aim.error(null, 'warn', 'Error', 'Something is wrong in the form')
        return
      }

      const data = {
        reset: {
          password: this.user.password
        }
      }

      // save the password change
      AccountService.savePwChange(data).then(
        () => {
          // refresh the user data after the password change
          UserService.refresh().then(
            (refresh) => {
              // set the local storage with the new refreshed data
              UserService.setStorage(refresh.data)
              // close the modal since it was successful
              this.open = false
              // give the user a success message
              this.$aim.notify(null, 'info', 'Password Reset', 'You successfully reset your password')
            }
          )
        },
        (error) => {
          this.$aim.error(error.response)
        }
      ).finally(() => { this.loading = false })
    },
    getErrors: function (form, field, validators) {
      // get validation errors for each field
      const err = []
      if (!this.$v[form][field].$dirty) return err

      validators.forEach(e => {
        if (!this.$v[form][field][e]) {
          e === 'required' && err.push('This field is required')
          e === 'sameAsPassword' && err.push('Passwords do not match')
          e === 'minLength' && err.push(field.capitalize() + ' must be at least ' + this.$v[form][field].$params.minLength.min + ' characters')
        }
      })

      return err
    }
  },
  mixins: [validationMixin],
  validations: {
    user: {
      password: {
        required,
        minLength: minLength(6)
      },
      password2: {
        required,
        sameAsPassword: sameAs('password')
      }
    }
  }
}
</script>
