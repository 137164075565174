var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid",attrs:{"role":"region","aria-label":"account information"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.loading)?_c('b-spinner',{attrs:{"label":"'Loading Account Info'"}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"form-wrapper"},[_c('fieldset',[_c('legend',[_vm._v("Edit Account")]),_c('b-card',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticStyle:{"font-size":"1.2em"}},[_vm._v("User Account Information")])]},proxy:true}])},[_c('b-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"username"}},[_vm._v("User Name (5 minimum):")]),_c('b-input-group',[_c('b-form-input',{attrs:{"type":"text","disabled":!_vm.editUsername,"state":!_vm.$v.account.user.username.$error && !_vm.usernameTaken
                            ? null
                            : false,"minlength":"5","placeholder":"Desired Username","autocomplete":"username"},on:{"blur":function($event){return _vm.$v.account.user.username.$touch()},"input":function($event){return _vm.checkUsername()}},model:{value:(_vm.account.user.username),callback:function ($$v) {_vm.$set(_vm.account.user, "username", $$v)},expression:"account.user.username"}}),_c('b-input-group-append',[(_vm.editUsername)?_c('b-input-group-text',[_c('b-icon',{attrs:{"icon":"arrow-repeat","animation":_vm.searchUsername ? 'spin' : ''}})],1):_c('button',{staticClass:"btn btn-danger",on:{"click":function($event){$event.preventDefault();_vm.editUsername = !_vm.editUsername}}},[_c('b-icon',{attrs:{"icon":"pencil-square"}})],1)],1),_vm._l((_vm.getErrors('user', 'username', [
                          'required',
                          'minLength' ])),function(err){return _c('b-form-invalid-feedback',{key:err.id},[_vm._v(" "+_vm._s(err)+" ")])}),(_vm.usernameTaken)?_c('b-form-invalid-feedback',[_vm._v(" Username is taken ")]):_vm._e()],2)],1)]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"password"}},[_vm._v("Password:")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editPassword),expression:"!editPassword"}]},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.editPassword),expression:"!editPassword"}],staticClass:"btn btn-danger",on:{"click":function($event){$event.preventDefault();_vm.editPassword = !_vm.editPassword}}},[_c('b-icon',{attrs:{"icon":"pencil-square"}}),_vm._v(" Edit ")],1)]),_c('b-input-group',[_c('b-form-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.editPassword),expression:"editPassword"}],attrs:{"type":"password","disabled":_vm.searchUsername,"state":!_vm.$v.account.user.password.$error ? null : false,"name":"password","id":"password","required":"","minlength":"6","placeholder":"New Password","autocomplete":"new-password"},on:{"blur":function($event){return _vm.$v.account.user.password.$touch()}},model:{value:(_vm.account.user.password),callback:function ($$v) {_vm.$set(_vm.account.user, "password", $$v)},expression:"account.user.password"}}),_vm._l((_vm.getErrors('user', 'password', [
                          'required',
                          'minLength' ])),function(err){return _c('b-form-invalid-feedback',{key:err.id},[_vm._v(" "+_vm._s(err)+" ")])})],2)],1)]),_c('div',{staticClass:"col-sm-4"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editPassword),expression:"editPassword"}],staticClass:"form-group"},[_c('label',{staticClass:"control-label"},[_vm._v("Password Confirm:")]),_c('b-input-group',[_c('b-form-input',{attrs:{"type":"password","disabled":_vm.searchUsername,"state":!_vm.$v.account.user.password2.$error ? null : false,"name":"password2","id":"password2","required":"","placeholder":"New Password Confirm","autocomplete":"new-password"},on:{"blur":function($event){return _vm.$v.account.user.password2.$touch()}},model:{value:(_vm.account.user.password2),callback:function ($$v) {_vm.$set(_vm.account.user, "password2", $$v)},expression:"account.user.password2"}}),_vm._l((_vm.getErrors('user', 'password2', [
                          'required',
                          'sameAsPassword' ])),function(err){return _c('b-form-invalid-feedback',{key:err.id},[_vm._v(" "+_vm._s(err)+" ")])})],2)],1)])])])],1),_c('b-card',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticStyle:{"font-size":"1.2em"}},[_vm._v(" Personal Information "),_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){_vm.editInfo = !_vm.editInfo}}},[_c('b-icon',{attrs:{"icon":"pencil-square"}}),_vm._v(" Edit ")],1)],1)]},proxy:true}])},[_c('b-card-text',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"firstname"}},[_vm._v("First Name")]),_c('b-input-group',[_c('b-form-input',{attrs:{"type":"text","name":"firstname","id":"firstname","state":!_vm.$v.account.user.firstname.$error ? null : false,"disabled":_vm.loading || !_vm.editInfo,"placeholder":"First","autocomplete":"given-name"},on:{"blur":function($event){return _vm.$v.account.user.firstname.$touch()}},model:{value:(_vm.account.user.firstname),callback:function ($$v) {_vm.$set(_vm.account.user, "firstname", $$v)},expression:"account.user.firstname"}}),_vm._l((_vm.getErrors('user', 'firstname', [
                          'required' ])),function(err){return _c('b-form-invalid-feedback',{key:err.id},[_vm._v(" "+_vm._s(err)+" ")])})],2)],1)]),_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"lastname"}},[_vm._v("Last Name:")]),_c('b-input-group',[_c('b-form-input',{attrs:{"type":"text","name":"lastname","id":"lastname","state":!_vm.$v.account.user.lastname.$error ? null : false,"disabled":_vm.loading || !_vm.editInfo,"placeholder":"Last","autocomplete":"family-name"},on:{"blur":function($event){return _vm.$v.account.user.lastname.$touch()}},model:{value:(_vm.account.user.lastname),callback:function ($$v) {_vm.$set(_vm.account.user, "lastname", $$v)},expression:"account.user.lastname"}}),_vm._l((_vm.getErrors('user', 'lastname', [
                          'required' ])),function(err){return _c('b-form-invalid-feedback',{key:err.id},[_vm._v(" "+_vm._s(err)+" ")])})],2)],1)])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"email"}},[_vm._v("Email Address:")]),_c('b-input-group',[_c('b-form-input',{attrs:{"type":"email","name":"email","id":"email","placeholder":"Email","state":!_vm.$v.account.user.email.$error && !_vm.emailTaken
                            ? null
                            : false,"debounce":"700","disabled":_vm.loading || !_vm.editInfo,"autocomplete":"email"},on:{"blur":function($event){return _vm.$v.account.user.email.$touch()},"input":function($event){return _vm.checkEmail()}},model:{value:(_vm.account.user.email),callback:function ($$v) {_vm.$set(_vm.account.user, "email", $$v)},expression:"account.user.email"}}),_c('b-input-group-append',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchEmail),expression:"searchEmail"}]},[_c('b-input-group-text',[_c('b-icon',{attrs:{"icon":"arrow-repeat","animation":"spin"}})],1)],1),(_vm.emailTaken)?_c('b-form-invalid-feedback',[_vm._v(" This email is taken ")]):_vm._e(),_vm._l((_vm.getErrors('user', 'email', [
                          'required',
                          'email' ])),function(err){return _c('b-form-invalid-feedback',{key:err.id},[_vm._v(" "+_vm._s(err)+" ")])})],2)],1)]),_c('div',{staticClass:"col-sm-2"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"countrycode"}},[_vm._v("Phone Country")]),_c('b-form-group',[_c('b-form-select',{attrs:{"options":_vm.countryCodes,"disabled":_vm.loading || !_vm.editInfo,"value-field":"id","text-field":"text","state":!_vm.$v.account.user.countrycode_id.$error
                            ? null
                            : false},on:{"input":function($event){return _vm.$v.account.user.countrycode_id.$touch()}},scopedSlots:_vm._u([{key:"text-field",fn:function(){return undefined},proxy:true},{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null,"disabled":""}},[_vm._v("Country Code")])]},proxy:true}]),model:{value:(_vm.account.user.countrycode_id),callback:function ($$v) {_vm.$set(_vm.account.user, "countrycode_id", $$v)},expression:"account.user.countrycode_id"}}),_vm._l((_vm.getErrors('user', 'countrycode_id', [
                          'required' ])),function(err){return _c('b-form-invalid-feedback',{key:err.id},[_vm._v(" "+_vm._s(err)+" ")])})],2)],1)]),_c('div',{staticClass:"col-sm-4"},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"control-label",attrs:{"for":"phone"}},[_vm._v("Phone:")]),_c('div',[_c('b-input-group',[_c('b-form-input',{attrs:{"type":"text","name":"phone","id":"phone","placeholder":"Phone","state":!_vm.$v.account.user.phone.$error ? null : false,"disabled":_vm.loading || !_vm.editInfo,"autocomplete":"tel-national"},on:{"blur":function($event){return _vm.$v.account.user.phone.$touch()}},model:{value:(_vm.account.user.phone),callback:function ($$v) {_vm.$set(_vm.account.user, "phone", $$v)},expression:"account.user.phone"}}),_vm._l((_vm.getErrors('user', 'phone', [
                            'required',
                            'cellPhone' ])),function(err){return _c('b-form-invalid-feedback',{key:err.id},[_vm._v(" "+_vm._s(err)+" ")])})],2)],1)])])]),_c('AddressForm',{attrs:{"mainaddress":_vm.startingAdddress,"disabled":_vm.loading || !_vm.editInfo,"states":_vm.states,"countries":_vm.countries},on:{"isValid":_vm.setValidAdress,"dataChange":_vm.setAdress}})],1)],1),_c('div',{staticClass:"custom-control custom-checkbox mt-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.account.user.promo),expression:"account.user.promo"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"promo"},domProps:{"checked":Array.isArray(_vm.account.user.promo)?_vm._i(_vm.account.user.promo,null)>-1:(_vm.account.user.promo)},on:{"change":function($event){var $$a=_vm.account.user.promo,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.account.user, "promo", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.account.user, "promo", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.account.user, "promo", $$c)}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"promo"}},[_vm._v(" I wish to receive email notifications when new videos are uploaded or new live stream events are addded to the network. I also understand that from time to time I may receive newsletters and promotional material from SBN Broadcasting. These email preferences can be changed at any time by accessing the \"my account\" section of the network. ")])])],1),_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-xs-12 mx-auto"},[_c('button',{staticClass:"btn btn-success",attrs:{"disabled":!_vm.$v.$anyDirty ||
                _vm.$v.account.$anyError ||
                _vm.loading ||
                !_vm.validAddress},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save ")])])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }