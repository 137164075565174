<template>
  <b-container v-if="networkInfo">
    <b-row>
      <b-col class="text-center">
        <h1>Welcome to the video home of {{ networkInfo.name }}</h1>
        <p>Already have an account? <a href="" @click.prevent="showLoginModal = true">Log in here</a>.</p>
      </b-col>
    </b-row>
    <!-- custom content for this network -->
    <b-row v-if="content">
      <b-col
        class="text-center"
        v-html="content"
      ></b-col>
    </b-row>

    <!-- subscription selections -->
    <SubscriptionTypes :types="networkInfo.subscriptiontypes"></SubscriptionTypes>

    <b-row>
      <b-col class="text-center">
        <hr />
        <h1>Scheduled Events</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        class="mb-4"
      >
        <StreamList
          showNoResults
          showloading
        ></StreamList>
      </b-col>
    </b-row>

    <Login :show="showLoginModal"></Login>
  </b-container>
</template>

<script>
import UserService from '@/services/UserService'
import NetworkService from '@/services/NetworkService'
import SubscriptionTypes from '@/components/SubscriptionTypes'
import StreamList from '@/components/schedule/List'
import Login from '@/components/Login'

export default {
  name: 'Home',
  components: {
    SubscriptionTypes,
    StreamList,
    Login
  },
  data () {
    return {
      networkInfo: null,
      userInfo: null,
      content: null,
      showLoginModal: false
    }
  },
  async mounted () {
    await this.getNetworkInfo()
    await this.getUserInfo()
    // await this.getHomepageContent()
    const err = this.$route.query.errorMessage
    if (err) {
      const title = this.$route.query.errorTitle ? this.$route.query.errorTitle : 'Something Went Wrong'
      this.$router.push('/', () => {
        this.$aim.error({}, 'error', title, err)
      })
    }
  },
  methods: {
    async getNetworkInfo () {
      this.networkInfo = await UserService.getNetworkInfo()
    },
    async getUserInfo () {
      this.userInfo = await UserService.getUserInfo()
    },
    async getHomepageContent () {
      const response = await NetworkService.getHomepageContent()
      this.content = response.data.content
    }
  }
}
</script>
