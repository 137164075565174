import axios from 'axios'
import Widgets from '@/services/Widgets'

const LivestreamService = {
  async getSchedule (filters) {
    let timezone = null
    if (typeof Intl !== 'undefined') {
      timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    const data = {
      filters: filters,
      timezone: timezone
    }
    const params = Widgets.JSON_to_URLEncoded(data)

    return axios.post(axios.defaults.baseURL + 'video/livestream/upcoming',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },
  async init (scheduleId) {
    const params = Widgets.JSON_to_URLEncoded({ schedule_id: scheduleId })
    return axios.post(axios.defaults.baseURL + 'video/livestream/init',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async viewed (id, duration, viewed, adminView, Stopped) {
    const data = {
      schedule_id: id,
      duration: duration,
      id: +viewed,
      adminview: +adminView,
      stopped: Stopped
    }

    const params = Widgets.JSON_to_URLEncoded(data)
    return axios.post(axios.defaults.baseURL + 'video/livestream/viewed',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  }
}

export default LivestreamService
