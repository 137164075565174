<template>
  <b-container fluid>
    <div>
      <b-container v-if="id && id > 0">
        <b-row v-show="info.error">
          <b-col cols="12">
            <div class="text-danger text-center mb-4">
              <h2>Unable to load Live Stream</h2>
            </div>
          </b-col>
        </b-row>
        <div v-show="!info.error">
          <video-player
            v-if="videoOptions.sources.length > 0"
            :options="videoOptions"
            :viewedInterval="viewedInterval"
            :updateInterval="updateInterval"
            :id="+id"
            livestream
            :provider="provider"
          ></video-player>
          <b-container>
            <b-row>
              <b-col
                cols="12"
                v-show="info.loading"
              >
                <div class="text-center">
                  <b-spinner></b-spinner><br>{{ info.message }}<br><br>
                </div>
              </b-col>
              <b-col v-if="!info.loading">
                <h2>
                  {{ livestream.data.title }}
                </h2>
                <h3 v-if="livestream.data.schedule && livestream.data.schedule.id > 0">
                  {{ livestream.data.schedule.name }}
                </h3>
              </b-col>
            </b-row>
          </b-container>
        </div>
      </b-container>
    </div>
    <div>
      <StreamSchedule
        showNoResults
        showloading
      ></StreamSchedule>
    </div>
  </b-container>
</template>

<script>
import StreamSchedule from '@/components/schedule/List'
import UserService from '@/services/UserService'
import VideoPlayer from '@/components/widget/VideoPlayer'
import LivestreamService from '@/services/LivestreamService'
import { bus } from '@/main'

export default {
  name: 'Live',
  components: {
    StreamSchedule,
    VideoPlayer
  },
  props: {
    id: {
      type: [Number, String],
      default: 0
    }
  },
  data: () => {
    return {
      viewedInterval: 30,
      updateInterval: 30,
      livestream: {
        id: 0,
        data: {},
        show: false
      },
      info: {
        loading: false,
        error: false,
        message: 'Loading Video Info'
      },
      loadingMessage: 'Loading Video',
      network_id: 0,
      organization_id: 0,
      provider: null,
      videoOptions: {
        disablePictureInPicture: true,
        controls: true,
        autoplay: false,
        liveui: true,
        html5: {
          vhs: {
          }
        },
        sources: []
      }
    }
  },
  created: function () {
    this.init()
  },
  methods: {
    init: function () {
      UserService.getNetworkInfo().then(
        (response) => {
          if (response) {
            this.network_id = response.id
            this.organization_id = response.organization_id
          }
        }
      )
      if (+this.id > 0) {
        this.fetch()
      }
    },
    fetch: function () {
      bus.$emit('videoLoading')
      this.info.loading = true
      LivestreamService.init(+this.id).then(
        (response) => {
          this.livestream.data = response.data
          this.provider = this.livestream.data.provider
          this.info.loading = false
          this.videoOptions.sources = []
          this.videoOptions.sources.push(
            {
              src: atob(this.livestream.data.playlist_link),
              type: 'application/x-mpegURL'
            }
          )
        }
      ).catch(
        (error) => {
          this.$aim.error(error.response)
          this.info.error = true
          this.info.loading = false
          bus.$emit('videoError')
        }
      )
    }
  }
}
</script>
