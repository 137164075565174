<template>
  <b-modal
    v-if="request"
    id="password-verify-modal"
    v-model="openModal"
    no-close-on-backdrop
    no-close-on-esc
    title="Confirm Password"
    ok-title="Confirm"
    ok-variant="success"
    :ok-disabled="loading || !pword || verified"
    size="md"
    @ok="confirmPassword"
  >
    <Loading :loading="loading"></Loading>
    <div v-if="!loading && !verified">
      <div class="alert alert-danger">
        <p>
          This action requires you to verify your password before proceeding.
        </p>
      </div>
      <b-form-group label="Password" class="mx-5">
        <b-form-input
          v-model="pword"
          type="password"
          placeholder="Enter your password"
          ref="pword"
          required
          autocomplete="current-password"
        ></b-form-input>
      </b-form-group>
      <b-alert v-show="error" variant="danger">
        <p>{{ error }}</p>
      </b-alert>
    </div>
    <b-alert v-show="verified" variant="success">
      <p>Password verified successfully!</p>
    </b-alert>
  </b-modal>
</template>
<script>
import AccountService from '@/services/AccountService'
import Loading from '@/components/widget/Loading'

export default {
  name: 'PasswordVerify',
  components: {
    Loading
  },

  props: {
    open: Boolean,
    request: Object
  },

  data () {
    return {
      loading: false,
      openModal: false,
      verified: false,
      pword: null,
      error: null
    }
  },

  methods: {
    confirmPassword (e) {
      if (e) {
        e.preventDefault()
      }
      if (this.loading || !this.pword) {
        return false
      }
      this.loading = true
      AccountService.verifyPassword({ pword: this.pword, payload: this.request }).then(
        (response) => {
          if (response && response.data && response.data.success) {
            this.verified = true
            this.loading = false
            this.error = null
            this.setPayload(response.data)
            setTimeout(() => {
              this.close()
            }, 500)
          } else {
            this.verified = false
            this.loading = false
            this.error = 'Failed to verify password!'
          }
        }
      ).catch((error) => {
        this.$aim.error(error.response)
        this.loading = false
      })
    },

    setPayload (info) {
      this.$emit('payload', info)
    },

    close () {
      this.$nextTick(() => {
        this.$bvModal.hide('password-verify-modal')
      })
    }
  },

  watch: {
    verified () {
      this.$emit('verified', this.verified)
    },

    open () {
      this.openModal = this.open
    }
  }
}
</script>
