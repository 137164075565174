import axios from 'axios'
import Widgets from '@/services/Widgets'

const NetworkService = {

  async getHomepageContent () {
    return axios({
      url: axios.defaults.baseURL + 'network/homepage/',
      method: 'get'
    })
  },

  async getAboutpageContent () {
    return axios({
      url: axios.defaults.baseURL + 'network/aboutpage/',
      method: 'get'
    })
  },

  async submitContactUs (data) {
    const params = Widgets.JSON_to_URLEncoded(data)
    return axios.post(
      axios.defaults.baseURL + 'main/contact/submit/',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async getPolicyLastUpdated (type) {
    return axios({
      url: axios.defaults.baseURL + 'main/policy/lastupdated/' + type,
      method: 'get'
    })
  }
}

export default NetworkService
