<template>
  <b-modal id="search-modal" title="Search Network" hide-footer size="xl" no-close-on-backdrop>
    <b-row
      v-if="loading"
    >
      <b-col
        cols="12"
        class="text-center"
      >
        <b-spinner></b-spinner><br />Loading Videos<br /><br />
      </b-col>
    </b-row>
    <b-form @submit.prevent="performSearch" inline class="mb-3">
      <b-form-input
        size="sm"
        placeholder="Search Network"
        v-model="modalSearchText"
        class="mr-sm-2"
      ></b-form-input>
      <b-button
        size="sm"
        type="submit"
        variant="outline-success"
        :disabled="modalSearchText.length < 3"
      >Search</b-button>
    </b-form>
    <div class="d-flex justify-content-end align-items-center">
      <span class="mr-auto">Total Results: {{ totalRows }}</span>
      <b-pagination
        v-if="totalRows > perPage"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="right"
        class="mt-4"
        @change="onPageChange"
      ></b-pagination>
    </div>
    <video-list
      v-if="searchResults.length"
      @play="routeToSubscribe()"
      :displayType="'grid'"
      :preview="true"
      :videoObject="{ results: searchResults }"
    ></video-list>
    <div v-else>No results found.</div>
  </b-modal>
</template>

<script>
import VideoService from '@/services/VideoService'
import VideoList from '@/components/video-list/List'
export default {
  name: 'SearchModal',
  components: {
    VideoList
  },
  props: {
    initialSearchQuery: String
  },
  data () {
    return {
      modalSearchText: this.initialSearchQuery,
      searchResults: [],
      loading: false,
      pagination: {
        numPages: 1,
        page: 1,
        perPage: 20,
        rows: 1
      },
      currentPage: 1,
      perPage: 20,
      totalRows: 0
    }
  },
  watch: {
    initialSearchQuery (newQuery) {
      this.modalSearchText = newQuery
      this.performSearch(newQuery)
    }
  },
  methods: {
    performSearch () {
      this.loading = true
      const data = {
        filters: {},
        pagination: this.pagination
      }
      if (this.modalSearchText && this.modalSearchText !== '') {
        data.filters.text_search = this.modalSearchText
        data.filters.presearch = true
      }
      VideoService.search(data).then(
        (response) => {
          this.searchResults = response.data.results
          this.totalRows = response.data.totalResults
        }
      ).catch((error) => {
        this.$aim.error(error.response)
      }).finally(() => { this.loading = false })
    },
    setDisplayType (type) {
      this.displayType = type
    },
    onPageChange (page) {
      this.currentPage = page
      this.pagination.page = page
      this.performSearch()
    },
    routeToSubscribe () {
      this.$bvModal.hide('search-modal')
      this.$router.push('/subscribe')
    }
  }
}
</script>
