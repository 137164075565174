<template>
  <b-navbar
    v-if="networkInfo"
    class="sbn-navbar py-0"
    toggleable="lg"
    type="light"
    role="region"
    aria-label="sidebar"
  >
    <b-navbar-brand to="/">
      <a
        to="/"
        class="sbn-navbar-brand-logo"
      ><img
          :alt="networkInfo.name"
          :src="logoImage()"
        /></a>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse
      id="nav-collapse"
      is-nav
    >
      <b-navbar-nav>
        <b-nav-item to="/faq/">FAQ</b-nav-item>
        <b-nav-item
          class="text-uppercase"
          to="/about/"
        > About Us </b-nav-item>
        <contact-modal>Contact Us</contact-modal>
        <b-nav-item
          v-if="!isLoggedIn"
          class="text-uppercase"
          to="/subscribe/"
        >Become a Subscriber</b-nav-item>
        <b-nav-item
          class="text-uppercase"
          to="/live/"
          v-if="isLoggedIn"
        >
          <span class="text-danger">
            <b-icon-camera-video-fill></b-icon-camera-video-fill>
            Schedule
          </span>
        </b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-form v-if="!isLoggedIn" @submit.prevent="openSearchModal">
          <b-form-input
            size="sm"
            placeholder="Search Network"
            name="text_search"
            v-model="searchText"
          ></b-form-input>
          <b-button
            size="sm"
            type="button"
            :disabled="searchText.length < 3"
            @click="openSearchModal"
          >Search</b-button>
        </b-nav-form>

        <!-- Add the SearchModal component -->
        <search-modal v-if="!isLoggedIn" ref="searchModal" :initial-search-query="searchText"></search-modal>
        <div v-if="!isLoggedIn">
          <Login :show="showLoginModal" @close="showLoginModal = false"></Login>
          <b-nav-item @click="showLoginModal = true">
            <b-icon icon="box-arrow-in-right"></b-icon> LOG IN
          </b-nav-item>
        </div>

        <b-nav-form
          action="/video"
          method="GET"
          v-if="isLoggedIn"
        >
          <b-form-input
            size="sm"
            placeholder="Search Network"
            name="text_search"
            v-model="searchText"
          ></b-form-input>
          <b-button
            size="sm"
            type="submit"
            :disabled="searchText.length < 3"
          >Search</b-button>
        </b-nav-form>

        <b-nav-item-dropdown
          v-if="isLoggedIn"
          right
        >
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <b-icon-person></b-icon-person>
            <em>{{ userInfo.username }}</em>
          </template>
          <b-dropdown-item to="/account">Profile</b-dropdown-item>
          <b-dropdown-item to="/account/subscription">Subscription</b-dropdown-item>
          <b-dropdown-item to="/account/billing">Billing</b-dropdown-item>
          <b-dropdown-item
            href="#"
            @click.prevent="logout"
          >Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import UserService from '@/services/UserService'
// import axios from 'axios'
import { BIconPerson } from 'bootstrap-vue'
import Login from '@/components/Login'
import ContactModal from '@/components/modals/ContactModal'
import SearchModal from '@/components/modals/SearchModal'
import { bus } from '@/main'

export default {
  name: 'Navigation',
  components: {
    BIconPerson,
    ContactModal,
    SearchModal,
    Login
  },
  data () {
    return {
      networkInfo: null,
      userInfo: null,
      searchText: '',
      showLoginModal: false
    }
  },
  async mounted () {
    await this.getNetworkInfo()
    await this.getUserInfo()
    // when the user logs in, check the user info again
    // this is used for setting nav status
    bus.$on('loggedIn', () => {
      this.getUserInfo()
    })
  },

  computed: {
    /**
     * @returns {Boolean}
     */
    isLoggedIn: function () {
      return (this.userInfo && this.userInfo.user_id > 0)
    }
  },

  methods: {
    async getNetworkInfo () {
      this.networkInfo = await UserService.getNetworkInfo()
    },
    async getUserInfo () {
      this.userInfo = await UserService.getUserInfo()
    },
    openSearchModal () {
      if (this.searchText.length >= 3) {
        this.$bvModal.show('search-modal')
        this.$refs.searchModal.initialSearchQuery = this.searchText
      }
    },
    async login () {
      // hack to force login as demo user - do not do this
      // axios.defaults.headers.common.Authorization = 'Bearer 405942-2314d3-c74037-c742e0-2f983e' // demo user
      const response = await UserService.refresh()
      UserService.setStorage(response.data)
      location.reload()
    },
    async logout () {
      // remove user from local storage to log user out
      localStorage.clear()
      // localStorage.removeItem('user');
      await this.getUserInfo()
      bus.$emit('loggedOut')

      this.$nextTick(() => {
        this.$router.push('/')
      })
    },

    logoImage () {
      let url = '/img/stock/logo-image-default.png'
      if (this.networkInfo.logoimage_display) {
        url = this.networkInfo.logoimage_display
      } else if (this.networkInfo.profileimage_display) {
        url = this.networkInfo.profileimage_display
      }
      return url
    }
  }
}
</script>

<style scoped>
.sbn-navbar {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.25);
  margin-bottom: 10px;
}
.sbn-navbar-brand-logo {
  margin: 0;
  padding: 5px 1em 0;
}
.sbn-navbar-brand-logo > img {
  max-height: 48px;
}
</style>
