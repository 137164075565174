<template>
  <span>
    <h4>
      <b-icon icon="tag-fill"></b-icon> {{ card.nickname && card.nickname.length > 0 ? card.nickname : card.creditcardtype }}
    </h4>
    <h5>
      <b-icon icon="credit-card-fill"></b-icon> {{ ccTypeMask[card.creditcardtype] }}{{ ('0000' + card.last_four).slice(-4) }}
    </h5>
    <h5 :class="card.expvalid == 1 ? card.daysexpire < 30 ? 'text-warning' : 'text-success' : 'text-danger'">
      <b-icon icon="calendar-fill"></b-icon> {{ card.expmonth }}/{{ card.expyear }}
      <small v-show="card.expvalid == 1 && card.daysexpire < 30">
        <br>
        <b-icon icon="exclamation-circle-fill"></b-icon> This card will expire in {{ card.daysexpire }} days.
      </small>
    </h5>
    <h5
      v-show="card.validated != 1"
      class="text-danger"
    >
      <br>
      <b-icon icon="x-circle-fill"></b-icon> Card has not been successfully validated
    </h5>
  </span>
</template>
<script>
export default {
  name: 'CreditCardInfo',
  props: {
    card: Object
  },
  data: () => {
    return {
      ccTypeMask: {
        'American Express': 'xxxx-xxxxxx-x',
        'Diners Club': 'xxxx-xxxxxx-x',
        Discover: 'xxxx-xxxx-xxxx-',
        JCB: 'xxxx-xxxx-xxxx-',
        MasterCard: 'xxxx-xxxx-xxxx-',
        Visa: 'xxxx-xxxx-xxxx-'
      }
    }
  }
}
</script>
