<template>
  <b-modal
    id="subscription-confirm-modal"
    v-model="openModal"
    no-close-on-backdrop
    no-close-on-esc
    :title="isCurrent ? 'Change My Subscription' : 'Choose Subscription'"
    :ok-title="isCurrent ? 'Change Subscription' : 'Choose Subscription'"
    ok-variant="success"
    :ok-disabled="loading || !valid"
    size="lg"
    @ok="switchSubscription"
    @hidden="closeModal"
  >
    <Loading :loading="loading"></Loading>

    <b-card
      v-if="!loading"
      no-body
    >
      <b-card-header class="m-0">New Subscription Info:</b-card-header>
      <b-card-body class="pt-2">
        <Info :subscriptiontype="subscription.subscriptiontype"></Info>
      </b-card-body>
    </b-card>

    <b-row
      v-if="!loading && subscription.billingamount > 0"
      class="p-3 text-center"
    >
      <b-col
        cols="12"
        class="alert alert-danger mb-1"
      >
        <h3>
          Amount to be charged now:
          {{ amountDueFormatted }}
        </h3>
      </b-col>
      <b-col
        cols="12"
        class="px-0"
      >
        <Verify
          :card="subscription.billing"
          :open="open"
          @isValid="setValidity"
          @update="setBillingCvc"
        ></Verify>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import SubscriptionService from '@/services/SubscriptionService'
import Loading from '@/components/widget/Loading'
import Info from '@/components/subscriptiontypes/Info'
import Verify from '@/components/creditcards/Verify'

export default {
  name: 'ChangeConfirm',
  components: {
    Loading,
    Info,
    Verify
  },

  props: {
    open: Boolean,
    subscription: Object
  },

  data () {
    return {
      openModal: false,
      loading: false,
      valid: false,
      cvc: null
    }
  },

  methods: {
    init () {
      if (this.open && this.subscription && this.subscription.billingamount <= 0) {
        // no payment required, don't confirm, just submit
        this.valid = true
        // this.switchSubscription()
        this.openModal = this.open
      } else {
        this.openModal = this.open
      }
    },

    setValidity (info) {
      this.valid = (this.subscription && this.subscription.subscriptiontype_id && (this.subscription.billingamount <= 0 || this.subscription.billing.id)) ? info : false
    },

    setBillingCvc (info) {
      this.cvc = info
    },

    prepData () {
      const data = {}
      if (this.amountDue > 0) {
        data.billing_id = this.subscription.billing.id
        data.cvc = btoa(this.cvc)
      }
      data.subscriptiontype_id = this.subscription.subscriptiontype_id
      data.priorsubscription_id = this.subscription.priorsubscription_id || null
      data.organization_id = this.subscription.organization_id || null
      // data.affiliate_id = this.subscription.affiliate_id
      data.billingamount = this.amountDue || 0
      data.baseamount = this.subscription.subscriptiontype.amount
      data.appliedCredit = this.subscription.appliedCredit || 0
      data.remainingCredit = this.subscription.remainingCredit || 0
      data.credit = this.subscription.credit || 0
      data.discount = this.subscription.discount || null
      return data
    },

    switchSubscription (e) {
      if (e) {
        e.preventDefault()
      }
      if (this.loading || !this.valid) {
        return false
      }
      const data = this.prepData()
      this.loading = true

      SubscriptionService.change(data).then(
        (response) => {
          if (response && response.data && response.data.success) {
            this.closeModal()
            this.$aim.notify(response)
            this.$emit('refresh')
          } else {
            this.$aim.error(response)
          }
        }
      ).catch(
        (error) => {
          this.$aim.error(error.response)
          this.closeModal()
          this.$emit('refresh')
        }
      ).finally(() => {
        this.loading = false
      })
    },

    closeModal () {
      this.$emit('close')
    }
  },

  watch: {
    open () {
      this.init()
    }
  },

  computed: {
    isCurrent () {
      return (this.subscription && this.subscription.current === 1)
    },

    amountDue () {
      let amt = this.subscription.billingamount
      if (this.subscription && this.subscription.discount && this.subscription.discount.id) {
        amt = this.subscription.discount.balancedue.amount
      }
      return amt
    },

    amountDueFormatted () {
      let amt = this.subscription.localbillingamount.localcurrencyformatted
      if (this.subscription && this.subscription.discount && this.subscription.discount.id) {
        amt = this.subscription.discount.balancedue.localcurrencyformatted
      } else {
        console.log('no discount')
      }
      return amt
    }
  }
}
</script>
