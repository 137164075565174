<template>
  <!-- <div>
    NO JUMBOTRON?
  </div> -->
  <b-container v-if="networkInfo" role="region" aria-label="about us">
    <b-row>
      <b-col>
        <h1
          id="main-title"
          class="text-uppercase background-lines double-lines text-lg grey"
        >
          <span>About Us</span>
        </h1>
      </b-col>
    </b-row>
    <!-- custom content for this network -->
    <b-row v-if="content">
      <b-col>
        <img
          class="img img-responsive ml-5 mb-5 w-50 float-right"
          :alt="networkInfo.name"
          :src="image"
        />
        <span v-html="content"></span>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import UserService from '@/services/UserService'
import NetworkService from '@/services/NetworkService'

export default {
  name: 'About',
  components: {
    // SubscriptionTypes
  },
  data () {
    return {
      networkInfo: null,
      content: null,
      image: '/img/stock/about-us-default.png'
    }
  },
  async mounted () {
    await this.getNetworkInfo()
    await this.getAboutpageContent()
  },
  methods: {
    async getNetworkInfo () {
      this.networkInfo = await UserService.getNetworkInfo()
    },
    async getAboutpageContent () {
      const response = await NetworkService.getAboutpageContent()
      this.content = response.data.content
      if (response.data.image) {
        this.image = response.data.image
      }
    }
  }
}
</script>
