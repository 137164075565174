<template>
  <section>
    <b-card v-if="isSelectMode && hasSelection">
      <b-card-header class="bg-success text-white">
        <h3>{{ selected.name }}</h3>
      </b-card-header>
      <b-card-text class="p-3">
        <Info :subscriptiontype="selected">
          <b-button type="button" variant="danger" @click="selectSubType({})"
            >Change Type</b-button
          >
        </Info>
      </b-card-text>
    </b-card>

    <b-row v-else class="align-self-stretch">
      <b-col v-for="t in types" :key="t.id" class="text-center">
        <b-card @click="selectSubType(t)" class="h-100 px-0">
          <b-card-header class="bg-success text-white">
            <h3>{{ t.name }}</h3>
          </b-card-header>
          <b-card-text class="p-3">
            <h2>{{ t.localcurrencyformatted }}</h2>
            <p>{{ t.duration | billingType }}</p>
            <small v-show="t.localcurrency !== 'USD'"
              >* charges will be made in USD @ ${{ t.amount }}, international
              fees may apply</small
            >
            <p>{{ t.description }}</p>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import Info from '@/components/subscriptiontypes/Info'

export default {
  name: 'SelectType',
  components: {
    Info
  },
  props: {
    types: Array,
    selected: {
      type: Object,
      default () { return null }
    }
  },

  data () {
    return {
    }
  },

  methods: {
    selectSubType (type) {
      if (!this.isSelectMode) {
        return false
      }
      this.$emit('select', type)
    }
  },

  computed: {
    isSelectMode () {
      return this.selected !== null
    },
    hasSelection () {
      return (this.selected !== null && this.selected && this.selected.id > 0)
    }
  },

  filters: {
    /** @returns {String} */
    billingType: function (val) {
      return val > 1 ? 'Billed Annually Until Cancellation' : (val) ? 'Billed Monthly Until Cancellation' : 'One Time Payment'
    }
  }
}
</script>
