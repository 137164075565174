<template>
  <b-img
    alt="Card Logo"
    :src="cardLogoImage"
    class="img-responsive"
  ></b-img>
</template>
<script>
export default {
  name: 'CreditCardLogo',
  props: {
    cardtype: {
      type: [Number, String],
      default: 0
    },
    size: {
      type: String,
      default: 'lg'
    }
  },

  computed: {
    cardLogoImage () {
      return '/img/cc/' + this.size + '/' + this.cardtype + '.png'
    }
  }
}
</script>
