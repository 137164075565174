<template>
  <b-card v-if="card" no-body class="mt-0 pt-0">
    <b-card-body>
      <b-row class="align-items-center">
        <b-col class="col-2 text-center">
          <CreditCardLogo :cardtype="card.creditcardtype"></CreditCardLogo>
        </b-col>

        <b-col class="col-5 offset-1 text-left">
          <CreditCardInfo :card="card"></CreditCardInfo>
        </b-col>

        <b-col class="col-4 text-left">
          <CreditCardAddress :card="card"></CreditCardAddress>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col cols="8" class="offset-3">
          <b-form-group
            label="Please Enter The CVC Code:"
            label-cols="6"
            label-align="right"
            label-class="font-weight-bold"
          >
            <b-input-group>
              <b-form-input
                v-model="cvc"
                ref="cvc"
                v-cardformat:formatCardCVC
                @blur="validateCardCVC"
                @input="error = null"
                :state="error !== null || $v.cvc.$error ? false : null"
                placeholder="CVC"
                style="min-width: 4em"
                :autofocus="true"
                autocomplete="cc-csc"
              ></b-form-input>

              <b-input-group-append v-if="allowReset">
                <b-button
                  type="button"
                  variant="danger"
                  @click.prevent="$emit('reset')"
                  >Change Payment Method</b-button
                >
              </b-input-group-append>

              <b-form-invalid-feedback v-if="error || $v.cvc.$error">
                <span v-if="error">{{ error }} - </span>
                <span v-if="$v.cvc.$error">CVC is required</span>
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'

import CreditCardInfo from '@/components/creditcards/Info'
import CreditCardLogo from '@/components/creditcards/Logo'
import CreditCardAddress from '@/components/creditcards/Address'

export default {
  name: 'Verify',
  components: {
    CreditCardInfo,
    CreditCardLogo,
    CreditCardAddress
  },
  props: {
    card: Object,
    allowReset: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      cvc: null,
      error: null
    }
  },

  methods: {
    validateCardCVC () {
      this.$v.cvc.$touch()
      this.error = null
      if (!this.$cardFormat.validateCardCVC(this.cvc)) {
        this.error = 'Invalid CVC'
      }
    }
  },

  watch: {
    '$v.$invalid' () {
      this.$emit('isValid', !this.$v.$invalid)
    },

    cvc () {
      this.$emit('update', this.cvc)
    }
  },

  mixins: [validationMixin],

  validations: {
    cvc: {
      required,
      minLength: minLength(3)
    }
  }

}
</script>
