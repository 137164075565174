import axios from 'axios'
import { bus } from '@/main'
import Widgets from '@/services/Widgets'
import router from '@/router'

const UserService = {

  // Returns user info object
  async getUserInfo () {
    const info = await this.verify('user')
    return info
  },

  async getNetworkInfo () {
    const info = await this.verify('network')
    return info
  },

  async verify (key) {
    this.setHeaders()
    const info = this.getStorage(key)
    if (info && !this.storageIsExpired()) {
      return info
    } else {
      const response = await this.refresh()
      if (response !== null) {
        this.setStorage(response.data)
        return response.data[key]
      } else {
        // if network is invalid and that is what is being asked for
        // return some defaults for the sake of layout
        if (key === 'network') {
          return {
            name: 'SBN Broadcasting',
            channels: []
          }
        }
      }
    }
  },

  async refresh () {
    return axios({
      url: axios.defaults.baseURL + 'network/',
      method: 'post'
    }).then(success => {
      if (success && success.data && success.data.network && +success.data.network.active !== 1) {
        try {
          localStorage.clear()
          bus.$emit('loggedOut')
          router.push('/inactive')
        } catch (ex) {
        }
      }
      return Promise.resolve(success)
    }, error => {
      // if the network info is invalid, send to invalid page
      if (error.response) {
        if (error.response.status === 401 && error.response.data.error.includes('Network credentials')) {
          router.push('/invalid-network')
        } else if (error.response.status === 401 && error.response.data.error.includes('logged out')) {
          localStorage.clear()
          bus.$emit('loggedOut')
          router.push('/')
        } else {
          // internal db error likely
          router.push('/invalid-network')
        }
      } else {
        console.dir(error)
      }
      // because of how this is being used, the await is always
      // expecting a resolve return, so let's give them one
      return Promise.resolve(null)
    })
  },

  // Retrieve the network info object from local storage
  getStorage (key) {
    const temp = localStorage.getItem(key)
    if (temp && temp !== null) {
      try {
        return JSON.parse(localStorage.getItem(key))
      } catch (error) {
        // invalid localstorage item, delete it and return null
        localStorage.removeItem(key)
        return null
      }
    } else {
      return null
    }
  },

  // Set the network info object in local storage
  setStorage (info, date = new Date()) {
    localStorage.setItem('user', JSON.stringify(info.user))
    localStorage.setItem('network', JSON.stringify(info.network))
    localStorage.setItem('updated', JSON.stringify(date))
    bus.$emit('userInfo', date)
  },

  // Determine if network info is expired and needs to refresh
  storageIsExpired () {
    const lastEntry = JSON.parse(localStorage.getItem('updated'))
    if (!lastEntry) {
      return true
    }
    const last = new Date(lastEntry)
    const curt = new Date()
    if (curt - last >= 5 * 60 * 1000) {
      return true
    }
    return false
  },

  setHeaders () {
    const userInfo = this.getStorage('user')
    if (userInfo && userInfo.apikey) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + userInfo.apikey
      // 405942-2314d3-c74037-c742e0-2f983e - demo user
    } else {
      // remove any possible authorization header if user is invalid
      delete axios.defaults.headers.common.Authorization
    }
  },

  async login (user) {
    const data = {
      username: user.username,
      pword: user.password,
      locale: window.navigator.language
    }

    if (user.wlmasquerade) {
      data.wlmasquerade = user.wlmasquerade
    }

    const params = Widgets.JSON_to_URLEncoded(data)
    return axios.post(
      axios.defaults.baseURL + 'account/login',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async resetPassword (username) {
    const params = Widgets.JSON_to_URLEncoded({ username: username })
    return axios({
      url: axios.defaults.baseURL + 'account/forgot/password?' + params,
      method: 'post'
    })
  },

  async retrieveUsername (email) {
    const params = Widgets.JSON_to_URLEncoded({ email: email })
    return axios({
      url: axios.defaults.baseURL + 'account/forgot/username?' + params,
      method: 'post'
    })
  },

  async verifyCaptcha (token) {
    const params = Widgets.JSON_to_URLEncoded({ secret: '6Le-L6wZAAAAAKUXFOsoFELsxtt3_G6wVQ1BAw0A', response: token })
    const response = axios({
      url: axios.defaults.baseURL + 'account/verifycaptcha?' + params,
      method: 'post'
    })
    return response
  }
}

export default UserService
