<template>
  <div class="container-fluid mb-5" role="region" aria-label="user subscription">
    <Loading :loading="loading"></Loading>

    <PayBalance
      v-if="!loading && balanceDue > 0"
      :subscription="subscription"
      :states="states"
      :countries="countries"
      @refresh="init"
    >
      <h2>
        <b-icon icon="exclamation-circle-fill"></b-icon>
        Your Account Has an Unpaid Balance of {{ accountStatus.localbalance.localcurrencyformatted }}
      </h2>
      <p>You will be prohibited from access to videos and live streams until this balance is paid.</p>
    </PayBalance>

    <b-card
      v-if="!loading && accountStatus && isCurrent"
      class="mb-3"
      no-body
    >
      <b-card-header>
        <h4 class="m-0 card-title text-center">Current Subscription</h4>
      </b-card-header>
      <b-card-text class="m-3">
        <Info
          :subscription="subscription"
          :class="(subscription.nextsubscriptiontype_id > 0) ? 'alert alert-danger' : ''"
          @refresh="init"
        >
          <template>
            <Cancel
              :subscription="subscription"
              @refresh="init"
            ></Cancel>
          </template>
        </Info>
      </b-card-text>
    </b-card>

    <b-card
      v-if="!loading && accountStatus && accountStatus.balance <= 0 && filteredTypes.length > 0 && (!subscription || !subscription.nextsubscriptiontype_id) && !isAdmin"
      no-body
    >
      <b-card-header>
        <h4 class="m-0 card-title text-center">{{ isCurrent ? 'Change' : 'Choose' }} your Subscription</h4>
      </b-card-header>
      <b-card-text class="m-3">
        <b-card
          v-for="t in filteredTypes"
          :key="t.id"
          class="mb-3"
        >
          <b-card-text>
            <SubscriptionTypeInfo
              :subscriptiontype="t"
              @refresh="init"
            >
              <Change
                :subscription="subscription"
                :subscriptiontype="t"
                :status="accountStatus"
                :types="types"
                :states="states"
                :countries="countries"
                @refresh="init"
              ></Change>
            </SubscriptionTypeInfo>
          </b-card-text>
        </b-card>
      </b-card-text>
    </b-card>

    <b-card
      v-if="!loading && !(accountStatus && accountStatus.balance <= 0 && filteredTypes.length > 0 && (!subscription || !subscription.nextsubscriptiontype_id) && !isAdmin)"
      no-body
    >
      <b-card-header>
        <h4 class="m-0 card-title text-center">No data to display</h4>
      </b-card-header>
      <b-card-text class="m-3 text-center">
        <h3>As an administrator you do not need to have a subscription</h3>
      </b-card-text>
    </b-card>

  </div>
</template>
<script>
import SubscriptionService from '@/services/SubscriptionService'

import Loading from '@/components/widget/Loading'
import PayBalance from '@/components/billing/PayBalance'
import Info from '@/components/subscriptions/Info'
import SubscriptionTypeInfo from '@/components/subscriptiontypes/Info'
import Cancel from '@/components/subscriptions/Cancel'
import Change from '@/components/subscriptions/Change'

export default {
  name: 'Subscription',
  components: {
    Loading,
    PayBalance,
    Info,
    Cancel,
    Change,
    SubscriptionTypeInfo
  },
  data: () => {
    return {
      loading: false,
      openedRenewModal: false,
      accountStatus: null,
      subscription: null,
      types: [],
      states: [],
      countries: [],
      isAdmin: false
    }
  },

  created: function () {
    this.init()
  },

  methods: {
    init () {
      this.loading = true
      SubscriptionService.init().then(
        (response) => {
          this.subscription = response.data.subscription
          this.states = response.data.states
          this.countries = response.data.countries

          if (typeof response.data.types !== 'undefined' && response.data.types !== null && response.data.types !== false && response.data.types.length > 0) {
            this.types = response.data.types
          }
          if (typeof response.data.accountStatus !== 'undefined' && response.data.accountStatus !== null && response.data.accountStatus !== false) {
            this.accountStatus = response.data.accountStatus
          }

          if (typeof response.data.isAdmin !== 'undefined' && response.data.isAdmin !== null && response.data.isAdmin !== false) {
            this.isAdmin = response.data.isAdmin
          }
          /* trigger upgrade/switch modal if no valid subscription is found *
          if (!this.subscription || !this.subscription.id) {
            if (!this.openedRenewModal) {
              // $scope.fn.upgrade(null, true);
            }
            this.openedRenewModal = true;
          } */
        }
      ).catch((error) => {
        this.$aim.error(error.response)
      }).finally(() => {
        this.loading = false
      })
    }
  },

  computed: {
    balanceDue () {
      return (this.accountStatus) ? this.accountStatus.balance : 0
    },

    isCurrent () {
      return (this.subscription && this.subscription.current === 1)
    },

    filteredTypes: function () {
      return this.types.filter(e => { return (this.isCurrent) ? (e.id !== this.subscription.subscriptiontype_id) : true })
    }
  },

  filters: {
    billingType: function (value) {
      return value > 1 ? 'Annually' : value ? 'Monthly' : 'Once'
    }
  }
}
</script>
<style scoped lang="scss">
.well {
  padding: 1em;
  border-radius: 4px;
  background-image: -webkit-linear-gradient(top, #e8e8e8 0%, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #e8e8e8 0%, #f5f5f5 100%);
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#e8e8e8),
    to(#f5f5f5)
  );
  background-image: linear-gradient(to bottom, #e8e8e8 0%, #f5f5f5 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe8e8e8', endColorstr='#fff5f5f5', GradientType=0);
  background-repeat: repeat-x;
  border-color: #dcdcdc;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05),
    0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05),
    0 1px 0 rgba(255, 255, 255, 0.1);
}
</style>
