<template>
  <div v-if="subscriptiontype">
    <b-row>
      <b-col>
        <h2 class="m-0">
          <b-icon icon="tag-fill"></b-icon>
          {{ subscriptiontype.name }}
        </h2>
      </b-col>
      <b-col
        cols="3"
        xs="12"
        class="text-right"
      >
        <slot @refresh="$emit('refresh')"></slot>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <p class="m-3">
          <b-icon icon="info-circle-fill"></b-icon>
          {{ subscriptiontype.description }}
        </p>
      </b-col>
    </b-row>

    <hr class="my-0 mx-3">
    <b-row>
      <b-col>
        <h4 class="m-3 mb-0">
          <PiggyBankIcon></PiggyBankIcon>
          {{ subscriptiontype.localcurrencyformatted }} Billed {{ subscriptiontype.duration | billingType }}
          <a
            uib-tooltip="Click here to learn more..."
            @click="openModal = true"
            style="cursor: pointer"
          >
            <b-icon
              icon="info-circle-fill"
              variant="info"
            ></b-icon>
          </a>
        </h4>
        <div v-if="subscriptiontype.localcurrency !== 'USD'">
          <small>* charges will be made in USD @ ${{ subscriptiontype.amount }}, international fees may apply</small>
        </div>
      </b-col>
    </b-row>

    <b-modal
      v-model="openModal"
      :title="subscriptiontype.name"
      size="lg"
      ok-only
      ok-variant="info"
    >
      <div class="alert alert-info">
        <h3>Info:</h3>
        <p>{{ subscriptiontype.longdescription }}</p>
      </div>
    </b-modal>
  </div>
</template>
<script>
import PiggyBankIcon from '@/components/widget/PiggyBankIcon'

export default {
  name: 'Info',
  components: {
    PiggyBankIcon
  },
  props: {
    subscriptiontype: Object
  },
  data: () => {
    return {
      openModal: false
    }
  },
  filters: {
    billingType: function (value) {
      return value > 1 ? 'Annually' : value ? 'Monthly' : 'Once'
    }
  }
}
</script>
