<template>
  <section>
    <b-row>
      <b-col>
        <div class="alert alert-danger">
          <slot></slot>
          <b-button
            variant="danger"
            type="button"
            size="lg"
            @click.prevent="openModal = true"
          >
            <b-icon icon="cash"></b-icon> Pay Unpaid Balance
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-modal
      v-if="status"
      id="pay-balance-modal"
      v-model="openModal"
      no-close-on-backdrop
      no-close-on-esc
      title="Pay Balance"
      ok-title="Pay Balance"
      ok-variant="success"
      :ok-disabled="loading || !isValid"
      size="xl"
      @ok="payBalance"
    >
      <Loading :loading="loading"></Loading>

      <div v-if="!loading">
        <b-row class="my-3">
          <b-col>
            <div class="alert alert-danger">
              <slot></slot>
            </div>
          </b-col>
        </b-row>

        <DiscountCode
          :discount="discount"
          :subscriptiontype_id="subscription.subscriptiontype_id"
          class="my-3"
        ></DiscountCode>

        <b-card v-if="amountDue > 0">
          <BillingList
            :states="states"
            :countries="countries"
            :selected="billing"
            @select="setBilling"
          ></BillingList>
        </b-card>
      </div>
    </b-modal>

    <PayBalanceConfirm
      v-if="billing && billing.id > 0"
      :open="openConfirm"
      :payment="payment"
      @refresh="$emit('refresh')"
    ></PayBalanceConfirm>
  </section>
</template>
<script>
import BillingService from '@/services/BillingService'
import Loading from '@/components/widget/Loading'
import DiscountCode from '@/components/billing/DiscountCode'
import BillingList from '@/components/billing/List'
import PayBalanceConfirm from '@/components/billing/PayBalanceConfirm'

export default {
  name: 'PayBalance',

  components: {
    Loading,
    DiscountCode,
    BillingList,
    PayBalanceConfirm
  },

  props: {
    subscription: Object,
    states: Array,
    countries: Array
  },

  data () {
    return {
      openModal: false,
      openConfirm: false,
      loading: false,
      status: null,
      transactions: null,
      discount: null,
      billing: {},
      subscription_id: null,
      subscriptiontype_id: null,
      payment: null
    }
  },

  methods: {
    init () {
      this.loading = true
      BillingService.paymentInit().then(
        (response) => {
          this.billing = {}
          this.discount = null
          this.cvc = null
          this.cvcValid = false
          this.status = response.data.status
          this.transactions = response.data.transactions
          if (!this.subscription && this.transactions.length > 0) {
            this.subscription_id = this.transactions[0].subscription_id
            this.subscriptiontype_id = this.transactions[0].subscription.subscriptiontype_id
          } else {
            this.subscription_id = this.subscription.id
            this.subscriptiontype_id = this.subscription.subscriptiontype_id
          }
        }
      ).catch((error) => {
        this.$aim.error(error.response)
        this.close()
      }).finally(() => {
        this.loading = false
      })
    },

    payBalance () {
      if (!this.loading && this.isValid) {
        this.prepData()
        this.openConfirm = true
      }
    },

    prepData () {
      this.payment = {
        status: this.status,
        subscription: this.subscription,
        transactions: this.transactions,
        billing: this.billing,
        discount: this.discount,
        amount: this.amountDue,
        localamount: this.amountDueFormatted
      }
    },

    close () {
      this.$nextTick(() => {
        this.$bvModal.hide('pay-balance-modal')
      })
    },

    setBilling (info) {
      this.billing = info
    },

    clearBilling () {
      this.billing = {}
    }
  },

  computed: {
    amountDue () {
      return (this.status) ? this.status.balance : 0
    },
    amountDueFormatted () {
      return (this.status) ? this.status.localbalance.localcurrencyformatted : 0
    },
    isValid () {
      return (this.billing && this.billing.id)
    }
  },

  watch: {
    openModal (n, o) {
      if (n && !o) {
        this.init()
      }
    }
  }
}
</script>
