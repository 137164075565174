<template>
  <b-container v-if="networkInfo" role="region" aria-label="terms of use">
    <b-row>
      <b-col>
        <h1
          id="main-title"
          class="text-uppercase text-center text-lg"
        ><span>TERMS OF USE</span></h1>
      </b-col>
    </b-row>
    <!-- custom content for this network -->
    <b-row>
      <b-col>
        <div class="row">
          <div class="col-xs-12">
            <h2 class="text-center"></h2>
            <p v-if="lastupdate">
              Last Revised: {{ lastupdate }}
            </p>
            <p>
              This website is operated by SBN Broadcasting (a DBA of Tres&oacute;na Solutions, LLC) for and on behalf of
              {{ networkInfo.name }}. Below we have listed important legal terms that apply to anyone who visits this website or
              uses its services. These terms are necessary in order to protect both you and us, and to make our services
              possible and more enjoyable for everyone. SBN Broadcasting offers a wide range of services and
              features and part of the terms below may not be relevant to the specific services you use.
            </p>
            <p>
              We understand that legal terms can be exhausting to read, and we've tried to make the experience more
              pleasant. If you have any suggestions on how we can improve them, you are more than welcome to contact us
              at <a href="mailto:support@sbnbroadcasting.com">support@sbnbroadcasting.com</a>.
            </p>
            <div style="margin-bottom:30px;">
              <h4>OVERVIEW</h4>
              <p>
                The following Terms of Use, along with any additional terms and conditions that are referenced herein or
                that are presented in connection with a specific application, service or feature (collectively, "Terms"),
                and the Privacy Policy set forth the terms and conditions that apply to use of this SBN Broadcasting site
                and service ("Service"). Certain products and services available to you may require you to register and
                provide additional information to us, and in certain cases, to our third-party providers, vendors and
                processors as a condition of application for and use of those products. For certain third-party products,
                you also may be asked to accept the terms and policies of such provider. You should read all applicable
                terms and policies carefully to ensure you understand how a third party may use your information. Please
                read these Terms carefully.
              </p>
              <p>
                These Terms are subject to change on occasion, so you should review these Terms from time to time, as you
                will
                be deemed to accept such changes through your continued use of the Service. We hope you enjoy your
                experience
                with the SBN Broadcasting.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>ACCEPTANCE OF TERMS OF USE</h4>
              <p>
                These terms of use are entered into by and between You and SBN Broadcasting (<b>"Company," "we,"</b> or
                <b>"us"</b>). The following terms and conditions, together with any documents they expressly incorporate by
                reference (collectively, "Terms of Use"), govern your access to and use of {{ networkInfo.url }}, including
                any content, functionality, and services offered on or through {{ networkInfo.url }} (the <b>"Website"</b>),
                whether as a guest or a registered user.
              </p>
              <p>
                Please read the Terms of Use carefully before you start to use the Website. <b>By using the Website or by
                  clicking to accept or agree to the Terms of Use when this option is made available to you, you accept and
                  agree to be bound and abide by these Terms of Use and our Privacy Policy, found at <a href="/privacy/">Privacy Policy</a>,
                  incorporated herein by reference.</b> If you do not want to agree to these Terms of Use or the Privacy
                Policy,
                you must not access or use the Website.
              </p>
              <p>
                This Website is offered and available to users who are 18 years of age or older. By using this Website, you
                represent and warrant that you are of legal age to form a binding contract with the Company and meet all of
                the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or
                use the Website.
              </p>
              <p>
                The owner of the website is based in the State of Arizona in the United States. We provide this Website for
                use only by persons located in the United States. We make no claims that the Website or any of its content
                is accessible or appropriate outside of the United States. Access to the Website may not be legal by certain
                persons or in certain countries. If you access the Website from outside the United States, you do so on your
                own initiative and are responsible for compliance with local laws.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>LINKS FROM THE WEBSITE</h4>
              <p>
                If the Website contains links to other sites and resources provided by third parties, these links are
                provided for your convenience only. This includes links contained in advertisements, including banner
                advertisements and sponsored links. We have no control over the contents of those sites or resources and
                accept no responsibility for them or for any loss or damage that may arise from your use of them. If you
                decide to access any of the third-party websites linked to this Website, you do so entirely at your own risk
                and subject to the terms and conditions of use for such websites.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>CHANGES TO TERMS OF USE</h4>
              <p>
                We may revise and update these Terms of Use from time to time in our sole discretion. All changes are
                effective immediately when we post them and apply to all access to and use of the Website thereafter.
                Your continued use of the Website following the posting of revised Terms of Use means that you accept and
                agree to the changes. You are expected to check this page from time to time/frequently/each time you access
                this Website, so you are aware of any changes, as they are binding on you.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>PRIVACY POLICY</h4>
              <p>
                Any personally identifying information submitted through the Service is subject to our Privacy Policy.
                Please review our <a href="/privacy/">Privacy Policy</a> to understand our practices and procedures for
                personal information we collect through the Service. The date of any changes to our Privacy Policy will be
                noted at the top of the policy. As applicable, please also note the separate privacy policies for our
                vendors providing third party products and services through the Service. By using the Service, you are
                consenting to receive electronic communications from us related to the Service, your account and our
                operation and administration of the Service. You agree that any notice, agreements, disclosure or other
                communications that we send to you electronically will satisfy any legal communication requirements,
                including that such communications be in writing.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>SUBSCRIPTION AND BILLING</h4>
              <p>
                SBN Broadcasting provides video events and other digital content to visitors to our website and other user
                interfaces. SBN Broadcasting reserves the right in its discretion and at any time (i) to deny any account
                creation request, (ii) to terminate your account and subscription to the Service, (iii) to remove any
                content from the Service, and/or (iv) to modify, interrupt, limit, suspend or discontinue, temporarily or
                permanently, the Service or any subscription or access to the Service. We are not liable to you or to any
                third party for any such modifications, suspensions or discontinuances of the Service or subscriptions or
                access.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>SBN BROADCASTING SUBSCRIPTION</h4>
              <p>
                Access to the content on the Service requires a paid SBN Broadcasting Subscription, in which case you will
                be required to establish a valid email address and verified password in order to access this content. You
                will also be required to provide certain information to establish a valid electronic payment method with SBN
                Broadcasting ("Payment Method"), pay the applicable subscription fee and accept these Terms in order to
                access this content. As part of the registration process, you may be asked to verify that you are 18 years
                of age or older. Payment processing associated with a subscription may be handled by a third-party payment
                processor. The current SBN Broadcasting subscription plans and rates are posted on the Service. Please note
                that prices and charges are subject to change as determined by SBN Broadcasting in its discretion. SBN
                Broadcasting reserves the right to determine which content is accessible via an SBN Broadcasting
                subscription in our sole and absolute discretion.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>ACCOUNT INFORMATION AND PASSWORDS</h4>
              <p>
                As an SBN Broadcasting member, you will have access and control over your SBN Broadcasting account. Your
                control is exercised through use of your username and password; therefore, you should not reveal this
                information to anyone. You are solely responsible for keeping username, password, Payment Method details and
                other codes and information pertaining to the Service and your account confidential and secure from
                unauthorized persons. Accounts are created for personal use only and may not be shared under any
                circumstances.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>BILLING</h4>
              <p>
                You can find the specific details regarding your subscription to the Service at any time by clicking on
                the <b>Log In</b> link on the Website and use your username and password to access your account. Once you
                are logged in to your account on the Website, you can click the <b>My Subscription</b> link to view and
                manage your subscription details, including the subscription type, upgrading your subscription, cost, and
                next bill date. You can also cancel your subscription from this page. If you want to use a different Payment
                Method or if there is a change in Payment Method, such as your credit card validity or expiration date, you
                may edit your Payment Method information by clicking the <b>My Credit Cards</b> link and using your username
                and password to access your account information on the Service.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>SPECIALTY CONTENT SUBSCRIPTIONS</h4>
              <p>
                In addition to our standard subscription offerings for the Service, we may offer special subscriptions that
                provide access to specially produced, premium content or features that are provided on the Service from time
                to time. Should you wish to access any such special content, you will be required to register, enter a valid
                Payment Method and pay the applicable subscription fee as a condition of access to this content. Additional
                sign-up and payment options may be available to SBN Broadcasting subscribers through the <b>My
                  Subscription</b> link, as determined by the SBN Broadcasting in its discretion. The current SBN
                Broadcasting subscription plans and rates are posted on the Service. SBN Broadcasting reserves the right to
                determine which content or features are accessible only as part of a special subscription or pass in our
                sole and absolute discretion.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>AUTHORIZATION TO CHARGE YOUR PAYMENT METHOD</h4>
              <p>
                By starting your SBN Broadcasting subscription, you expressly agree that the SBN Broadcasting is authorized,
                and may authorize its third-party payment processor, to charge you a fee at the then current published rate
                for the Service using your selected Payment Method at the time of registration and upon any automatic
                renewal of your subscription to the Service in accordance with these Terms. <b>You also authorize SBN
                  Broadcasting to change, at its option, your SBN Broadcasting subscription from a month-to-month
                  subscription to an annual subscription once the total amount charged for the month-to-month subscription
                  exceeds the cost of an annual subscription.</b> Please note that prices and charges are subject to change
                as determined by SBN Broadcasting in its discretion. As used in these Terms of Use, "billing" shall indicate
                either a charge or debit, as applicable, against your Payment Method. You may change your Payment Method as
                set forth in these Terms.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>REFUNDS</h4>
              <p>
                Requests for refunds must be submitted to the SBN Broadcasting through the Customer Service link on the
                Service by email to <a href="mailto:support@sbnbroadcasting.com">support@sbnbroadcasting.com</a>. SBN
                Broadcasting will honor any refund request for unused monthly or annual subscriptions received within thirty
                (30) days of the purchase date or the last renewal billing date. We may change the fees and charges in
                effect or add new fees and charges from time to time, but we will give you advance notice of these changes
                by email.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>AUTO-RENEWAL OF SUBSCRIPTIONS</h4>
              <p>
                SBN Broadcasting monthly or annual subscriptions will renew automatically at the end of term unless and
                until you cancel your subscription via the <b>My Subscription</b> link in your account or submit a request
                to Customer Service to terminate it. You must cancel your subscription in order to avoid billing of the next
                cycle's subscription fees to your Payment Method on file. Subscription fees are fully earned upon payment
                and may not be cancelled or refunded except as expressly permitted in these Terms. Please note that prices
                and charges are subject to change as determined by the SBN Broadcasting in its discretion.
              </p>
              <p>
                To cancel or change the renewal option of your subscription from automatic renewal to manual renewal, visit
                <b>My Subscription</b> in your account.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>CANCELLATION</h4>
              <p>
                You may cancel your SBN Broadcasting subscription at any time. To cancel, click on the <b>Log In</b> link on
                the Website and use your username and password to access your account. Once you are logged in to your
                account on the Website, click the <b>My Subscription</b> link and click "Cancel My Subscription." You can
                cancel any active subscriptions. Please note, however, that subscription fees are fully earned upon payment
                and may not be cancelled or refunded except as expressly permitted in these Terms.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>RIGHT TO TERMINATE</h4>
              <p>
                We reserve the right to terminate or restrict your use of the Service, without notice, for any or no reason
                whatsoever.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>HOW OUR STREAMING SERVICE WORKS</h4>
              <p>
                SBN Broadcasting is an online service providing its subscribers with access to nondramatic, musical
                performance content streaming over the Internet to computers, mobile devices, and other Internet-connected
                devices. These devices are manufactured and sold by entities other than SBN Broadcasting. We reserve the
                right in our sole and absolute discretion to make changes from time to time and without notice in how we
                operate and support the Service. Any description of how the Service works should not be considered a
                representation or obligation with respect to how the Service will always work. We reserve the right to
                modify, update and change the Service at any time and from time to time in our discretion.
              </p>
              <div style="margin-left:3%;">
                <p>
                  <b>Availability:</b> The availability of live and on demand content will change from time to time, and
                  from device to device. The quality of the display of our content may vary from computer to computer, and
                  device to device, and may be affected by a variety of factors, such as your location, the bandwidth
                  available through and/or speed of your Internet connection. You are responsible for all Internet access
                  connectivity and charges. Please check with your Internet provider for information on possible Internet
                  data usage charges. SBN Broadcasting makes no representations or warranties about the quality of your
                  instant watching experience on your display.
                </p>
                <p>
                  <b>Personal Computer Requirements and Device Limitations:</b> You may only use your account to watch the
                  Service on one unique device at a time. We may terminate your account if we determine that you are using
                  your account to access the Service from multiple devices concurrently or if we believe that you have
                  shared your account credentials with other persons in violation of these Terms.
                </p>
              </div>
              <p>
                We do not warrant that any of the software used and or licensed in connection with the Service will be
                compatible with other third-party software nor do we warrant that operation of the Service and the
                associated software will not damage or disrupt other software or hardware. Any issues related to the
                Service, including any system requirements, are covered and limited by these Terms. Please refer to the
                Disclaimers of Warranties and Limitations of Liability set forth in these Terms. In response to requests
                from content providers, or due to technical limitations, or for any reason in our sole and absolute
                discretion, and without prior notice to you, some or all of our content may not be watched or may cease to
                be available for watching through the Service.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>DISCLAIMERS OF WARRANTIES AND LIMITATIONS ON LIABILITY</h4>
              <p>
                THE SERVICE, INCLUDING OUR WEBSITE AND USER INTERFACES, AND ALL CONTENT AND SOFTWARE ASSOCIATED THEREWITH,
                AND ANY OTHER FEATURES OR FUNCTIONALITIES ASSOCIATED WITH THE SERVICE, ARE PROVIDED "AS IS" AND "AS
                AVAILABLE" WITH ALL FAULTS AND WITHOUT WARRANTY OF ANY KIND. WE AND OUR LICENSORS MAKE NO REPRESENTATIONS OR
                WARRANTIES OF ANY KIND WITH RESPECT TO THE SERVICE, OUR WEBSITE AND USER INTERFACES, OR ANY CONTENT AND
                SOFTWARE ASSOCIATED THEREWITH. SBN BROADCASTING DOES NOT GUARANTEE, REPRESENT, OR WARRANT THAT YOUR USE OF
                THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE, AND YOU AGREE THAT SBN BROADCASTING MAY ELIMINATE OR
                OTHERWISE MODIFY ANY OR ALL ASPECTS OF THE SERVICE, INCLUDING FEATURES, WITHOUT COMPENSATION OR NOTICE TO
                YOU. SBN BROADCASTING SPECIFICALLY DISCLAIMS LIABILITY FOR THE USE OF APPLICATIONS AND THE SERVICE SOFTWARE
                (INCLUDING THEIR CONTINUING COMPATIBILITY WITH THE SERVICE). Without limiting the foregoing, we and our
                licensors assume no liability or responsibility for any of the following: (i) errors or omissions in the
                content delivered by the Service, including the website and user interfaces; (ii) recommendations or advice
                of Customer Service; (iii) any failure or interruption in the availability of the Service and/or website or
                user interfaces, (iv) delivery and or display of any content contained on or through the Service, including
                the website and/or user interfaces; and (vii) any losses or damages arising from the use of the content
                provided on or through the Service, including website and/or user interfaces, including any losses or
                damages arising from downloading of related software, downloading and/or use of any other software, optical
                media discs or any conduct by users of the Service, including website and/or user interfaces. TO THE MAXIMUM
                EXTENT ALLOWABLE BY LAW, WE AND OUR LICENSORS DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, INCLUDING,
                WARRANTIES OF MERCHANTABILITY, TITLE, SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER
                APPLICABLE LAW. In addition, we do not represent or warrant that the information accessible via our site is
                accurate, complete or current. We do not make any representations with respect to the content contained on
                video events from the Service or the descriptions of any video event content contained on our website and
                user interfaces. We do not represent or guarantee that your use of the Service will be free from
                interruption, loss, corruption, attack, viruses, interference, hacking, or other security intrusion and we
                disclaim any liability with respect thereto. No oral or written information or advice given by us or our
                authorized representatives, including Customer Service, shall create a warranty or otherwise constitute a
                representation binding upon SBN Broadcasting or its affiliated parties.<br><br>
                IN NO EVENT SHALL SBN BROADCASTING OR ITS SUBSIDIARIES OR ANY OF THEIR OWNERS, DIRECTORS, OFFICERS,
                EMPLOYEES OR LICENSORS BE LIABLE (JOINTLY OR SEVERALLY) TO YOU OR ANY PARTY FOR PERSONAL INJURY OR ANY
                SPECIAL, INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER RESULTING FROM
                LOSS OF USE, DATA OR PROFITS, BUSINESS INTERRUPTION OR ANY OTHER COMMERCIAL DAMAGES OR LOSS, WHETHER OR NOT
                ADVISED OF THE POSSIBILITY OF DAMAGE, AND ON ANY THEORY OF LIABILITY, ARISING OUT OF OR IN CONNECTION WITH
                THE USE OR PERFORMANCE OF THE SERVICE, OUR WEBSITE AND USER INTERFACES, AND ALL CONTENT AND SOFTWARE
                ASSOCIATED THEREWITH, OR OTHERWISE RELATED TO THE SERVICE, INCLUDING ANY OPTICAL MEDIA DISCS, FEATURES OR
                FUNCTIONALITIES ASSOCIATED THEREWITH. IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU FOR ALL DAMAGES FOR
                LOSSES ARISING FROM THE USE OR INABILITY TO USE THE SERVICE, INCLUDING OUR WEBSITE AND USER INTERFACES, AND
                ALL CONTENT AND SOFTWARE ASSOCIATED THEREWITH (OTHER THAN AS MAY BE REQUIRED BY APPLICABLE LAW IN CASES
                INVOLVING PERSONAL INJURY) EXCEED THE AMOUNT OF YOUR MOST RECENT SUBSCRIPTION FEE. THE FOREGOING LIMITATIONS
                WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE. IF ANY APPLICABLE AUTHORITY HOLDS
                ANY PORTION OF THIS SECTION OR OTHER SECTIONS OF THESE TERMS OF USE, INCLUDING ANY PORTION OF THE PRIVACY
                POLICY, TO BE UNENFORCEABLE, THEN THOSE PORTIONS DEEMED UNENFORCEABLE SHALL BE SEVERED AND THE TERMS OF USE
                SHALL BE ENFORCED ABSENT THOSE PROVISIONS AND ANY LIABILITY WILL BE LIMITED TO THE FULLEST POSSIBLE EXTENT
                PERMITTED BY APPLICABLE LAW. THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED
                UNDER APPLICABLE LAW.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>INTELLECTUAL PROPERTY</h4>
              <p style="font-style: italic;">
                COPYRIGHT
              </p>
              <p>
                The Service, including all content included on the website and/or user interfaces, or delivered to members
                as part of the Service, including, but not limited to, video events you can watch instantly, text, graphics,
                logos, designs, photographs, button icons, images, audio/video clips, digital downloads, data compilations,
                and software, are the property of SBN Broadcasting or its licensors, and are protected by United States and
                international copyright, trade secret or other intellectual property laws and treaties. The compilation of
                all content, data and any software or other materials provided by SBN Broadcasting on the Service, including
                the website and/or user interfaces, or in connection with the Service are the exclusive property of SBN
                Broadcasting and its licensors and are protected by the copyright and trade secret laws in the territories
                in which the Service operates and by international treaty provisions. Neither the Service content nor
                software may be reproduced for any purpose whatsoever. Any use of the Service shall be in strict accordance
                with these Terms. You agree not to decompile, reverse engineer or disassemble any software or other products
                or processes accessible through the Service, not to insert any code or product or manipulate the content of
                the Service in any way, and not to use any data mining, data gathering or extraction method. SBN
                Broadcasting reserves the right to terminate your Subscription hereunder if SBN Broadcasting, in its sole
                and absolute discretion, believes that you are in violation of these Terms or applicable law or that you are
                otherwise infringing or violating SBN Broadcasting's proprietary rights in and to the Service and/or any
                elements thereof. SBN Broadcasting does not promote, foster or condone the copying of content or video
                events of any party or any other infringing activity. The use of the Service, including video events, tools
                and features made available to you by us through the Service, is solely for your personal and non-commercial
                use. For the sake of clarity, your use of any feature on the Service that enables clipping and sharing of
                content from the Service and/or use of that content may be implemented only through the tools provided for
                such feature without any copying of the content and is strictly for personal use and may not be used to
                distribute, syndicate or share with others via any commercial site, service or page. Other terms may be
                posted with and apply to use of such features. Please see Claims of Copyright Infringement below for
                instructions for notifying us of the presence of any allegedly infringing content of the Service, including
                any on the website and/or user interfaces.
              </p>
              <p style="font-style: italic;">
                VIDEO PLAYER
              </p>
              <p>
                You may not modify, interfere with, enhance, remove, or otherwise alter in any way any portion of the Video
                Player, or any of the Video Player's underlying technology, any digital rights management mechanism, device,
                or other content protection or access control measure incorporated into the Video Player. This restriction
                includes, without limitation, disabling, modifying, reverse engineering, interfering with or otherwise
                circumventing the Video Player in any manner that enables users to view the Content without: (i) visibly
                displaying both the Video Player and all surrounding elements (including the graphical user interface, any
                advertising, copyright notices, and trademarks) of the webpage where the Video Player is located; and (ii)
                having full access to all functionality of the Video Player, including, without limitation, all video
                quality and display functionality and all interactive, elective or click-through advertising functionality.
                Other restrictions and terms set forth in these Terms applicable to SBN Broadcasting software shall apply to
                the Video Player.
              </p>
              <p style="font-style: italic;">
                TRADEMARKS
              </p>
              <p>
                The SBN Broadcasting logo and SBNBroadcasting.Com are trademarks or service marks of Tres&oacute;na
                Solutions, LLC. The Service, website and user interfaces, including but not limited to its graphics, logos,
                page headers, button icons, look and feel, scripts and the Service names constitute trade dress of
                Tres&oacute;na Solutions, LLC. The trademarks, service marks and trade dress of the SBN Broadcasting may not
                be used or reproduced without prior written approval from SBN Broadcasting and may not be used in connection
                with any product or service that is not affiliated with SBN Broadcasting, in any manner that is likely to
                cause confusion among customers, in any manner that dilutes the rights of SBN Broadcasting, or in any manner
                that disparages or discredits SBN Broadcasting or its licensors. Other trademarks that appear on the SBN
                Broadcasting website and user interfaces are the property of their respective owners, who may or may not be
                affiliated with, connected to, or sponsored by SBN Broadcasting. Any images of persons or personalities
                contained on SBN Broadcasting website and user interfaces are not an indication or endorsement of SBN
                Broadcasting or any particular product or the Service unless otherwise indicated. Please be aware that
                persons depicted in such images may have additional right in such images that require consent and/or payment
                for any use outside the Service.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>USE OF INFORMATION SUBMITTED</h4>
              <p>
                By submitting comments, information, ideas, concepts, reviews, or techniques or any other material contained
                in any communication you may send to us ("Materials"), including responses to questionnaires or through
                postings to the Service, including the SBN Broadcasting website and user interfaces, you grant us a
                perpetual, transferrable, royalty-free license, with right to sublicense, in and to the Materials for use in
                our discretion via any means, media and format now known or hereafter devised without any obligation to you,
                including, but not limited to, use in developing, manufacturing and marketing new products and services and
                in creating, supplementing, modifying and/or improving the Service. Further, you hereby waive any claim or
                claims against SBN Broadcasting, its affiliates, licensors and successors-in-interest regarding use of such
                Materials. You represent and warrant to us that any and all Materials submitted by you is and will be
                original to you and does not or will not infringe or violate the rights of any party.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>CUSTOMER SERVICE</h4>
              <p>
                If you need assistance with your account, you may find answers and reach Customer Service at any time by
                email to <a href="mailto:support@sbnbroadcasting.com">support@sbnbroadcasting.com</a>. Through these
                resources, you will find the answers to many frequently asked questions and information. The Disclaimers of
                Warranties and Limitations of Liability and other applicable provisions set forth in these Terms expressly
                apply to the use of Customer Service. We may utilize the services of third parties in providing you Customer
                Service support and additional terms may apply.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>YOUR CONDUCT IN ACCESSING THE SERVICE</h4>
              <p>
                SBN Broadcasting content features students and certain content is produced by participating schools and
                their students. As such, the content and the Service are intended to be suitable for a general audience,
                including students and their friends and family members. SBN Broadcasting requires an appropriate level of
                decorum in your use and sharing of our content, similar to what you would encounter by actually visiting a
                school event.
              </p>
              <p>
                By accessing the Service, including the website and/or user interfaces, you agree to use the Service,
                including all features and functionalities associated therewith, the website and/or user interfaces and all
                content and software associated therewith in accordance with these Terms and all applicable laws, rules and
                regulations, including public performance limitations or other restrictions on use of the Service or content
                therein. You agree not to upload, post, e-mail or otherwise send or transmit any material that contains
                software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the
                functionality of any computer software or hardware or telecommunications equipment associated with the
                Service. You also agree not to impersonate any other person or imply any affiliation with SBN Broadcasting
                in connection with your use of the Service, conduct yourself in a vulgar or offensive manner while using the
                Service, or use the Service for any unlawful purpose.
              </p>
              <p>
                You agree that all of the foregoing restrictions, as well as the other applicable restrictions set forth in
                these Terms, apply not only to the Service, but also to the servers and networks connected to any portions
                of the Service whether operated by SBN Broadcasting or third parties ("Networks"). You agree not to
                interfere with Networks, violate any of the procedures, policies or regulations applicable to Networks, use
                Networks in a way that harms or impairs anyone else's use of Networks, use Networks to gain unauthorized
                access to any service, data, account or other networks, use Networks to falsify any protocol or email header
                information (e.g., "spoofing"), use Networks to send "spam" (i.e., unsolicited bulk email or commercial
                messages) or items of a destructive or deceptive nature, or remove, modify, or tamper with any regulatory or
                legal notice or link that is incorporated into Networks.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>LIMITATIONS ON USE</h4>
              <p>
                You must be 18 years of age or older to become a member of the Service and meet other applicable
                requirements as set forth in these Terms. Unless otherwise specified, the Service, and any content viewed
                through the Service, are for your personal and non-commercial use only and we grant you a limited,
                non-exclusive, non-transferable, license to access the Service solely for that purpose. No right, title or
                ownership interest in or to the Service or any elements thereof shall be transferred to you.
              </p>
              <p>
                You may not download (other than through page caching necessary for personal use, or as otherwise expressly
                permitted by these Terms of Use), modify, copy, distribute, transmit, display, perform, reproduce,
                duplicate, publish, license, create derivative works from, or offer for sale any content, data or other
                information contained on, or obtained from or through, the Service.
              </p>
              <p>
                SBN Broadcasting does not promote, foster or condone the unauthorized copying of digitally delivered
                content, or any other infringing activity. You may not circumvent, remove, alter, deactivate, degrade or
                thwart any of the content protections in the Service. You may not purchase search terms or use any meta tags
                or any other "hidden text" utilizing the SBN Broadcasting name or trademarks. Any unauthorized use of the
                Service or its contents will terminate the limited license granted by us and will result in the cancellation
                of your Subscription or subscription
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>CLAIMS OF COPYRIGHT INFRINGEMEN</h4>
              <p>
                It is the policy of SBN Broadcasting to respect the intellectual property rights of others. SBN Broadcasting
                does not permit or condone the unauthorized reproduction, distribution, adaptation, or public performance of
                video events or other copyrighted content. If you believe that any User Contributions violate your
                copyright, please see our <a href="/copyright">Copyright Policy</a> for instructions on sending us a
                notice of copyright infringement. It is the policy of the Company to terminate the user accounts of repeat
                infringers.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>DISPUTE RESOLUTION</h4>
              <p>
                The parties specifically agree to submit any controversy or claim arising out of or relating to this
                agreement, the Service, or any alleged breach of this agreement, or any other controversy or claim between
                the parties, solely and exclusively to arbitration administered by the American Arbitration Association
                ("AAA") in accordance with its Commercial Arbitration Rules. Claims seeking relief on a class-wide or
                consolidated basis rather than on an individual basis may be brought exclusively on an individual basis in
                arbitration and the parties specifically waive any right to bring any claims between themselves in court on
                a consolidated or class-action basis. The validity of this arbitration clause is governed by the Federal
                Arbitration Act, 9 U.S.C. &sect; 1 et seq. The following provisions govern the conduct of the arbitration
                and any
                award therefrom:
              </p>
              <p>
              <ol>
                <li>
                  The notice of intention to arbitration ("demand") must be filed with the AAA regional office to be
                  assigned to Phoenix, Arizona. All in-person hearings will be held in Phoenix, Arizona.
                </li>
                <li>
                  Claims shall be heard by a single arbitrator, unless the claim amount exceeds $2,000,000, in which case
                  the dispute shall be heard by a panel of three arbitrators. The arbitrator(s) must be qualified in matters
                  relating to programming distribution and subscription agreements.
                </li>
                <li>
                  Each party will, upon written request of the other party, promptly provide the other with copies of all
                  relevant documents. The arbitrator(s) will decide any objections to production of evidence. The
                  arbitrator(s) may not allow, direct or authorize any other discovery.
                </li>
                <li>
                  The arbitrator(s) must issue the award within four (4) months of the filing of the demand, and the
                  arbitrator(s) must agree to comply with this schedule before accepting appointment. This time limit may be
                  extended by the arbitrator(s) only for good cause shown or by mutual agreement of the parties.
                </li>
                <li>
                  The arbitrator(s) have no authority to award punitive damages, consequential damages, or other damages not
                  measured by the prevailing party's actual damages, except as may be required by statute. The arbitrator(s)
                  may award only monetary damages and may not include in the award any injunction or direction to a party
                  other than to pay a monetary amount. The arbitrator(s) must include reasonable attorneys' fees to be paid
                  to the prevailing party. The arbitrator(s)' award will include a reasoned opinion.
                </li>
                <li>
                  The parties specifically agree that failure or refusal of any party to pay its required share of the
                  deposits for arbitrator compensation or administrative charges as directed by the AAA constitutes a waiver
                  by that party to present evidence or cross-examine witnesses. In that event, the other party will present
                  evidence and legal argument only as the arbitrator(s) may require for the making of an award. The waiver
                  of the right to present evidence does not allow for a default judgment against the non-paying party.
                </li>
                <li>
                  Except as may be required by law, neither a party nor an arbitrator may disclose the existence, content,
                  or results of any arbitration hereunder without the prior written consent of both parties.
                </li>
                <li>
                  The award may be appealed pursuant to the AAA's Optional Appellate Arbitration Rules ("Appellate Rules")
                  and as a result, the award is not final until after the time for filing the notice of appeal pursuant to
                  the Appellate Rules has expired. Appeals must be initiated within thirty (30) days of receipt of an award,
                  as defined by Rule A-3 of the Appellate Rules, by filing a Notice of Appeal with any AAA office. If no
                  appeal has been filed, then judgment on the award may be taken in any court of competent jurisdiction. If
                  an appeal is filed, then following the appeal process, the decision rendered by the appeal tribunal may be
                  entered in any court having jurisdiction thereof.
                </li>
              </ol>
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>GOVERNING LAW</h4>
              <p>
                All matters relating to the Website and these Terms of Use, and any dispute or claim arising therefrom or
                related thereto (in each case, including non-contractual disputes or claims), shall be governed by and
                construed in accordance with the internal laws of the State of Arizona without giving effect to any choice
                or conflict of law provision or rule (whether of the State of Arizona or any other jurisdiction).
              </p>
              <p>
                Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the Website shall
                be instituted exclusively in the federal courts of the United States or the courts of the State of Arizona,
                in each case located in the City of Phoenix and County of Maricopa, although we retain the right to bring
                any suit, action, or proceeding against you for breach of these Terms of Use in your country of residence or
                any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by
                such courts and to venue in such courts.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>INDEMNICIATION</h4>
              <p>
                You agree to defend, indemnify, and hold harmless SBN Broadcasting, its affiliates, licensors, and service
                providers, and its and their respective officers, directors, employees, contractors, agents, licensors,
                suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards,
                losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or relating to your
                violation of these Terms of Use or your use of the Website, including, but not limited to any use of the
                website's content, services, and products other than as expressly authorized in these Terms of Use, or your
                use of any information obtained from the website.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>LIMITATION ON TIME TO FILE CLAIMS</h4>
              <p>
                ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEBSITE
                MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR
                CLAIM IS PERMANENTLY BARRED.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>WAIVER AND SEVERABILITY</h4>
              <p>
                No waiver by SBN Broadcasting of any term or condition set out in these Terms of Use shall be deemed a
                further or continuing waiver of such term or condition or a waiver of any other term or condition, and any
                failure of SBN Broadcasting to assert a right or provision under these Terms of Use shall not constitute a
                waiver of such right or provision.
              </p>
              <p>
                If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be
                invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the
                minimum extent such that the remaining provisions of the Terms of Use will continue in full force and
                effect.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>ENTIRE AGREEMENT</h4>
              <p>
                The Terms of Use, and our Privacy Policy, constitute the sole and entire agreement between you and SBN
                Broadcasting regarding the Website and supersede all prior and contemporaneous understandings, agreements,
                representations, and warranties, both written and oral, regarding the Website.
              </p>
            </div>
            <div style="margin-bottom:30px;">
              <h4>CONTACTING US</h4>
              <p>
                If you have questions concerning your account or these Terms please contact us via email at <a href="mailto:support@sbnbroadcasting.com">support@sbnbroadcasting.com</a>. For written correspondence,
                our address is:
              </p>
              <p>
                <b>Tres&oacute;na Solutions, LLC</b><br>
                4720 E. Cotton Gin Loop<br>
                Suite 135<br>
                Phoenix, AZ 85040<br>
                Attn: Legal Department<br>
              </p>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import UserService from '@/services/UserService'
import NetworkService from '@/services/NetworkService'

export default {
  name: 'Terms',
  data () {
    return {
      networkInfo: null,
      lastupdate: null
    }
  },
  async mounted () {
    await this.getNetworkInfo()
    await this.getLastUpdate()
  },
  methods: {
    async getNetworkInfo () {
      this.networkInfo = await UserService.getNetworkInfo()
    },
    async getLastUpdate () {
      const info = await NetworkService.getPolicyLastUpdated(30)
      const date = new Date(info.data.updated)
      this.lastupdate = date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
    }
  }
}
</script>
