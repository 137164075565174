<template>
  <div v-if="subscription">
    <SubscriptionTypeInfo
      :subscriptiontype="subscription.subscriptiontype"
      @refresh="$emit('refresh')"
    >
      <slot></slot>
    </SubscriptionTypeInfo>

    <b-row>
      <b-col>
        <h4
          class="my-0 mx-3"
          v-show="
            !(
              subscription.balance > 0 ||
              subscription.nextsubscriptiontype_id > 0 ||
              subscription.autorenew == 0
            )
          "
        >
          <b-icon icon="calendar"></b-icon>
          Next Bill Date: {{ subscription.enddate }}
        </h4>
      </b-col>
    </b-row>

    <b-row
      v-show="subscription.autorenew == 0 && subscription.cancel == 1"
      class="text-danger"
    >
      <b-col>
        <h4 class="my-0 mx-3">
          <b-icon icon="x-circle-fill"></b-icon> Subscription Cancelled
        </h4>
        <p class="my-0 mx-3">
          Subscription will remain active until {{ subscription.enddate }}
        </p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import SubscriptionTypeInfo from '@/components/subscriptiontypes/Info'

export default {
  name: 'Info',
  components: {
    SubscriptionTypeInfo
  },
  props: {
    subscription: Object
  },
  filters: {
    billingType: function (value) {
      return value > 1 ? 'Annually' : value ? 'Monthly' : 'Once'
    }
  }
}
</script>
