<template>
  <div>
    <VideoSearch :id="id"></VideoSearch>
  </div>
</template>
<script>
import VideoSearch from '@/components/video-list/Search'

export default {
  name: 'Channel',
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },
  components: {
    VideoSearch
  }
}
</script>
