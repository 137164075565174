<template>
  <b-row v-if="types && types.length > 0" align-h="center">
    <b-col v-for="type in types" :key="type.id" md="4" sm="12">
      <div class="panel text-center">
        <router-link
          class="btn btn-block btn-success"
          :to="'/subscribe/' + type.id"
        >
          Select
        </router-link>
        <div class="panel-heading">
          <h3>{{ type.name }}</h3>
        </div>
        <div class="panel-body">
          <h2>
            {{ type.localcurrencyformatted }}
            {{ type.duration | billingType }}
          </h2>
          <div v-if="type.localcurrency !== 'USD'" class="small">
            <small
              >* charges will be made in USD @ ${{ type.amount }}, international
              fees may apply</small
            >
          </div>
          <p>{{ type.longdescription }}</p>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'SubscriptionTypes',
  data () {
    return {
    }
  },
  props: {
    types: {
      Array,
      default: () => [
        {
          id: null,
          name: null,
          duration: 1
        }
      ]
    }
  },

  filters: {
    billingType (val) {
      return val > 1 ? 'annually' : (val) ? 'monthly' : ''
    }
  }
}
</script>
