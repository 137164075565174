<template>
  <div>
    <b-row v-if="loading" class="mb-4">
      <b-col cols="12" class="text-center">
        <b-spinner></b-spinner><br />Loading Videos<br /><br />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mb-4">
        <StreamList :limit="3"></StreamList>
      </b-col>
    </b-row>
    <b-row v-if="!loading" class="channel-list">
      <b-col
        :cols="channelCols()"
        :sm="channelCols('sm')"
        :md="channelCols('md')"
        :lg="channelCols('lg')"
        v-for="c in channels"
        :key="c.id"
        class="mb-4 channel-item"
        v-b-tooltip.hover
        :title="c.description ? c.description : ''"
      >
        <router-link :to="'/video/channel/' + c.id">
          <div class="channel-panel">
            <div>
              <b-img :src="c.logoimage_display" :alt="c.name"></b-img>
            </div>
            <span class="channel-name">{{ c.name }}</span>
            <p class="video-count">
              <b-icon icon="film"></b-icon> {{ c.videocount }}
            </p>
          </div>
        </router-link>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import UserService from '@/services/UserService'
import StreamList from '@/components/schedule/List'

export default {
  components: {
    StreamList
  },
  data () {
    return {
      searchText: '',
      pagination: {
        numPages: 1,
        page: 1,
        perPage: 25
      },
      channel_id: null,
      loading: false,
      channels: [],
      videos: [],
      display: 0,
      currentVideo: null
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      this.loading = true
      UserService.getNetworkInfo().then(
        (network) => {
          this.channels = network.channels
          if (this.channels.length === 1) {
            this.$router.push('/video/channel/' + this.channels[0].id)
          }
        }
      ).catch(
        err => {
          this.$aim.error(err.response)
        }
      ).finally(() => { this.loading = false })
    },
    channelCols: function (size = '') {
      if (this.channels.length < 2) {
        return 12
      }

      let counts = []

      switch (size) {
        case 'lg':
          counts = [6, 4, 3]
          break
        case 'md':
          counts = [6, 4, 4]
          break
        case 'sm':
          counts = [6, 6, 6]
          break
        default:
          counts = [12, 12, 12]
          break
      }

      return this.channels.length === 2 ? counts[0] : this.channels.length === 3 ? counts[1] : counts[2]
    }
  }
}
</script>
