var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"role":"region","aria-label":"video"}},[_c('b-overlay',{attrs:{"show":_vm.loading ||
      _vm.videoOptions.sources.length == 0 ||
      _vm.video == null ||
      _vm.video.id == null}},[(_vm.videoOptions.sources.length > 0 && _vm.video && _vm.video.id)?_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('video-player',{attrs:{"options":_vm.videoOptions,"viewedInterval":30,"updateInterval":30,"id":_vm.video.id}})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',{staticClass:"video-watch-info"},[_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.video.video.title)+" "),_c('b-icon',{staticStyle:{"font-size":"0.8em"},attrs:{"icon":_vm.video.favorite ? 'heart-fill' : 'heart',"variant":"danger"},on:{"click":function($event){return _vm.favorite()}}}),_c('b-icon',{staticClass:"ml-4",staticStyle:{"font-size":"0.65em","padding":"4px"},attrs:{"icon":"share-fill"},on:{"click":function($event){_vm.sharebox = !_vm.sharebox}}})],1)]),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('b-btn',{on:{"click":function($event){return _vm.clearVideo()}}},[_c('b-icon',{attrs:{"icon":"caret-up"}}),_vm._v(" Back to Video List")],1)],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[_c('b-collapse',{staticClass:"pb-2",attrs:{"id":"share-box"},model:{value:(_vm.sharebox),callback:function ($$v) {_vm.sharebox=$$v},expression:"sharebox"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"value":_vm.video.shareableLink,"disabled":""}}),_c('b-input-group-append',{on:{"click":function($event){return _vm.copy()}}},[_c('b-input-group-text',{staticStyle:{"background":"#28a745"}},[_c('b-icon',{attrs:{"icon":"clipboard","color":"white"}})],1)],1)],1)],1),_c('div',{staticClass:"video-description"},[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.video.video.description))])]),_c('h4',[_vm._v(" Performed By: "),(
                  _vm.video.video.ensemble.organization.name !==
                  _vm.video.video.ensemble.name
                )?_c('span',[_vm._v(" "+_vm._s(_vm.video.video.ensemble.organization.name)+" - ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.video.video.ensemble.name)+" ")]),(_vm.video.video.programlink && _vm.video.video.programlink !== '')?_c('span',[_c('a',{attrs:{"href":_vm.video.video.programlink,"target":"_blank"}},[_vm._v("Program Link")])]):_vm._e(),_vm._l((_vm.video.video.ownVideocomposition),function(vc){return _c('div',{key:vc.id,staticClass:"video-comp-list"},[_c('p',{staticClass:"mb-0"},[(vc.composition_id > 0)?_c('b',[_vm._v(_vm._s(vc.composition.title))]):_vm._e(),(vc.composition_id == null)?_c('b',[_vm._v(_vm._s(vc.newtitle))]):_vm._e()]),((vc.composition && vc.composition.sharedComposer.length > 0))?_c('ul',{staticClass:"list-inline mb-0 small"},[_c('li',{staticClass:"list-inline-item"},[_vm._v("Written by:")]),_vm._l((vc.composition.sharedComposer),function(co,key){return _c('li',{key:key,staticClass:"list-inline-item"},[_vm._v(" "+_vm._s(co.name)),_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                      key !==
                      Object.keys(vc.composition.sharedComposer).length - 1
                    ),expression:"\n                      key !==\n                      Object.keys(vc.composition.sharedComposer).length - 1\n                    "}]},[_vm._v(", ")])])})],2):_vm._e(),((vc.composition_id == null && vc.newcomposer !== null))?_c('ul',{staticClass:"list-inline mb-0 small"},[_c('li',{staticClass:"list-inline-item"},[_vm._v("Written by:")]),_c('li',{staticClass:"list-inline-item"},[_vm._v(_vm._s(vc.newcomposer))])]):_vm._e()])})],2)],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }