<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        :sm="getCols('sm')"
        :md="getCols('md')"
        :lg="getCols('lg')"
        v-for="video in videoObject.results"
        :key="video.id"
      >
        <ListItem
          v-if="displayType == 'list'"
          :video="video"
          :preview="preview"
          @play="$emit('play', video.id)"
          @update-video-favorite-status="$emit('update-video-favorite-status', video.id)"
        ></ListItem>
        <GridItem
          v-else
          :video="video"
          :preview="preview"
          @play="$emit('play', video.id)"
          @update-video-favorite-status="$emit('update-video-favorite-status', video.id)"
        ></GridItem>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import GridItem from '@/components/video-list/GridItem'
import ListItem from '@/components/video-list/ListItem'

export default {
  props: {
    displayType: {
      type: String,
      default: 'grid'
    },
    videoObject: {
      type: Object,
      default () {
        return {}
      }
    },
    preview: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getCols (size) {
      if (this.displayType === 'list') {
        return 12
      }
      switch (size) {
        case 'sm':
          return '6'
        case 'md':
          return '4'
        case 'lg':
          return '3'
      }
    }
  },
  components: {
    GridItem,
    ListItem
  }
}
</script>
