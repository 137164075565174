<template>
  <div>
    <VideoSearch></VideoSearch>
  </div>
</template>
<script>
import VideoSearch from '@/components/video-list/Search'

export default {
  name: 'Search',

  components: {
    VideoSearch
  }
}
</script>
