import axios from 'axios'
import Widgets from '@/services/Widgets'
// import UserService from '@/services/UserService'

const AccountService = {
  async init () {
    return axios({
      url: axios.defaults.baseURL + 'account/edit/init',
      method: 'post'
    })
  },

  async createInit (subtype) {
    const data = {
      locale: window.navigator.language,
      subscriptiontype_id: subtype
    }
    const params = Widgets.JSON_to_URLEncoded(data)
    return axios.post(
      axios.defaults.baseURL + 'account/create/init',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async checkUsername (username) {
    const params = Widgets.JSON_to_URLEncoded({ username: username })

    return axios.post(
      axios.defaults.baseURL + 'account/check/username',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async checkEmail (email) {
    const params = Widgets.JSON_to_URLEncoded({ email: email })
    return axios.post(
      axios.defaults.baseURL + 'account/check/email',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async checkZip (zip) {
    const params = Widgets.JSON_to_URLEncoded({ zipcode: zip })

    return axios.post(
      axios.defaults.baseURL + 'account/address/lookup/zip',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async checkCode (code, subtype, balance) {
    const languages = navigator.languages === undefined ? [navigator.language] : navigator.languages
    let url = axios.defaults.baseURL + 'discount/check/' + code + '/' + subtype + '/'
    if (+balance > 0) {
      url += balance + '/'
    }
    if (languages && languages.length) {
      url += '?locale=' + languages[0]
    }
    return axios.get(url)
  },

  async create (account) {
    const params = Widgets.JSON_to_URLEncoded({ data: account })

    return axios.post(
      axios.defaults.baseURL + 'account/create',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async save (account) {
    const params = Widgets.JSON_to_URLEncoded({ data: account })

    return axios.post(
      axios.defaults.baseURL + 'account/edit/save',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async savePwChange (data) {
    const params = Widgets.JSON_to_URLEncoded({ data: data })

    return axios.post(
      axios.defaults.baseURL + 'account/edit/savepwchange',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    ).then((response) => {
      /*
       * Because this route always returns a success we need to check if
       * there are items in the success array or in the error array.
       * Based on that we send a success or error to the caller of the method.
       * Makes things easier to read from the method call.
       *
       * We can remove this if in the future we change the api, but right now
       * that is not ideal since it is used by legacy items such as Spin Network.
       */
      return new Promise((resolve, reject) => {
        if (response.data.success && response.data.success.length > 0) {
          resolve({
            data: {
              success: 1
            }
          })
        }
        if (response.data.error && response.data.error.length > 0) {
          reject(new Error(response.data.error[0]))
        }
      })
    })
  },

  async verifyPassword (data) {
    return axios.post(
      axios.defaults.baseURL + 'account/pwverify',
      Widgets.JSON_to_URLEncoded(data),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async getNetworkSwapHash (userroleId) {
    return axios.post(
      axios.defaults.baseURL + 'account/makehash/',
      Widgets.JSON_to_URLEncoded({ userrole_id: userroleId }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async getSingleSignOn (data) {
    return axios.post(
      axios.defaults.baseURL + 'account/checkhash/',
      Widgets.JSON_to_URLEncoded(data),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  }
}

export default AccountService
