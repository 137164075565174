<template>
  <b-container fluid class="m-0 p-0">
    <b-modal v-model="showModal" hide-footer title="Log In" :busy="loading">
      <b-overlay :show="loading">
        <form v-on:submit.prevent="login()" action="">
          <b-row>
            <b-col cols="12" sm="4">
              <label><b>Username or Email:</b></label>
            </b-col>
            <b-col cols="12" sm="8">
              <b-form-group>
                <b-form-input
                  v-model="user.username"
                  type="text"
                  :state="$v.user.username.$error || message !== '' ? false : null"
                  @blur="$v.user.username.$touch()"
                  @input="message = ''"
                  autocomplete="username"
                ></b-form-input>
                <!-- ---------- -->
                <b-form-invalid-feedback v-if="$v.user.username.$error">
                  Username or email is required
                </b-form-invalid-feedback>
                <!-- ---------- -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="4">
              <label><b>Password:</b></label>
            </b-col>
            <b-col cols="12" sm="8">
              <b-form-group>
                <b-form-input
                  v-model="user.password"
                  type="password"
                  :state="$v.user.password.$error || message !== '' ? false : null"
                  @blur="$v.user.password.$touch()"
                  @input="message = ''"
                  autocomplete="current-password"
                ></b-form-input>
                <b-form-invalid-feedback v-if="$v.user.password.$error">
                  Password is required
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              <span class="invalid-feedback d-inline-block" v-if="message !== ''">
                {{ message }}
              </span>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="6">
              <router-link to="/forgot/" @click.native="close()">Forgot your password or username?</router-link>
            </b-col>
            <b-col cols="6" style="text-align: right">
              <b-button @click="close()" class="mr-2" :disabled="loading">
                Close
              </b-button>
              <b-button
                variant="info"
                type="submit"
                :disabled="loading || $v.$invalid"
              >
                Log In
              </b-button>
            </b-col>
          </b-row>
        </form>
      </b-overlay>
    </b-modal>
  </b-container>
</template>
<script>
import UserService from '@/services/UserService'
import axios from 'axios'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { bus } from '@/main'

export default {
  props: {
    show: Boolean
  },
  data: () => {
    return {
      user: {
        username: null,
        password: null
      },
      showModal: false,
      loading: false,
      message: ''
    }
  },
  mixins: [validationMixin],
  validations: {
    user: {
      username: {
        required
      },
      password: {
        required
      }
    }
  },
  methods: {
    close: function () {
      this.user = {
        username: null,
        password: null
      }
      this.message = ''
      // this.$v.$reset()
      this.$emit('close')
    },
    login: async function () {
      this.loading = true
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      // Trim leading and trailing spaces from username and password
      this.user.username = this.user.username.trim()
      this.user.password = this.user.password.trim()

      const data = {
        username: this.user.username,
        password: this.user.password
      }

      if (this.$route.query.wlmask && (this.$route.query.wlmask === true || this.$route.query.wlmask === 'true')) {
        if (this.$route.query.user_id && this.$route.query.userrole_id && this.$route.query.role_id) {
          data.wlmasquerade = {
            user_id: this.$route.query.user_id,
            userrole_id: this.$route.query.userrole_id,
            role_id: this.$route.query.role_id
          }
        }
      }

      UserService.login(data).then(
        (response) => {
          if (response.data.success) {
            axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.key
            UserService.refresh().then(
              (refresh) => {
                UserService.setStorage(refresh.data)

                // get the route of where they were trying to access before logging in
                let routeTo = this.$router.history._startLocation

                // need to remove any query params that are attached to our history because they will affect the check
                // if they are going somewhere with params we don't want to remove them though. This is purely for testing the route
                let cleanRoute = routeTo
                const paramsIdx = cleanRoute.indexOf('?')
                if (paramsIdx !== -1) {
                  cleanRoute = cleanRoute.substring(0, paramsIdx)
                }

                // if they started on the forgot page then don't route back there, its redundant
                const excludedRoutes = ['/forgot/', '/', '/inactive']
                const exclude = excludedRoutes.includes(cleanRoute) || excludedRoutes.includes(cleanRoute + '/') || cleanRoute.startsWith('/subscribe')

                // if they are trying to route to a page they shouldn't be then just take them to the video page
                if (cleanRoute === '' || exclude || typeof cleanRoute === 'undefined') {
                  routeTo = '/video/channel'
                }

                // close the modal because successful login. not sure if its necessary but it keeps it clean
                this.$emit('close')
                // this emit is caught by the nav bar, side nav, and force password chage so they
                // update and see if there is user info (sets the navs to logged in and checks if they need to reset the password)
                bus.$emit('loggedIn')
                // route to where the user was going or to the video channel page
                this.$router.push(routeTo, () => {
                  // notify the user that the login was successful upon route load
                  this.$notify({
                    group: 'notices',
                    title: 'Login Successful',
                    text: 'You are now logged in.',
                    type: 'info'
                  })
                })
              }
            )
          } else {
            this.message = response.data.message
            this.loading = false
          }
        }
      ).catch((error) => {
        this.$aim.error(error.response)
        this.loading = false
      })
    }
  },

  watch: {
    show: function (n) {
      this.showModal = n
    }
  }
}
</script>
