import axios from 'axios'
import Widgets from '@/services/Widgets'

const SubscriptionService = {
  async init () {
    return axios({
      url: axios.defaults.baseURL + 'subscription/init',
      method: 'post'
    })
  },

  async changeInit (subscriptionId, subscriptiontypeId, discount) {
    return axios.post(
      axios.defaults.baseURL + 'subscription/upgrade/init',
      Widgets.JSON_to_URLEncoded({ subscription_id: subscriptionId, subscriptiontype_id: subscriptiontypeId, discount: discount }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async change (data) {
    return axios.post(
      axios.defaults.baseURL + 'subscription/upgrade',
      Widgets.JSON_to_URLEncoded({ data: data }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async cancel (data) {
    return axios.post(
      axios.defaults.baseURL + 'subscription/cancel',
      Widgets.JSON_to_URLEncoded(data),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  }
}

export default SubscriptionService
