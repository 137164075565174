<template>
  <div></div>
</template>
<script>
import AccountService from '@/services/AccountService'
import UserService from '@/services/UserService'
import { bus } from '@/main'

export default {
  data () {
    return {}
  },

  props: {
    id: {
      type: [Number, String],
      default: 0
    },

    hash: {
      type: String,
      default: ''
    },

    timestamp: {
      type: String,
      default: ''
    }
  },

  created () {
    const data = {
      id: this.id,
      hash: this.hash,
      timestamp: this.timestamp
    }

    AccountService.getSingleSignOn(data).then(
      (response) => {
        if (response.data.success && response.data.apikey) {
          localStorage.setItem('user', JSON.stringify({ apikey: response.data.apikey }))
          localStorage.setItem('updated', null)
          UserService.verify('user').then(
            (info) => {
              this.$nextTick(() => {
                bus.$emit('loggedIn')
                this.$router.push('/', () => {
                  this.$forceUpdate()
                })
              })
            }
          )
        } else {
          this.$router.push('/', () => {
            this.$forceUpdate()
          })
        }
      }
    )
  }
}
</script>
