<template>
  <section class="sidebar-panels" v-if="networkInfo" role="region" aria-label="sidebar">
    <div class="card card-default mb-2" v-if="networkInfo.channels.length > 1">
      <div class="card-header">Channels:</div>
      <div class="">
        <ul class="list-group">
          <li class="list-group-item"
            :class="+channel_id === 0 && channel_id != null ? 'bolded' : ''"
          >
            <router-link to="/video/channel"> All Channels </router-link>
          </li>
          <li
            class="list-group-item"
            v-for="c in networkInfo.channels"
            :key="c.id"
            :class="+channel_id == +c.id ? 'bolded' : ''"
          >
            <router-link :to="'/video/channel/' + c.id + '/'" v-b-tooltip.hover :title="c.description ? c.description : ''">
              {{ c.name }} ({{ c.videocount }})
            </router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="card mb-2">
      <div class="card-header">Account:</div>
      <div class="">
        <ul class="list-group">
          <li class="list-group-item">
            <router-link to="/account/"> My Account </router-link>
          </li>
          <li class="list-group-item">
            <router-link to="/account/subscription/">
              My Subscription
            </router-link>
          </li>
          <li class="list-group-item">
            <router-link to="/account/billing/">My Credit Cards</router-link>
          </li>
        </ul>
      </div>
    </div>

    <div class="card mb-2" v-if="user && user.networks && user.networks.length > 0">
      <div class="card-header">My Networks:</div>
      <div class="">
        <ul class="list-group">
          <li class="list-group-item" v-for="net in user.networks" :key="net.id">
            <router-link
              v-if="net.id == networkInfo.id"
              to="/video/channel"
            > <b>{{ net.name }}</b> </router-link>
            <a href="javascript:void(0)" v-else
              style="color:#007bff;"
              @click="swapnetwork(net)"
            >
              {{ net.name}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import UserService from '@/services/UserService'
import { bus } from '@/main'
import AccountService from '@/services/AccountService'

export default {
  name: 'Sidebar',
  data () {
    return {
      networkInfo: null,
      channel_id: null,
      user: null
    }
  },
  created () {
    this.getNetworkInfo()
    this.getUserInfo()
    bus.$on('setChannel', (channel) => {
      if (channel !== this.channel_id) {
        bus.$emit('clearVideo')
      }
      this.channel_id = channel
    })
    if (this.$router.currentRoute.path.includes('video/channel')) {
      if (this.$router.currentRoute.path === '/video/channel' || this.$router.currentRoute.path === '/video/channel/') {
        this.channel_id = 0
      } else {
        this.channel_id = this.$router.currentRoute.params.id
      }
    }
  },
  methods: {
    async getNetworkInfo () {
      this.networkInfo = await UserService.getNetworkInfo()
    },

    async getUserInfo () {
      this.user = await UserService.getUserInfo()
    },

    swapnetwork (network) {
      AccountService.getNetworkSwapHash(network.userrole_id).then(
        (response) => {
          if (response.data.hash !== null && response.data.hash !== 'null') {
            const hash = response.data.hash
            const timestamp = response.data.timestamp
            const link = network.url + '/sso/' + network.userrole_id + '/' + hash + '/' + timestamp
            // the console log is for testing
            console.log(link)
            window.location.replace(link)
          }
        }
      )
    }
  }
}
</script>
