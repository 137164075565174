<template>
  <b-container fluid>
    <b-row class="my-0" v-if="loading">
      <b-col class="text-center"
        ><b-spinner></b-spinner><br />Loading<br /><br
      /></b-col>
    </b-row>
    <b-row class="my-0" v-else v-show="!collapsed || !collapseable">
      <b-col cols="12" class="py-0 d-none d-md-inline-block">
        <b-row class="my-0">
          <b-col cols="3">
            <b>Channel</b>
          </b-col>
          <b-col cols="4">
            <b>Title</b>
          </b-col>
          <b-col cols="2">
            <b>Start</b>
          </b-col>
          <b-col cols="2">
            <b>End</b>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
        <hr />
      </b-col>
      <b-col cols="12" v-for="premiere in premieres" :key="premiere.id">
        <b-row class="mt-4">
          <b-col cols="12" md="3">
            <b-button
              size="sm"
              class="stream-channel-button"
              :href="'/video/channel/' + premiere.networkchannel_id"
              >{{ premiere && premiere.networkchannel && premiere.networkchannel.name ? premiere.networkchannel.name : 'Channel' }}</b-button
            >
            <div class="d-block d-md-none">
              {{ premiere.name }}
              <span v-show="premiere.programlink && premiere.programlink != ''"><br><a :href="premiere.programlink">Event Program</a></span>
              <br /><br />
              {{ premiere.streamdatefull }}<br />
              {{ premiere.streamtimefull }}<br />
              <small> {{ premiere.ts | time }} (est. local) </small><br /><br />
              <b-button :href="premiere.livehref" v-if="premiere.islive" class="stream-channel-button"
                >Watch Now</b-button
              >
              <hr />
            </div>
          </b-col>
          <b-col cols="4" class="d-none d-md-inline-block">
            {{ premiere.name }}
            <span v-show="premiere.programlink && premiere.programlink != ''"><br><a :href="premiere.programlink">Event Program</a></span>
          </b-col>
          <b-col cols="2" class="d-none d-md-inline-block">
            {{ premiere.streamdatefull }}<br />
            {{ premiere.streamtimefull }}<br />
            <small> {{ premiere.ts | time }} (est. local) </small>
          </b-col>
          <b-col cols="2" class="d-none d-md-inline-block">
            {{ premiere.enddatefull }}<br />
            {{ premiere.endtimefull }}<br />
            <small> {{ premiere.endts | time }} (est. local) </small>
          </b-col>
          <b-col cols="1" class="d-none d-md-inline-block">
            <b-button
              class="stream-channel-button"
              :href="premiere.livehref"
              v-if="premiere.islive == 1"
              variant="success"
              >Watch Now</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import LivestreamService from '@/services/LivestreamService'

export default {
  props: {
    limit: {
      type: Number,
      default: 0
    },
    channelId: {
      type: Number,
      default: 0
    },
    collapseable: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      loading: false,
      premieres: [],
      collapsed: true
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init: function () {
      this.loading = true
      const data = {
        scheduletype: 2,
        groupbyvideo: true
      }

      if (this.limit && this.limit > 0) {
        data.limit = this.limit
      }
      if (this.channelId && this.channelId !== 0) {
        data.channel_id = this.channelId
      }

      LivestreamService.getSchedule(data).then(
        (response) => {
          this.premieres = response.data.results
          this.$emit('listCount', this.premieres.length)
        }
      ).catch(error => {
        this.$aim.error(error.response)
      }).finally(() => { this.loading = false })
    }
  },
  filters: {
    time: function (val) {
      if (val) {
        const date = new Date(val * 1000)

        let hours = date.getHours()
        const ampm = hours >= 12 ? 'PM' : 'AM'
        hours = (hours === 0 || hours === 12) ? 12 : hours % 12
        const minutes = '0' + date.getMinutes()

        return hours + ':' + minutes.substr(-2) + ampm
      } else {
        return ''
      }
    }
  },
  watch: {
    channelId: function (n, o) {
      if (n !== o) {
        this.init()
      }
    }
  }
}
</script>
