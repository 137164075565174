<template>
  <span>
    <b-nav-item class="text-uppercase" href="#" v-on:click.prevent="openView()"
      >Contact Us</b-nav-item
    >

    <b-modal
      v-model="openModal"
      title="Contact Us"
      no-close-on-backdrop
      no-close-on-esc
      size="xl"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      :busy="loading"
      :ok-disabled="$v.$invalid || $v.contact.$anyError"
    >
      <b-row>
        <b-col cols="12" xl="3">
          <label class="float-xl-right">What can we help you with?</label>
        </b-col>
        <b-col cols="12" xl="9">
          <b-form-group>
            <b-form-select
              v-model="contact.category"
              :options="categories"
              :state="!$v.contact.category.$error ? null : false"
              @blur="$v.contact.category.$touch()"
            >
            </b-form-select>
            <b-form-invalid-feedback
              v-for="err in getErrors('category', ['required'])"
              :key="err.id"
            >
              {{ err }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="contact.category">
        <b-row>
          <b-col cols="12" lg="3">
            <label class="float-lg-right">First Name</label>
          </b-col>
          <b-col cols="12" lg="9">
            <b-form-group>
              <b-input-group>
                <b-form-input
                  type="text"
                  v-model="contact.firstname"
                  :state="!$v.contact.firstname.$error ? null : false"
                  @blur="$v.contact.firstname.$touch()"
                  autocomplete="given-name"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-for="err in getErrors('firstname', ['required'])"
                  :key="err.id"
                >
                  {{ err }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" lg="3">
            <label class="float-lg-right">Last Name</label>
          </b-col>
          <b-col cols="12" lg="9">
            <b-form-group>
              <b-input-group>
                <b-form-input
                  type="text"
                  v-model="contact.lastname"
                  :state="!$v.contact.lastname.$error ? null : false"
                  @blur="$v.contact.lastname.$touch()"
                  autocomplete="family-name"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-for="err in getErrors('lastname', ['required'])"
                  :key="err.id"
                >
                  {{ err }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" lg="3">
            <label class="float-lg-right">Email</label>
          </b-col>
          <b-col cols="12" lg="9">
            <b-form-group>
              <b-input-group>
                <b-form-input
                  type="text"
                  v-model="contact.email"
                  :state="!$v.contact.email.$error ? null : false"
                  @blur="$v.contact.email.$touch()"
                  autocomplete="email"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-for="err in getErrors('email', ['required', 'email'])"
                  :key="err.id"
                >
                  {{ err }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" lg="3">
            <label class="float-lg-right">Phone</label>
          </b-col>
          <b-col cols="12" lg="9">
            <b-form-group>
              <b-input-group>
                <b-form-input
                  type="text"
                  v-model="contact.phone"
                  :state="!$v.contact.phone.$error ? null : false"
                  @blur="$v.contact.phone.$touch()"
                  autocomplete="tel"
                ></b-form-input>
                <b-form-invalid-feedback
                  v-for="err in getErrors('phone', ['required', 'cellPhone'])"
                  :key="err.id"
                >
                  {{ err }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12" lg="3">
            <label class="float-lg-right">Message</label>
          </b-col>
          <b-col cols="12" lg="9">
            <b-form-group>
              <b-input-group>
                <b-form-textarea
                  type="text"
                  v-model="contact.message"
                  :state="!$v.contact.message.$error ? null : false"
                  @blur="$v.contact.message.$touch()"
                ></b-form-textarea>
                <b-form-invalid-feedback
                  v-for="err in getErrors('message', ['required'])"
                  :key="err.id"
                >
                  {{ err }}
                </b-form-invalid-feedback>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="!isLoggedIn">
          <div class="mx-auto">
            <vue-recaptcha @verify="onVerify" :sitekey="sitekey">
            </vue-recaptcha>
            <label
              v-if="$v.contact.recaptchaResponse.$error"
              class="text-danger"
            >
              Recaptcha is Required
            </label>
          </div>
        </b-row>
      </div>
    </b-modal>
  </span>
</template>

<script>
import UserService from '@/services/UserService'
import NetworkService from '@/services/NetworkService'
import VueRecaptcha from 'vue-recaptcha'
import { validationMixin } from 'vuelidate'
import { required, email, requiredIf } from 'vuelidate/lib/validators'

const cellPhone = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  } else {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)
  }
}

export default {
  name: 'Contact',
  props: {

  },
  components: {
    VueRecaptcha
  },
  data () {
    return {
      openModal: false,
      /*
        currently the id decides who the email goes to
        1 goes to the network owner
        2 goes to us for tickets
      */
      categories: [
        {
          value: {
            id: 1,
            category: 'Questions about musical performances or competitions'
          },
          text: 'Questions about musical performances or competitions'
        },
        {
          value: {
            id: 2,
            category: 'My Account'
          },
          text: 'My Account - Login, Subscription, Payment'
        },
        {
          value: {
            id: 2,
            category: 'Bug Report'
          },
          text: 'Bug Report or problem with site'
        }
      ],
      contact: {
        recaptchaResponse: null,
        category: null,
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        message: null
      },
      sitekey: '6Le-L6wZAAAAANrSopXo5MQU4rPseWwOZDndebHd',
      robot: true,
      isLoggedIn: false,
      loading: false
    }
  },
  methods: {
    openView: function () {
      UserService.getUserInfo().then(
        (user) => {
          if (user && user.apikey !== null) {
            this.isLoggedIn = true
            this.contact.firstname = user.firstname
            this.contact.lastname = user.lastname
            this.contact.email = user.email
          }
        }
      )
      this.openModal = true
    },
    checkFormValidity () {
      const valid = document.getElementById('contact-form').checkValidity()
      this.firstnameState = valid
      this.lastnameState = valid
      this.emailState = valid
      this.phoneState = valid
      this.messageState = valid
      return valid
    },
    resetModal () {
      this.contact = {
        recaptchaResponse: null,
        category: null,
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        message: null
      }
      this.$v.$reset()
    },
    handleOk (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit: function () {
      this.loading = true
      this.$v.$touch()
      // Exit when the form isn't valid
      if (this.$v.$invalid || this.$v.contact.$anyError) {
        const errorTxt = (this.robot && !this.isLoggedIn) ? 'Recaptcha is required' : 'Double check your input'
        this.$aim.error(null, 'warn', 'Something is Missing', errorTxt)
        this.loading = false
        return
      }
      NetworkService.submitContactUs(this.contact)
        .then((response) => {
          // close modal?
          this.openModal = false
          this.$aim.notify(response, 'info', 'Thank You', 'Your report has been submitted and staff will get back to you as soon as possible')
        }, (error) => {
          this.$aim.error(error.response)
        }).finally(() => { this.loading = false })
    },
    onVerify: function (response) {
      this.contact.recaptchaResponse = response
      if (response) {
        this.robot = false
        this.$v.contact.recaptchaResponse.$reset()
      }
    },
    getErrors: function (field, validators) {
      // get validation errors for each field
      const err = []
      if (!this.$v.contact[field].$dirty) return err

      validators.forEach(e => {
        if (!this.$v.contact[field][e]) {
          e === 'required' && err.push('This field is required')
          e === 'email' && err.push('Please enter a valid email')
          e === 'cellPhone' && err.push('Please enter a valid phone number')
        }
      })

      return err
    }
  },
  mixins: [validationMixin],
  validations: {
    contact: {
      recaptchaResponse: {
        required: requiredIf(function () {
          return !this.isLoggedIn
        })
      },
      category: {
        required
      },
      firstname: {
        required
      },
      lastname: {
        required
      },
      email: {
        required,
        email
      },
      phone: {
        required,
        cellPhone
      },
      message: {
        required
      }
    }
  }
}
</script>

<style scoped>
label {
  font-weight: bold;
}
</style>
