const Widgets = {
  // got this from https://gist.github.com/lastguest/1fd181a9c9db0550a847
  JSON_to_URLEncoded: function (element, key, list) {
    list = list || []
    if (typeof (element) === 'object') {
      for (const idx in element) { this.JSON_to_URLEncoded(element[idx], key ? key + '[' + idx + ']' : idx, list) }
    } else {
      list.push(key + '=' + encodeURIComponent(element))
    }
    return list.join('&')
  }
}

export default Widgets
