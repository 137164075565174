<template>
  <div>
    <div class="text-center" v-show="video.loading">
      <b-spinner></b-spinner> {{ video.message }}
    </div>
    <div v-show="!video.loading" style="margin: 0 auto; max-width: 1080px">
      <video ref="videoplayer" class="video-js vjs-default-skin" data-setup='{"fluid": true}'></video>
    </div>
  </div>
</template>
<script>
import videojs from 'video.js'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'
import { registerIVSTech, registerIVSQualityPlugin } from 'amazon-ivs-player'
import { bus } from '@/main'
import LivestreamService from '@/services/LivestreamService'
import VideoService from '@/services/VideoService'

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    livestream: Boolean,
    provider: {
      type: String,
      default: null
    },
    viewedInterval: {
      type: Number,
      default: 30
    },
    updateInterval: {
      type: Number,
      default: 30
    },
    id: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 600
    },
    height: {
      type: Number,
      default: 300
    }
  },

  data: () => {
    return {
      player: null,
      watched: 0,
      timer: null,
      viewed: null,
      video: {
        error: false,
        loading: true,
        message: 'Loading Video',
        player: {
          ended: false,
          error: false,
          playing: false,
          ready: false,
          started: false,
          stopped: false
        }
      },
      videoOptions: null
    }
  },

  mounted: function () {
    const self = this
    bus.$on('videoLoading', () => {
      this.video.loading = true
    })
    bus.$on('videoError', () => {
      this.video.player.error = true
      this.video.loading = false
    })

    this.videoOptions = JSON.parse(JSON.stringify(this.options))

    // "register" the amazon ivs tech if needed
    if (this.provider === 'IVS') {
      this.videoOptions.techOrder = ['AmazonIVS']
      registerIVSTech(videojs, {
        wasmWorker: this.createAbsolutePath('/amazon-ivs-player/amazon-ivs-wasmworker.min.js'),
        wasmBinary: this.createAbsolutePath('/amazon-ivs-player/amazon-ivs-wasmworker.min.wasm')
      })
      registerIVSQualityPlugin(videojs)
    }

    this.player = videojs(this.$refs.videoplayer, this.videoOptions, function () {
      if (self.provider === 'IVS') {
        self.player.src(self.videoOptions.sources)
      }
      self.video.player.ready = true
      self.video.loading = false

      self.player.qualityLevels()
      if (self.provider === 'IVS') {
        // this throws a this.tech is undefined error
        self.player.enableIVSQualityPlugin()
      } else {
        self.player.hlsQualitySelector({
          displayCurrentQuality: true
        })
      }

      bus.$emit('scrollToTop')
      this.play()
    })

    // console.log(this.player.qualityLevels())

    this.player.on('ended', () => {
      this.video.player.ended = true
      this.video.player.stopped = true
      this.video.player.playing = false
    })
    this.player.on('firstplay', () => {
      this.video.player.started = true
      this.video.player.playing = true
      this.video.player.stopped = false
      this.video.player.ended = false
    })
    this.player.on('pause', () => {
      this.video.player.playing = false
      this.video.player.stopped = true
    })
    this.player.on('playing', () => {
      this.video.player.playing = true
      this.video.player.stopped = false
    })
    this.player.on('error', () => {
      this.video.player.playing = false
      this.video.player.error = true
      this.video.player.stopped = true
    })
  },

  methods: {
    viewedSrc: function () {
      const duration = +(this.player.currentTime()) || 0

      if (this.livestream) {
        LivestreamService.viewed(this.id, duration, this.viewed, 0, this.player.stopped).then(
          (response) => {
            if (response.data && response.data.id > 0) {
              this.viewed = response.data.id
            } else {
              this.viewed = false
            }
          }
        )
      } else {
        VideoService.viewed(this.id, this.watched, this.viewed, 0, this.player.stopped).then(
          (response) => {
            if (response.data && response.data.id > 0) {
              this.viewed = response.data.id
            } else {
              this.viewed = false
            }
          }
        )
      }
    },

    createAbsolutePath (path) {
      return new URL(path, document.URL).toString()
    }
  },

  watch: {
    'video.player.started': function (n) {
      if (n && !this.video.loading) {
        this.viewedSrc()
      }
    },
    'video.player.playing': function (n) {
      if (n && !this.video.loading) {
        if (this.timer) {
          clearInterval(this.timer)
        }
        this.timer = setInterval(() => {
          this.watched++
        }, 15000)
      }
    },
    'video.player.stopped': function (n) {
      if (n && !this.video.loading) {
        if (this.timer) {
          clearInterval(this.timer)
        }
        if (this.viewed) {
          this.viewedSrc()
        }
      }
    },
    watched: function (n) {
      if (n >= this.viewedInterval) {
        if (this.updateInterval > 0 && n % this.updateInterval === 0) {
          this.viewedSrc()
        }
      }
    }
  },

  beforeDestroy: function () {
    if (this.player) {
      this.player.dispose()
      if (this.timer) {
        clearInterval(this.timer)
      }
    }
  }
}
</script>
