<template>
  <main>
    <header v-if="network != null && network.active == 1">
      <section>
        <Navigation></Navigation>
      </section>
      <section v-if="$route.meta.jumbotron != 'false' && video == null">
        <Jumbotron></Jumbotron>
      </section>
    </header>
    <section v-if="video && network != null && network.active == 1">
      <watch-video
        ref="videoplayer"
        :video="video"
      ></watch-video>
    </section>

    <section v-if="network != null && network.active == 1">
      <b-row>
        <b-col cols="2" class="d-none d-lg-block" v-if="loggedIn">
          <SideBar></SideBar>
        </b-col>
        <b-col cols="12" :lg="loggedIn ? 10 : 12">
          <slot ref="mainpage"></slot>
        </b-col>
      </b-row>
    </section>
    <section v-if="network != null && network.active == 0">
      <slot ref="mainpage"></slot>
    </section>

    <PasswordChange v-if="network != null && network.active == 1"></PasswordChange>
    <footer class="spin-footer" v-if="network != null && network.active == 1">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <a href=""><img class="logo" :src="network.logoimage_display" width="125" :alt="network.name" /></a>
            <p>&copy; {{ year }} {{ network.name }}</p>
            <p>
              <a href="/privacy" style="text-decoration: underline; color: black;">Privacy Policy</a><span class="d-none d-sm-inline"> - </span><br class="d-sm-none">
              <a href="/copyright" style="text-decoration: underline; color: black;">Copyright Policy</a><span class="d-none d-sm-inline"> - </span><br class="d-sm-none">
              <a href="/terms" style="text-decoration: underline; color: black;">Terms of Use</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  </main>
</template>

<script>
import Jumbotron from '@/components/Jumbotron'
import Navigation from '@/components/Navigation/Navigation'
import SideBar from '@/components/Navigation/Sidebar'
import UserService from '@/services/UserService'
import PasswordChange from '@/components/forms/PasswordChange'
import WatchVideo from '@/components/video-list/WatchVideo'
import { bus } from '@/main'

export default {
  name: 'Default',
  components: {
    Navigation,
    Jumbotron,
    SideBar,
    PasswordChange,
    WatchVideo
  },
  data () {
    return {
      loggedIn: false,
      year: null,
      network: null,
      video: null
    }
  },
  created: function () {
    this.init()
    // when the user logs in, check the user info again
    // this is used for setting sidebar status
    bus.$on('loggedIn', () => {
      this.init()
    })

    bus.$on('loggedOut', () => {
      this.init()
    })

    bus.$on('videoSelected', (video) => {
      this.video = null
      this.video = video
    })

    bus.$on('clearVideo', () => {
      this.video = null
    })

    this.year = (new Date()).getFullYear()
  },
  mounted: function () {
    bus.$on('scrollToTop', (num) => {
      // this is null sometimes and throws an error, probably not the right way to do this
      if (this.$refs.videoplayer) {
        this.$refs.videoplayer.$el.scrollIntoView(true)
      }
    })
  },
  methods: {
    init () {
      // init user info, used for sidebar status
      const userinfo = UserService.getStorage('user')
      if (userinfo && userinfo.apikey !== null) {
        this.loggedIn = true
      } else {
        this.loggedIn = false
      }

      const net = UserService.getStorage('network')

      if (net && net.id > 0) {
        this.network = net
      }
    }
  }
}
</script>
