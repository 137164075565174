<template>
  <b-container fluid role="region" aria-label="billing">
    <div
      v-if="loading"
      style="width: 100%; padding-top: 20vh; text-align: center"
    >
      <b-spinner
        variant="success"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <b-row v-else>
      <b-col cols="12">
        <b-card class="mb-5">
          <b-card-text>
            <BillingList
              v-if="!loading"
              :records="billing"
              :address="address"
              :states="states"
              :countries="countries"
            ></BillingList>

            <b-row v-if="transactions && transactions.length > 0">
              <b-col cols="12">
                <hr />
                <h4>Transaction History</h4>
                <b-table
                  :items="transactions"
                  :fields="transactionFields"
                  striped
                  hover
                >
                  <template #cell(info)="data">
                    <span v-if="data.item.snapshot.billing.billingtype_id">
                      <span
                        v-show="data.item.snapshot.billing.billingtype_id == 1"
                      >
                        {{ data.item.snapshot.billing.creditcardtype }}
                        <span v-show="data.item.snapshot.billing.number"
                          >Ending in
                          {{
                            ("0000" + data.item.snapshot.billing.number).slice(
                              -4
                            )
                          }}</span
                        >
                      </span>
                      <span
                        v-show="data.item.snapshot.billing.billingtype_id != 1"
                      >
                        {{ data.item.snapshot.billing.billingtype.name }}
                      </span>
                    </span>
                  </template>
                  <template #cell(amount)="data">
                    <span v-show="data.item.debit > 0"
                      >({{ data.item.debit | currency }})</span
                    >
                    <span v-show="data.item.credit > 0">{{
                      data.item.credit | currency
                    }}</span>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import BillingService from '@/services/BillingService'
import BillingList from '@/components/billing/List'

export default {
  components: {
    BillingList
  },
  data: () => {
    return {
      loading: false,
      billing: [],
      transactions: [],
      states: [],
      countries: [],
      address: null,
      w9: null,
      validated: false,
      transactionFields: [
        { key: 'id', label: 'ID' },
        'created',
        { key: 'transactiontypename', label: 'Type' },
        'info',
        'amount'
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.loading = true
      BillingService.init().then(
        (response) => {
          this.billing = response.data.results
          this.transactions = response.data.transactions
          this.w9 = response.data.w9
          this.states = response.data.states
          this.countries = response.data.countries
          this.address = response.data.address
          if (this.billing && this.billing.length > 0) {
            this.billing.some((b) => {
              if (b.validated !== 0) {
                this.validated = true
                return true
              }
              return false
            })
          }
        }
      ).catch((error) => {
        this.$aim.error(error.response)
      }).finally(() => { this.loading = false })
    },
    bgClass: function (billing) {
      let output = 'alert alert-info'
      if (+billing.validated !== 1) {
        output = 'alert alert-danger'
      } else if (billing.daysexpire < 30) {
        output = 'alert alert-warning'
      } else if (+billing.expvalid === 0) {
        output = 'alert alert-danger'
      } else if (billing.default_payment_method) {
        output = 'alert alert-success'
      }
      return output
    },
    getSource: function (type) {
      return `/img/cc/lg/${type}.png`
    }
  },
  filters: {
    currency: function (value) {
      return '$' + parseFloat(value).toFixed(2)
    }
  }
}
</script>
<style lang="scss">
img.img-responsive {
  max-width: 100% !important;
  height: auto !important;
}
h5.text-warning {
  color: hsl(45, 100%, 40%) !important;
}
</style>
