<template>
  <b-container fluid class="list-video mt-4">
    <b-row class="thumbnail-video">
      <b-col cols="2">
        <div>
          <div class="video-thumbnail">
            <a
              class="video-overlay"
              @click.prevent="$emit('play')"
              v-if="!video.noView"
              href=""
            >
              <b-icon
                icon="play-fill"
                class="play-icon large mx-auto"
                v-b-popover.hover.top=" !preview ? 'Watch Now' : 'Subscribe Now'"
              ></b-icon>
              <div class="overlay"></div>
            </a>
            <a class="video-overlay" v-if="video.video.featured > 0">
              <span class="featured-video-overlay">Featured Video</span>
            </a>
            <b-img
              src="https://placehold.it/960x550?text=Spin+Network"
              style="max-width: 100%; height: auto"
              v-if="video.thumbError"
              :alt="video.video.title"
            ></b-img>
            <b-img
              v-else
              :src="video.thumbnail_link"
              style="max-width: 100%; height: auto"
              :title="video.video.title"
              :alt="video.video.title"
            ></b-img>
          </div>
        </div>
      </b-col>
      <b-col cols="10">
        <b-row>
          <b-col cols="12">
            <b-dropdown
              dropup
              toggle-class="text-decoration-none"
              left
              variant="link"
              no-caret
              class="float-right"
            >
              <template #button-content v-if="!preview">
                <b-button variant="success">
                  <b-icon icon="share-fill"></b-icon> Share This Video
                </b-button>
              </template>
              <b-dropdown-text>
                <b-input-group>
                  <b-input readonly v-model="videolink"></b-input>
                  <b-input-group-addon>
                    <b-button @click="copy()">
                      <small><b-icon icon="clipboard"></b-icon> Copy</small>
                    </b-button>
                  </b-input-group-addon>
                </b-input-group>
              </b-dropdown-text>
            </b-dropdown>
            <h5>
              {{ video.video.title | stringMax(60) }}
              <b-badge variant="primary" v-show="video.new">NEW</b-badge>
            </h5>
            <div v-if="video.video.ensemble.id !== 1">
              <h6 class="m-0">
                {{ video.video.ensemble.organization.name }}
              </h6>
              <p class="m-0">
                {{ video.video.ensemble.name }}
              </p>
              <p
                class="m-0"
                v-show="
                  video.video.organization_id !==
                  video.video.ensemble.organization_id
                "
              >
                Channel: {{ video.video.organization.name }}
              </p>
            </div>
            <div v-if="video.video.ensemble.id !== 1 && !preview">
              <a
                v-for="(val, i) in sharedTags"
                :href="'/video?text_search=' + val.tag"
                :key="i"
                style="margin-right: 4px"
                v-b-popover.hover.top="'Search For'"
              >
                <b-badge pill variant="secondary">{{
                  val.tag | stringMax(34)
                }}</b-badge>
              </a>
              <span
                v-show="
                  video.video.sharedTag && video.video.sharedTag.length > 6
                "
                >...</span
              >
            </div>
            <div v-if="video.video.ensemble.id !== 1 && preview">
              <a
                v-for="(val, i) in sharedTags"
                :key="i"
                style="margin-right: 4px"
              >
                <b-badge pill variant="secondary">{{
                  val.tag | stringMax(34)
                }}</b-badge>
              </a>
              <span
                v-show="
                  video.video.sharedTag && video.video.sharedTag.length > 6
                "
                >...</span
              >
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <h5>
              <a
                href=""
                v-if="!video.noView && !preview"
                @click.prevent="favorite()"
                :class="'text-danger'"
                v-b-popover.hover.top="
                  video.favorite ? 'Remove Favorite' : 'Add Favorite'
                "
                ><b-icon :icon="video.favorite ? 'heart-fill' : 'heart'"></b-icon
              ></a>
              <a
                href=""
                @click.prevent="showInfo = 1"
                v-show="showInfo !== 1"
                class="ml-2"
                v-b-popover.hover.top="'Video Info'"
                ><b-icon icon="info-circle-fill"></b-icon
              ></a>
            </h5>
          </b-col>
        </b-row>
        <b-row v-show="showInfo">
          <b-col cols="12">
            <div class="well">
              <p class="m-0 small" ng-show="v.showInfo">
                Video Owner: {{ video.video.organization.name }}
              </p>
              <h6>
                Description:
                <a
                  href=""
                  class="float-right"
                  v-b-popover.hover.left="'Hide Info'"
                  @click.prevent="showInfo = 0"
                >
                  <b-icon
                    icon="x-circle-fill"
                    style="font-size: 1.5em !important"
                  ></b-icon>
                </a>
              </h6>
              <div
                class="video-description"
                v-show="video.video.description.length > 0"
              >
                <p class="m-0 small">
                  {{ video.video.description }}
                </p>
              </div>
              <span
                v-show="
                  video.video.programlink && video.video.programlink !== '' && !preview
                "
              >
                <a :href="video.video.programlink" target="_blank">
                  Program Link
                </a>
              </span>

              <div
                v-for="vc in video.video.ownVideocomposition"
                :key="vc.id"
                class="video-comp-list"
                v-show="video.video.ensemble.id !== 1 && !preview"
              >
                <p class="mb-0">
                  <b v-if="vc.composition_id > 0">{{ vc.composition.title }}</b>
                  <b v-if="vc.composition_id == null">{{ vc.newtitle }}</b>
                </p>
                <ul
                  class="list-inline mb-0 small"
                  v-if="(vc.composition && vc.composition.sharedComposer.length > 0)"
                >
                  <li class="list-inline-item">Written by:</li>
                  <li
                    class="list-inline-item"
                    v-for="(co, key) in vc.composition.sharedComposer"
                    :key="key"
                  >
                    {{ co.name
                    }}<span
                      v-show="
                        key !==
                        Object.keys(vc.composition.sharedComposer).length - 1
                      "
                      >,
                    </span>
                  </li>
                </ul>
                <ul class="list-inline mb-0 small" v-if="(vc.composition_id == null && vc.newcomposer !== null)">
                  <li class="list-inline-item">Written by:</li>
                  <li class="list-inline-item">{{ vc.newcomposer }}</li>
                </ul>
              </div>

            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import VideoService from '@/services/VideoService'
import UserService from '@/services/UserService'
export default {
  props: {
    video: {
      type: Object,
      default () {
        return {}
      }
    },
    preview: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      showInfo: 0,
      videolink: ''
    }
  },
  methods: {
    favorite: function () {
      VideoService.favorite(this.video.id).then(
        (response) => {
          this.$emit('update-video-favorite-status')
        }
      )
    },
    copy: function () {
      const el = document.createElement('textarea')
      el.style.position = 'fixed'
      el.style.opacity = 0
      el.textContent = decodeURI(this.videolink)
      const body = document.getElementsByTagName('body')[0]
      body.appendChild(el)
      el.select()
      document.execCommand('copy')
      body.removeChild(el)
    },
    getVideolink: async function () {
      const network = await UserService.getNetworkInfo()
      this.videolink = network.url + '/video/watch/' + this.video.id
    }
  },
  computed: {
    /**
     * @returns {Array}
     */
    sharedTags: function () {
      if (this.video.video.sharedTag.length > 6) {
        return this.video.video.sharedTag.slice(0, 6)
      }
      return this.video.video.sharedTag
    }
  },
  filters: {
    stringMax: function (val, amount) {
      let str = val
      if (val.length > (amount + 3)) {
        str = val.substr(0, amount) + '...'
      }
      return str
    }
  },
  mounted: function () {
    this.getVideolink()
  }
}
</script>
