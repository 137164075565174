<template>
  <b-jumbotron
    v-if="networkInfo"
    fluid
    container-fluid
    class="banner"
    :style="headerImage()"
  >
    <b-row>
      <b-col md="2">
        <div
          class="profile-image jumbosize"
          :style="profileImage()"
        ></div>
      </b-col>
      <b-col></b-col>
    </b-row>
  </b-jumbotron>
</template>

<script>
import UserService from '@/services/UserService'
import { bus } from '@/main'

export default {
  name: 'Jumbotron',
  data () {
    return {
      networkInfo: null,
      channelId: null
    }
  },
  async mounted () {
    await this.getNetworkInfo()

    bus.$on('setChannel', (channel) => {
      this.channelId = channel
    })

    if (this.$router.currentRoute.path.includes('video/channel')) {
      if (this.$router.currentRoute.path === '/video/channel' || this.$router.currentRoute.path === '/video/channel/') {
        this.channelId = 0
      } else {
        this.channelId = this.$router.currentRoute.params.id
      }
    }
  },
  methods: {
    async getNetworkInfo () {
      this.networkInfo = await UserService.getNetworkInfo()
    },

    headerImage () {
      let channel = null
      if (this.channelId && this.channelId > 0) {
        this.networkInfo.channels.some(
          e => {
            if (+e.id === +this.channelId) {
              channel = e
              return true
            }
            return false
          }
        )
      }

      if (channel && channel.bannerimage_display && channel.bannerimage_display !== '') {
        return { 'background-image': 'url("' + channel.bannerimage_display + '")' }
      } else if (this.networkInfo.headerimage_display) {
        return { 'background-image': 'url("' + this.networkInfo.headerimage_display + '")' }
      } else {
        return { 'background-image': 'url("/img/profile-banner.png")' }
      }
    },

    profileImage () {
      let channel = null
      if (this.channelId && this.channelId > 0) {
        this.networkInfo.channels.some(
          e => {
            if (+e.id === +this.channelId) {
              channel = e
              return true
            }
            return false
          }
        )
      }
      if (channel && channel.logoimage_display && channel.logoimage_display !== '') {
        return { 'background-image': 'url("' + channel.logoimage_display + '")' }
      } else if (this.networkInfo.profileimage_display) {
        return { 'background-image': 'url("' + this.networkInfo.profileimage_display + '")' }
      } else {
        return { 'background-image': 'url("/img/profileimage.png")' }
      }
    }
  }
}
</script>

<style scoped>
.banner {
  background-position: center center;
  background-size: cover;
  border-bottom: 4px solid #293866;
}
.profile-image {
  /* make it responsive */
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 300px;
  /* div height to be the same as width*/
  padding-top: 100%;
  /* make it a circle */
  border-radius: 50%;
  /* Centering on image`s center*/
  background-position-y: center;
  background-position-x: center;
  background-repeat: no-repeat;
  /* it makes the clue thing, takes smaller dimension to fill div */
  background-size: cover;
  /* it is optional, for making this div centered in parent*/
  margin: 0 auto;
}
</style>
