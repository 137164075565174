<style scoped>
.spacer {
  line-height: 1.5;
  height: 1rem;
  margin-bottom: 0.65rem;
  display: inline-block;
}
.subscription-selection .card-body {
  padding: 0 !important;
}
</style>
<template>
  <b-container fluid>
    <Loading :loading="loading"></Loading>
    <b-form v-if="!loading" @submit.prevent>
      <b-row>
        <b-col>
          <h1>SUBSCRIBE</h1>
        </b-col>
      </b-row>
      <b-row class="my-4 mx-md-5">
        <b-col cols="12">
          <b-card header="Personal Information">
            <b-card-text>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label" for="firstname"
                      >First Name *</label
                    >
                    <b-input-group>
                      <b-form-input
                        type="text"
                        v-model.lazy="account.user.firstname"
                        :state="!$v.account.user.firstname.$error"
                        @blur="$v.account.user.firstname.$touch()"
                        @input="setBillingName"
                        :disabled="loading"
                        placeholder="First"
                        autocomplete="given-name"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label" for="lastname"
                      >Last Name *</label
                    >
                    <b-input-group>
                      <b-form-input
                        type="text"
                        v-model.lazy="account.user.lastname"
                        :state="!$v.account.user.lastname.$error"
                        @blur="$v.account.user.lastname.$touch()"
                        @input="setBillingName"
                        :disabled="loading"
                        placeholder="Last"
                        autocomplete="family-name"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="control-label" for="email"
                      >Email Address *</label
                    >
                    <b-input-group>
                      <b-form-input
                        type="email"
                        placeholder="Email"
                        v-model.lazy="account.user.email"
                        :state="!$v.account.user.email.$error"
                        @input="$v.account.user.email.$touch()"
                        @blur="$v.account.user.email.$touch()"
                        :disabled="loading"
                        autocomplete="email"
                      ></b-form-input>
                      <b-input-group-append v-show="searchEmail">
                        <b-input-group-text>
                          <b-icon icon="arrow-repeat" animation="spin"></b-icon>
                        </b-input-group-text>
                      </b-input-group-append>
                      <b-form-invalid-feedback
                        v-if="
                          $v.account.user.email.email && $v.account.user.email.required &&
                          !$v.account.user.email.isUnique
                        "
                      >
                        This email is taken
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback
                        v-for="err in getErrors('user', 'email', [
                          'email',
                        ])"
                        :key="err.id"
                      >
                        {{ err }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </div>
                </div>
                <div class="col-sm-2">
                  <div class="form-group">
                    <label class="control-label" for="countrycode"
                      >Phone Country *</label
                    >
                    <b-form-group>
                      <b-form-select
                        v-model.lazy="account.user.countrycode_id"
                        :options="countryCodes"
                        :disabled="loading"
                        :state="!$v.account.user.countrycode_id.$error"
                        @blur.native="$v.account.user.countrycode_id.$touch()"
                        autocomplete="tel-country-code"
                      >
                        <template #text-field></template>
                        <template #first>
                          <b-form-select-option :value="null" disabled
                            >Country Code</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="control-label" for="phone">Phone *</label>
                    <div>
                      <b-input-group>
                        <b-form-input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Phone"
                          v-model.lazy="account.user.phone"
                          :state="!$v.account.user.phone.$error"
                          @blur="$v.account.user.phone.$touch()"
                          :disabled="loading"
                          autocomplete="tel-national"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          v-for="err in getErrors('user', 'phone', [
                            'cellPhone',
                          ])"
                          :key="err.id"
                        >
                          {{ err }}
                        </b-form-invalid-feedback>
                      </b-input-group>
                    </div>
                  </div>
                </div>
              </div>
              <AddressForm
                :countries="countries"
                :states="states"
                @dataChange="setUserAddress"
                is-registration
              ></AddressForm>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="my-4 mx-md-5" v-if="subscriptiontype">
        <b-col cols="12">
          <b-card header="Subscription Information">
            <b-card-text>
              <b-row class="text-center">
                <b-col cols="12" sm="4">
                  <h3>{{ subscriptiontype.name }}</h3>
                  <h2>{{ subscriptiontype.localcurrencyformatted }}</h2>
                  <div>
                    {{ subscriptiontype.duration | billingType }}
                  </div>
                  <small v-show="subscriptiontype.localcurrency !== 'USD'">
                    * charges will be made in USD @ ${{
                      subscriptiontype.amount
                    }}, international fees may apply
                  </small>
                  <b-button
                    variant="danger"
                    @click="clearSubscriptionType()"
                    v-show="subscriptiontypes.length > 1"
                    >Choose a Different Type</b-button
                  >
                </b-col>
                <b-col cols="12" sm="8">
                  <p>
                    <i>Subscription Terms</i>: {{ subscriptiontype.terms }}
                    <br /><b v-show="subscriptiontype.duration">
                      ** subscription will auto renew until you cancel.
                    </b>
                  </p>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-row
        class="my-4 mx-md-5 subscription-selection"
        v-show="account.subscriptiontype_id == null"
      >
        <b-col cols="12">
          <b-row class="align-self-stretch">
            <b-col
              v-for="t in subscriptiontypes"
              :key="t.id"
              class="text-center"
            >
              <b-card @click="selectSubType(t)" class="h-100 px-0">
                <b-card-header class="bg-success text-white">
                  <h3>{{ t.name }}</h3>
                </b-card-header>
                <b-card-text class="p-3">
                  <h2>{{ t.localcurrencyformatted }}</h2>
                  <div>
                    {{ t.duration | billingType }}
                  </div>
                  <small v-show="t.localcurrency !== 'USD'">
                    * charges will be made in USD @ ${{ t.amount }},
                    international fees may apply
                  </small>
                  <div>
                    <p>{{ t.description }}</p>
                  </div>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <!-- discount code -->
      <b-row v-if="account.subscriptiontype_id" class="my-4 mx-md-5">
        <b-col cols="12">
          <DiscountCode
            :discount="account.discount"
            :subscriptiontype_id="+account.subscriptiontype_id"
            @discountApplied="applyDiscount"
            color="none"
          ></DiscountCode>
        </b-col>
      </b-row>

      <b-row
        v-show="account.subscriptiontype_id && amountDue > 0"
        class="my-4 mx-md-5"
      >
        <b-col cols="12">
          <b-card header="Billing Information">
            <b-card-text>
              <b-row>
                <b-col cols="12" v-if="subscriptiontype">
                  <h3>Amount Due: {{ amountDueFormatted }}</h3>
                </b-col>
              </b-row>

              <BillingForm
                :states="states"
                :countries="countries"
                :name="billingName"
                :is-registration="true"
                @update="setBilling"
              ></BillingForm>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="my-4 mx-md-5">
        <b-col cols="12">
          <b-card header="User Account Information">
            <b-card-text>
              <b-row>
                <b-col cols="12" sm="4">
                  <b-form-group>
                    <label class="control-label">User Name * (5 minimum)</label>
                    <b-input-group>
                      <b-form-input
                        type="text"
                        v-model.lazy="account.user.username"
                        :state="!$v.account.user.username.$error"
                        @blur="$v.account.user.username.$touch()"
                        @input="$v.account.user.username.$touch()"
                        :disabled="searchUsername"
                        minlength="5"
                        placeholder="Desired Username"
                        autocomplete="username"
                      ></b-form-input>
                      <b-input-group-append v-if="searchUsername">
                        <b-input-group-text>
                          <b-icon icon="arrow-repeat" animation="spin"></b-icon>
                        </b-input-group-text>
                      </b-input-group-append>
                      <b-form-invalid-feedback
                        v-for="err in getErrors('user', 'username', [
                          'minLength',
                        ])"
                        :key="err.id"
                      >
                        {{ err }}
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback
                        v-if="
                          $v.account.user.username.required &&
                          $v.account.user.username.minLength &&
                          !$v.account.user.username.isUnique
                        "
                      >
                        Username is taken
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="4">
                  <b-form-group>
                    <label class="control-label">Password *</label>
                    <b-input-group>
                      <b-form-input
                        type="password"
                        v-model.lazy="account.user.password"
                        :state="!$v.account.user.password.$error"
                        @blur="$v.account.user.password.$touch()"
                        required
                        minlength="6"
                        placeholder="New Password"
                        autocomplete="new-password"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback
                        v-for="err in getErrors('user', 'password', [
                          'minLength',
                        ])"
                        :key="err.id"
                      >
                        {{ err }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="4">
                  <b-form-group>
                    <label class="control-label">Password Confirm *</label>
                    <b-input-group>
                      <b-form-input
                        type="password"
                        v-model.lazy="account.user.password2"
                        :state="!$v.account.user.password2.$error"
                        @blur="$v.account.user.password2.$touch()"
                        required
                        placeholder="New Password Confirm"
                        autocomplete="new-password"
                      >
                      </b-form-input>
                      <b-form-invalid-feedback
                        v-for="err in getErrors('user', 'password2', [
                          'sameAsPassword',
                        ])"
                        :key="err.id"
                      >
                        {{ err }}
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-checkbox
                    v-model="account.user.terms"
                    :state="!$v.account.user.terms.$invalid"
                    >I have read, understood, and agree to the
                    <a href="/terms/" target="_blank"
                      >Terms of Use</a
                    ></b-form-checkbox
                  >
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-checkbox
                    v-model="account.user.privacy"
                    :state="!$v.account.user.privacy.$invalid"
                    >I have read, understood, and agree to the
                    <a href="/privacy/" target="_blank"
                      >Privacy Policy</a
                    ></b-form-checkbox
                  >
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <div class="custom-control custom-checkbox mt-4">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      id="promo"
                      v-model="account.user.promo"
                    />
                    <label class="custom-control-label" for="promo">
                      I wish to receive email notifications when new videos are uploaded or new live stream events are addded to the network.
                      I also understand that from time to time I may receive newsletters and promotional material from SBN Broadcasting.
                      These email preferences can be changed at any time by accessing the "my account" section of the network.
                    </label>
                  </div>
                </b-col>
              </b-row>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <div style="text-align: center">
            <vue-recaptcha sitekey="6Le-L6wZAAAAANrSopXo5MQU4rPseWwOZDndebHd" @verify="handleSuccess" @error="handleError" style="text-align: center;">
              <Checkbox style="display: inline-block; text-align: center;" />
            </vue-recaptcha>
          </div>
        </b-col>
      </b-row>
      <b-row class="mb-5">
        <b-col class="text-center">
          <b-button
            @click.prevent="submit"
            type="button"
            variant="success"
            :disabled="!captcharesponse"
            >Subscribe</b-button
          ><br>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import axios from 'axios'
import AccountService from '@/services/AccountService'
import UserService from '@/services/UserService'
import VueRecaptcha from 'vue-recaptcha'

import Loading from '@/components/widget/Loading'
import AddressForm from '@/components/forms/Address'
import BillingForm from '@/components/billing/Form'
import DiscountCode from '@/components/billing/DiscountCode'
import { validationMixin } from 'vuelidate'
import {
  required,
  email,
  minLength,
  sameAs
} from 'vuelidate/lib/validators'

const cellPhone = value => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  } else {
    return /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(value)
  }
}

export default {
  components: {
    Loading,
    AddressForm,
    BillingForm,
    DiscountCode,
    VueRecaptcha
  },
  data: () => {
    return {
      loading: false,
      searchUsername: false,
      searchEmail: false,
      usernameTaken: false,
      emailTaken: false,
      checkEmailTimer: null,
      checkUsernameTimer: null,
      billingName: null,
      account: {
        subscriptiontype_id: null,
        user: {
          firstname: null,
          lastname: null,
          email: null,
          username: null,
          password: null,
          password2: null,
          phone: null,
          countrycode_id: null,
          terms: false,
          privacy: false,
          promo: true
        },
        address: {
          address1: null,
          address2: null,
          city: null,
          state: null,
          state_id: null,
          zip: null,
          country_id: '184',
          valid: false
        },
        billing: {
          billingtype_id: 1,
          creditcardtype: null,
          name: null,
          firstname: null,
          lastname: null,
          number: null,
          expmonth: null,
          expyear: null,
          cvc: null,
          valid: false
        },
        billingAddressSame: true,
        billingAddress: {
          address1: null,
          address2: null,
          city: null,
          state_id: null,
          zip: null,
          country_id: '184',
          valid: false
        },
        discount: {}
      },
      states: [],
      countries: [],
      subscriptiontypes: [],
      subscriptiontype: null,
      captcharesponse: false
    }
  },

  props: {
    subtype: {
      type: [Number, String],
      default: null
    }
  },

  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.loading = true
      AccountService.createInit(this.subtype).then(
        (response) => {
          this.states = response.data.states
          this.countries = response.data.countries
          this.subscriptiontypes = response.data.subscriptiontypes

          if (this.subscriptiontypes.length === 1) {
            this.selectSubType(this.subscriptiontypes[0])
          } else if (this.subtype && +this.subtype > 0) {
            let st = null
            this.subscriptiontypes.some((t) => {
              if (t.id === this.subtype) {
                st = t
                return true
              }
              return false
            })

            if (st) {
              this.selectSubType(st)
            }
          }
        }
      ).catch(
        (error) => {
          this.$aim.error(error.response)
        }
      ).finally(() => {
        this.loading = false
        this.$v.account.$touch()
      })
    },

    handleSuccess (response) {
      const verify = UserService.verifyCaptcha(response)
      verify.then((result) => {
        this.captcharesponse = result.data.success
      }).catch((error) => {
        console.error(error)
      })
    },

    handleError (response) {
    },

    selectSubType (type) {
      this.account.subscriptiontype_id = type.id
      this.subscriptiontype = type
    },

    clearSubscriptionType () {
      this.account.subscriptiontype_id = null
      this.account.discount = {}
      this.subscriptiontype = null
    },

    getErrors (form, field, validators) {
      const err = []
      if (!this.$v.account[form][field].$dirty) return err

      validators.forEach(e => {
        if (!this.$v.account[form][field][e]) {
          e === 'sameAsPassword' && err.push('Passwords do not match')
          e === 'minLength' && err.push(field.capitalize() + ' must be at least ' + this.$v.account[form][field].$params.minLength.min + ' characters')
          e === 'email' && err.push('Please enter a valid email')
          e === 'cellPhone' && err.push('Please enter a valid phone number')
        }
      })

      return err
    },

    setBilling (info) {
      if (!info || !info.billing) {
        return false
      }
      if (info.billing.name && info.billing.name.length > 0) {
        this.account.billing.name = info.billing.name
      }
      if (!info.billing.billingAddressSame) {
        this.account.billingAddress = info.billingAddress
      }
      if (info.billing.number) {
        this.account.billing.number = info.billing.number
      }
      if (info.billing.expmonth) {
        this.account.billing.expmonth = info.billing.expmonth
      }
      if (info.billing.expyear) {
        this.account.billing.expyear = info.billing.expyear
      }
      if (info.billing.cvc) {
        this.account.billing.cvc = info.billing.cvc
      }
      if (info.billing.creditcardtype) {
        this.account.billing.creditcardtype = info.billing.creditcardtype
      }
      this.account.billing.valid = info.billing.valid
    },

    /** populate billing name on user first/last input */
    setBillingName () {
      this.billingName = ''
      if (this.account.user.firstname) {
        this.billingName = this.account.user.firstname
      }
      if (this.account.user.lastname) {
        this.billingName += ' ' + this.account.user.lastname
      }
    },

    setUserAddress (address) {
      this.account.address = address
    },

    prepData () {
      const data = JSON.parse(JSON.stringify(this.account))
      data.amount = this.subscriptiontype.amount
      data.role_id = this.subscriptiontype.role_id
      if (data.billingAddressSame) {
        data.billingAddress = data.address
      }
      delete data.billingAddressSame
      if (data.billing.name) {
        const n = data.billing.name.split(' ')
        data.billing.lastname = n.pop()
        data.billing.firstname = n.join(' ')
        delete data.billing.name
      }
      if (data.billing.number) {
        data.billing.number = btoa(data.billing.number)
        data.billing.cvc = btoa(data.billing.cvc)
      }
      return data
    },

    async submit () {
      this.$v.$touch()
      if (this.$v.$invalid || (this.amountDue > 0 && !this.account.billing.valid)) {
        let messages = ''
        if (this.account.subscriptiontype_id === null) {
          messages += '<p style="color: red">No subscription type selected.</p>'
        }
        if (this.$v.$invalid) {
          messages += '<p style="color: red">Missing or invalid data. Look for any sections of the form highlighted in red.</p>'
        }
        if (this.amountDue > 0 && !this.account.billing.valid) {
          messages += '<p style="color: red">Billing information is invalid</p>'
        }
        this.$bvModal.msgBoxOk(this.$createElement('div', {
          domProps: { innerHTML: messages }
        }), {
          title: 'Errors found'
        })
        return false
      }

      const data = this.prepData()
      this.loading = true
      AccountService.create(data).then(
        (response) => {
          axios.defaults.headers.common.Authorization = 'Bearer ' + response.data.apikey
          UserService.refresh().then(
            (refresh) => {
              UserService.setStorage(refresh.data)
              // response.data.redirect = '/'
              this.$aim.notify(response)
              // hard reload
              window.location.href = '/'
            }
          ).catch((error) => {
            this.$aim.error(error.response)
            this.loading = false
          })
        }
      ).catch(
        (error) => {
          this.$aim.error(error.response)
          this.loading = false
        }
      )
    },
    /**
     * Set the discount this user is using, from the
     * discououtcode.vue event
     */
    applyDiscount (discount) {
      this.account.discount = discount
    }
  },

  computed: {
    /**
     * @returns {Array}
     */
    countryCodes: function () {
      const codes = []
      this.countries.forEach(e => {
        codes.push({ value: e.id, text: e.isothreeletter + ' - +' + e.countrycode })
      })
      return codes
    },
    /**
     * @returns {Number}
     */
    amountDue () {
      return (this.account && this.account.discount && this.account.discount.id > 0) ? parseFloat(this.account.discount.subscriptiontype.amount) : (this.subscriptiontype) ? parseFloat(this.subscriptiontype.amount) : 0
    },
    /**
     * @returns {String}
     */
    amountDueFormatted () {
      return (this.account.discount && this.account.discount.id > 0) ? this.account.discount.subscriptiontype.localcurrencyformatted : (this.subscriptiontype) ? this.subscriptiontype.localcurrencyformatted : 0
    }
  },

  filters: {
    /**
     * @returns {String}
     */
    billingType: function (val) {
      return val > 1 ? 'Billed Annually Until Cancellation' : (val) ? 'Billed Monthly Until Cancellation' : 'One Time Payment'
    }
  },

  mixins: [validationMixin],
  validations: {
    account: {
      user: {
        username: {
          required,
          minLength: minLength(5),
          isUnique (value) {
            return new Promise((resolve) => {
              if (this.checkUsernameTimer) {
                clearTimeout(this.checkUsernameTimer)
                this.checkUsernameTimer = null
              }
              if (!value || value.length < 5) {
                resolve(false)
              } else {
                this.checkUsernameTimer = setTimeout(() => {
                  this.searchUsername = true
                  AccountService.checkUsername(value).then((response) => {
                    if (response.data && response.data.exists !== undefined) {
                      this.searchUsername = false
                      resolve(response.data.exists)
                    } else {
                      this.searchUsername = false
                      resolve(false)
                    }
                  }).catch(() => resolve(false))
                }, 700)
              }
            })
          }
        },
        password: {
          required,
          minLength: minLength(6)
        },
        password2: {
          required,
          sameAsPassword: sameAs('password')
        },
        firstname: {
          required
        },
        lastname: {
          required
        },
        email: {
          required,
          email,
          isUnique (value) {
            return new Promise((resolve) => {
              if (this.checkEmailTimer) {
                clearTimeout(this.checkEmailTimer)
                this.checkEmailTimer = null
              }
              if (!value) {
                resolve(false)
              } else if (!email(value)) {
                resolve(false)
              } else {
                this.checkEmailTimer = setTimeout(() => {
                  this.searchEmail = true
                  AccountService.checkEmail(value).then((response) => {
                    if (response.data && response.data.exists !== undefined) {
                      this.searchEmail = false
                      resolve(response.data.exists)
                    } else {
                      this.searchEmail = false
                      resolve(false)
                    }
                  }).catch(() => resolve(false))
                }, 700)
              }
            })
          }
        },
        phone: {
          required,
          minLength: minLength(10),
          cellPhone
        },
        countrycode_id: {
          required
        },
        terms: {
          required,
          checked: sameAs(() => true)
        },
        privacy: {
          required,
          checked: sameAs(() => true)
        }
      },
      address: {
        address1: {
          required
        },
        zip: {
          required,
          minLength: minLength(5)
        },
        city: {
          required
        },
        state: {
          required
        },
        country_id: {
          required
        }
      }
    }
  }
}
</script>
