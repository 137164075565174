<template>
  <div>
    <b-button
      style="transform: translateY(4px)"
      :variant="isCancelled ? 'success' : 'danger'"
      type="button"
      @click.prevent="openModal = true"
    >
      <b-icon
        :icon="actionName === 'Renew' ? 'check2-circle' : 'x-circle-fill'"
      ></b-icon>
      {{ actionName }} My Subscription
    </b-button>

    <b-modal
      id="subscription-cancel-modal"
      v-model="openModal"
      no-close-on-backdrop
      no-close-on-esc
      :title="actionName + ' My Subscription'"
      :ok-title="actionName + ' Subscription'"
      :ok-variant="isCancelled ? 'success' : 'danger'"
      size="xl"
      cancel-title="Close"
      @ok="toggleAutoRenew"
    >
      <Loading :loading="loading"></Loading>

      <b-card v-if="!loading" no-body>
        <b-card-header>
          <h3 class="m-0">Current Subscription Info:</h3>
        </b-card-header>
        <b-card-text class="m-3">
          <h2 class="mb-3">{{ subscription.network }}</h2>
          <Info :subscription="subscription" class="ml-3"></Info>
        </b-card-text>
      </b-card>

      <b-row v-if="!loading && subscription.balance <= 0">
        <b-col>
          <b-alert variant="info" class="text-center m-3" show>
            <h3 class="m-0">
              Your subscription will
              {{
                actionName === "Renew"
                  ? " resume billing on "
                  : " remain active until "
              }}
              {{ subscription.enddate }}.
            </h3>
          </b-alert>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import Loading from '@/components/widget/Loading'
import Info from '@/components/subscriptions/Info'
import SubscriptionService from '@/services/SubscriptionService'

export default {
  name: 'Cancel',
  props: {
    subscription: Object
  },
  components: {
    Loading,
    Info
  },
  data () {
    return {
      openModal: false,
      loading: false
    }
  },

  methods: {
    toggleAutoRenew (e) {
      e.preventDefault()
      if (this.loading) {
        return false
      }
      this.loading = true

      SubscriptionService.cancel({ subscription_id: this.subscription.id }).then(
        (response) => {
          if (response && response.data && response.data.success) {
            this.$nextTick(() => {
              this.$bvModal.hide('subscription-cancel-modal')
            })
            this.$aim.notify(response)
            this.$emit('refresh')
          } else {
            this.$aim.error(response)
          }
        }
      ).catch(
        (error) => {
          this.$aim.error(error.response)
        }
      ).finally(() => {
        this.loading = false
      })
    }
  },

  computed: {
    isCancelled () {
      return (+this.subscription.autorenew !== 1 && this.subscription.cancel === 1)
    },
    actionName () {
      return this.isCancelled ? 'Renew' : 'Cancel'
    }
  }
}
</script>
