<template>
  <b-container fluid>
    <b-row class="my-0" v-if="loading">
      <b-col class="text-center"
        ><b-spinner></b-spinner><br />Loading<br /><br
      /></b-col>
    </b-row>
    <b-row class="my-0" v-else v-show="!collapsed || !collapseable">
      <b-col cols="12" class="py-0 d-none d-md-inline-block">
        <b-row class="my-0">
          <b-col cols="3">
            <b>Channel</b>
          </b-col>
          <b-col cols="5">
            <b>Title</b>
          </b-col>
          <b-col cols="2">
            <b>Start</b>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <hr />
      </b-col>
      <b-col cols="12" v-for="stream in streams" :key="stream.id">
        <b-row class="mt-4">
          <b-col cols="12" md="3">
            <b-button
              size="sm"
              class="stream-channel-button"
              :href="'/video/channel/' + stream.networkchannel_id"
              >{{ stream && stream.networkchannel && stream.networkchannel.name ? stream.networkchannel.name : 'Channel' }}</b-button
            >
            <div class="d-block d-md-none">
              {{ stream.name }}
              <span v-show="stream.lineuplink && stream.lineuplink != ''"
                ><br />
                <a :href="stream.lineuplink" target="_blank"> Event Program </a>
              </span>
              <br /><br />
              {{ stream.streamdatefull }}<br />
              {{ stream.streamtimefull }}<br />
              <small> {{ stream.ts | time }} (est. local) </small><br /><br />
              <b-button :href="stream.livehref" v-if="stream.islive"
                class="stream-channel-button"
                >Watch Now</b-button
              >
              <hr />
            </div>
          </b-col>
          <b-col cols="5" class="d-none d-md-inline-block">
            {{ stream.name }}
            <span v-show="stream.lineuplink && stream.lineuplink != ''"
              ><br />
              <a :href="stream.lineuplink" target="_blank">Event Program</a>
            </span>
          </b-col>
          <b-col cols="2" class="d-none d-md-inline-block">
            {{ stream.streamdatefull }}<br />
            {{ stream.streamtimefull }}<br />
            <small> {{ stream.ts | time }} (est. local) </small>
          </b-col>
          <b-col cols="2" class="d-none d-md-inline-block">
            <b-button
              :href="stream.livehref"
              v-if="stream.islive == 1"
              variant="success"
              class="stream-channel-button"
              >Watch Now</b-button
            >
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import LivestreamService from '@/services/LivestreamService'

export default {
  props: {
    limit: {
      type: Number,
      default: 0
    },
    channelId: {
      type: Number,
      default: 0
    },
    collapseable: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      loading: false,
      streams: [],
      collapsed: true
    }
  },
  mounted: function () {
    this.init()
  },
  methods: {
    init: function () {
      this.loading = true
      const data = {
        scheduletype: 1
      }

      if (this.limit && this.limit > 0) {
        data.limit = this.limit
      }
      if (this.channelId && this.channelId !== 0) {
        data.channel_id = this.channelId
      }

      LivestreamService.getSchedule(data).then(
        (response) => {
          this.streams = response.data.results
          this.$emit('listCount', this.streams.length)
        }
      ).catch(error => {
        this.$aim.error(error.response)
      }).finally(() => { this.loading = false })
    }
  },
  filters: {
    time: function (val) {
      if (val) {
        const date = new Date(val * 1000)

        let hours = date.getHours()
        const ampm = hours >= 12 ? 'PM' : 'AM'
        hours = (hours === 0 || hours === 12) ? 12 : hours % 12
        const minutes = '0' + date.getMinutes()

        return hours + ':' + minutes.substr(-2) + ampm
      } else {
        return ''
      }
    }
  },
  watch: {
    channelId: function (n, o) {
      if (n !== o) {
        this.init()
      }
    }
  }
}
</script>
