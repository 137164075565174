<template>
  <span>
    <b-button
      class="btn-block"
      @click="modalstate = true"
      :variant="btnColor"
      :size="btnSize"
    >
      <slot name="btnContent">
        Open Modal
      </slot>
    </b-button>

    <b-modal
      id="credit-card-modal"
      :title="((billingrecord) ? 'Edit' : 'Add') + ' Credit Card'"
      v-model="modalstate"
      no-close-on-backdrop
      no-close-on-esc
      :ok-title="((billingrecord) ? 'Edit' : 'Add') + ' Card'"
      ok-variant="success"
      :ok-disabled="(!form.billing.valid && form.billing.cardedit && this.form.billing.cardedit > 0) || !form.billingAddress.valid || loading"
      size="lg"
      @ok="updateBilling"
    >
      <Loading :loading="loading"></Loading>
      <BillingForm
        v-if="!loading"
        :address="address"
        :states="states"
        :countries="countries"
        :billingrecord="billingrecord"
        @update="setBilling"
      ></BillingForm>
    </b-modal>
  </span>
</template>

<script>
import BillingService from '@/services/BillingService'
import Loading from '@/components/widget/Loading'
import BillingForm from '@/components/billing/Form'

export default {
  components: {
    Loading,
    BillingForm
  },

  props: {
    type: {
      type: String,
      default: 'add'
    },
    btnColor: {
      type: String,
      default: 'success'
    },
    btnSize: {
      type: String,
      default: 'sm'
    },
    billingrecord: {
      type: Object,
      default () { return null }
    },
    address: {
      type: Object,
      default () { return null }
    },
    states: Array,
    countries: Array
  },

  data: () => {
    return {
      modalstate: false,
      loading: false,
      form: {
        billing: {
          valid: false
        },
        billingAddress: {
          valid: false
        }
      }
    }
  },

  methods: {
    setBilling (data) {
      this.form = Object.assign({}, data)
    },

    prepData () {
      const data = {
        info: this.form.billing,
        address: this.form.billingAddress,
        save: { address: 1, info: 1 }
      }
      const tmp = data.info.name.split(/\s+/)
      data.info.firstname = tmp.shift()
      data.info.lastname = tmp.join(' ')
      data.info.number = btoa(data.info.number)
      data.info.cvc = btoa(data.info.cvc)
      return data
    },

    updateBilling (e) {
      if (e) {
        e.preventDefault()
      }
      if (this.loading) {
        return false
      }
      if ((!this.form.billing.valid && this.form.billing.cardedit && this.form.billing.cardedit > 0) || !this.form.billingAddress.valid) {
        alert('Your card information is invalid. Please try again.')
        return false
      }
      this.loading = true

      BillingService.save(this.prepData()).then(
        (response) => {
          this.$emit('refresh')
          this.$aim.notify(response)
          this.$nextTick(() => {
            this.$bvModal.hide('credit-card-modal')
          })
        }
      ).catch((error) => {
        this.$aim.error(error.response)
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
