<template>
  <b-container role="region" aria-label="forgot password">
    <div v-if="loading" style="width: 100%; text-align: center">
      <b-spinner
        variant="success"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <b-row>
      <b-col cols="12" md="6" class="mt-4">
        <b-card header="Reset Password">
          <b-card-text>
            <b-row class="text-center">
              <b-col cols="12">
                Enter your username below to reset your password
              </b-col>
              <b-col cols="12" class="mt-4">
                <b-row class="text-left">
                  <b-col cols="4" class="pt-1">
                    <b>Username:</b>
                  </b-col>
                  <b-col cols="8">
                    <b-form-group>
                      <b-form-input
                        v-model="username"
                        type="text"
                        @blur="$v.username.$touch()"
                        :state="$v.username.$error ? false : null"
                        autocomplete="username"
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        Username is required
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <b-button
                  variant="danger"
                  :disabled="$v.username.$invalid || loading || reset"
                  @click="resetPassword()"
                  >Reset Password</b-button
                >
              </b-col>
              <b-col
                cols="12"
                v-if="resetMessageHTML !== ''"
                class="text-success"
              >
                <b><span v-html="resetMessageHTML"></span></b>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" class="mt-4">
        <b-card header="Recover Username">
          <b-card-text>
            <b-row class="text-center">
              <b-col cols="12">
                Enter your email below to recover your username
              </b-col>
              <b-col cols="12" class="mt-4">
                <b-row class="text-left">
                  <b-col cols="4" class="pt-1">
                    <b>Email:</b>
                  </b-col>
                  <b-col cols="8">
                    <b-form-group>
                      <b-form-input
                        v-model="email"
                        type="text"
                        @blur="$v.email.$touch()"
                        :state="$v.email.$error ? false : null"
                        autocomplete="email"
                      ></b-form-input>
                      <b-form-invalid-feedback v-if="!$v.email.required">
                        Email is required
                      </b-form-invalid-feedback>
                      <b-form-invalid-feedback v-if="!$v.email.email">
                        Please enter a valid email
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12">
                <b-button
                  variant="danger"
                  :disabled="$v.email.$invalid || loading || recovered"
                  @click="recoverUsername()"
                  >Recover Username</b-button
                >
              </b-col>
              <b-col
                cols="12"
                v-if="recoverMessageHTML !== ''"
                class="text-success"
              >
                <b><span v-html="recoverMessageHTML"></span></b>
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import UserService from '@/services/UserService'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'

export default {
  data: () => {
    return {
      loading: false,
      username: null,
      email: null,
      resetMessageHTML: '',
      recoverMessageHTML: '',
      reset: false,
      recovered: false
    }
  },
  mixins: [validationMixin],
  validations: {
    username: {
      required
    },
    email: {
      required,
      email
    }
  },
  methods: {
    resetPassword: function () {
      this.$v.username.$touch()
      if (this.$v.username.$invalid) {
        return
      }

      this.loading = true

      UserService.resetPassword(this.username).then(
        (response) => {
          if (response && response.data && response.data.error.length === 0) {
            this.resetMessageHTML = ''
            response.data.success.forEach(e => {
              this.resetMessageHTML += e + '<br>'
            })
          } else {
            alert('Failed to reset password! ' + response.data.error[0])
          }
          this.reset = true
        }
      ).catch(
        error => {
          alert('Failed to reset password! ' + error.data.error[0])
          this.loading = false
        }
      ).finally(() => { this.loading = false })
    },
    recoverUsername: function () {
      this.$v.email.$touch()
      if (this.$v.email.$invalid) {
        return
      }

      this.loading = true

      UserService.retrieveUsername(this.email).then(
        (response) => {
          if (response && response.data && response.data.error.length === 0) {
            this.recoverMessageHTML = ''
            response.data.success.forEach(e => {
              this.recoverMessageHTML += e + '<br>'
            })
            this.recovered = true
          } else {
            alert('Failed to reset password! ' + response.data.error[0])
          }
          this.loading = false
        }
      ).catch(
        error => {
          alert('Failed to reset password! ' + error.data.error[0])
          this.loading = false
        }
      ).finally(() => { this.loading = false })
    }
  }
}
</script>
