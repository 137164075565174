<template>
  <div>
    <b-row v-if="!loading">
      <b-col cols="5">
        <router-link
          to="/video/channel"
          v-if="channels.length > 1"
        >
          <b-icon icon="arrow-left"></b-icon> Back to Channels
        </router-link>
      </b-col>
      <b-col
        cols="7"
        class="mb-2"
      >
        <b-input-group class="float-right">
          <b-form-input
            v-model="searchText"
            size="sm"
            placeholder="Search Videos"
            @keyup.enter.native="getVideos()"
          ></b-form-input>
          <b-input-group-append>
            <b-button
              variant="success"
              size="sm"
              :disabled="loading"
              @click.prevent="getVideos()"
            >
              <b-icon-search></b-icon-search> Search
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <StreamList v-if="channel_id > 0" :channel="+channel_id" :limit="3"></StreamList>
    <b-row
      v-if="loading"
    >
      <b-col
        cols="12"
        class="text-center"
      >
        <b-spinner></b-spinner><br />Loading Videos<br /><br />
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-button-group class="float-right">
          <b-button
            :pressed="display == 0"
            @click="display = 0"
          >
            <b-icon icon="grid-fill"></b-icon>
          </b-button>
          <b-button
            :pressed="display == 1"
            @click="display = 1"
          >
            <b-icon icon="list"></b-icon>
          </b-button>
        </b-button-group>
        <b-tabs
          v-model="currentTab"
        >
          <b-tab
            v-for="vid in videos"
            :key="vid.id"
          >
            <template #title>
              <b-icon :icon="vid.iconClass"></b-icon> <span class="d-none d-lg-inline">{{ vid.title }}</span> <b-badge>{{ vid.totalResults }}</b-badge>
            </template>
            <b-pagination
              v-if="vid.pagination && vid.totalResults > vid.pagination.perPage"
              align="right"
              class="mt-4"
              v-model="vid.pagination.page"
              :total-rows="vid.totalResults"
              @change="updatePage($event)"
            ></b-pagination>
            <VideoList
              :displayType="display == 0 ? 'grid' : 'list'"
              :videoObject="vid"
              @play="showVideo"
              @update-video-favorite-status="updateVideoFavoriteStatus"
            ></VideoList>
            <h2 v-if="vid.totalResults == 0 && !loading" class="text-center mt-4">This channel has not uploaded a video yet</h2>
              <b-pagination
                v-if="vid.pagination && vid.totalResults > vid.pagination.perPage"
                align="right"
                class="mt-4"
                v-model="vid.pagination.page"
                :total-rows="vid.totalResults"
                @change="updatePage($event)"
              ></b-pagination>
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import VideoService from '@/services/VideoService'
import VideoList from '@/components/video-list/List'
import StreamList from '@/components/schedule/List'
import UserService from '@/services/UserService'
import { bus } from '@/main'

export default {
  name: 'VideoSearch',
  props: {
    id: {
      type: [String, Number],
      default: null
    }
  },

  data () {
    return {
      searchText: '',
      pagination: {
        numPages: 1,
        page: 1,
        perPage: 20,
        rows: 1
      },
      channel_id: null,
      loading: false,
      channels: [],
      videos: [],
      display: 0,
      currentTab: 0,
      streamList: 0
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      this.loading = true
      this.channel_id = this.id
      const url = new URL(window.location.href)
      const params = new URLSearchParams(url.search)
      this.searchText = params.get('text_search')
      setTimeout(() => { this.getVideos() }, 1)
      UserService.getNetworkInfo().then(network => {
        this.channels = network.channels
      })
    },

    showVideo (id) {
      bus.$emit('clearVideo')
      VideoService.fetch(id).then(
        (response) => {
          bus.$emit('videoSelected', response.data)
        }
      ).catch(
        err => {
          this.$aim.notify(err.response)
        }
      )
    },

    getVideos (list = null) {
      this.loading = true
      const pag = (list !== null) ? (this.videos[list].pagination ? this.videos[list].pagination : this.pagination) : this.pagination
      const data = {
        filters: {},
        pagination: pag,
        list: list
      }
      if (this.searchText && this.searchText !== '') {
        data.filters.text_search = this.searchText
      }
      // If channel_id is set, we are on the channel video list page
      if (this.channel_id) {
        data.filters.channel_id = this.channel_id
        VideoService.channelVideos(data).then(
          (response) => {
            if (list !== null) {
              this.videos[list] = response.data.videos
            } else {
              this.videos = response.data.videos
            }
          }
        ).catch((error) => {
          this.$aim.error(error.response)
        }).finally(() => { this.loading = false })
      } else {
        VideoService.search(data).then(
          (response) => {
            this.videos = [response.data]
          }
        ).catch((error) => {
          this.$aim.error(error.response)
        }).finally(() => { this.loading = false })
      }
    },

    updateVideoFavoriteStatus (id) {
      this.videos.some(videos => {
        let found = false
        videos.results.some(x => {
          if (x.id === id) {
            x.favorite = !x.favorite
            found = true
            return true
          }
          return false
        })
        return found
      })
    },
    updatePage (event) {
      this.videos[this.currentTab].pagination.page = event
      this.getVideos(this.currentTab)
    }
  },

  components: {
    VideoList,
    StreamList
  },

  watch: {
    id: function (n, o) {
      if (o !== n) {
        this.channel_id = n
        this.streamList = 0
        this.init()
      }
    }
  }
}
</script>
