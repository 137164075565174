<template>
  <span>
    <h4>
      <b-icon icon="person-fill"></b-icon> {{ card.firstname }} {{ card.lastname }}
    </h4>
    <address>
      <b-icon icon="globe"></b-icon>
      {{ card.address.address1 }}
      {{ card.address.address2 }}<br>
      {{ card.address.city }}, {{ card.address.state }}
      {{ card.address.zip }}
    </address>
  </span>
</template>
<script>
export default {
  name: 'CreditCardAddress',
  props: {
    card: Object
  }
}
</script>
