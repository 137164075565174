<template>
  <b-modal
    v-if="payment"
    id="payment-confirm-modal"
    v-model="openModal"
    no-close-on-backdrop
    no-close-on-esc
    title="Confirm Payment"
    ok-title="Confirm Payment"
    ok-variant="success"
    :ok-disabled="loading || !isValid"
    size="lg"
    @ok="confirmPayment"
  >
    <Loading :loading="loading"></Loading>

    <b-row v-if="!loading">
      <b-col>
        <div class="alert alert-success text-center">
          <h4><i class="glyphicon glyphicon-alert"></i> You are applying a payment for the Unpaid Balance of {{ payment.status.localbalance.localcurrencyformatted }}</h4>
          <p>Please Confirm Your Payment.</p>
        </div>
      </b-col>
    </b-row>

    <Verify
      v-if="!loading && amountDue > 0 && payment.billing && payment.billing.id"
      :open="openModal"
      :card="payment.billing"
      @isValid="setValidity"
      @update="setBillingCvc"
    ></Verify>

    <b-row
      class="mt-3"
      v-if="!loading"
    >
      <b-col>
        <div class="alert alert-info text-center">
          <h2><i class="glyphicon glyphicon-alert"></i> Amount to be Billed: {{ payment.status.localbalance.localcurrencyformatted }}</h2>
        </div>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import BillingService from '@/services/BillingService'
import Loading from '@/components/widget/Loading'
import Verify from '@/components/creditcards/Verify'

export default {
  name: 'PayBalanceConfirm',

  components: {
    Loading,
    Verify
  },

  props: {
    open: Boolean,
    payment: Object
  },

  data () {
    return {
      loading: false,
      openModal: false,
      cvc: null,
      cvcValid: false
    }
  },

  methods: {
    prepData () {
      const data = Object.assign({}, this.payment)
      data.account = {}
      data.account.subscription_id = (data.subscription) ? data.subscription.id : null
      data.billing_id = (this.payment.billing) ? this.payment.billing.id : null
      data.cvc = btoa(this.cvc)
      return data
    },

    confirmPayment (e) {
      if (e) {
        e.preventDefault()
      }
      if (this.loading || !this.isValid) {
        return false
      }
      this.loading = true
      const data = this.prepData()
      BillingService.payment(data).then(
        (response) => {
          if (response && response.data) {
            this.$aim.notify(response)
            this.close()
            this.$emit('refresh')
          } else {
            this.$aim.notify(response, 'error', 'Payment Failed', 'Your payment failed to process.')
          }
        }
      ).catch((error) => {
        this.$aim.error(error.response)
        // this.close()
      }).finally(() => {
        this.loading = false
      })
    },

    close () {
      this.$nextTick(() => {
        this.$bvModal.hide('payment-confirm-modal')
      })
    },

    setValidity (info) {
      this.cvcValid = info
    },

    setBillingCvc (info) {
      this.cvc = info
    }
  },

  computed: {
    amountDue () {
      return (this.payment && this.payment.status) ? this.payment.status.balance : 0
    },

    amountDueFormatted () {
      return (this.payment && this.payment.status) ? this.payment.status.localbalance.localcurrencyformatted : 0
    },

    isValid () {
      return (this.payment && this.payment.amount > 0 && this.payment.billing && this.payment.billing.id > 0 && this.cvcValid)
    }
  },

  watch: {
    open () {
      this.openModal = this.open
    }
  }
}
</script>
