<template>
  <b-card>
    <b-card-text>
      <b-row v-if="!isRegistration">
        <b-col cols="12">
          <b-form-group label="Nickname">
            <b-form-input
              type="text"
              v-model.lazy="form.billing.nickname"
              placeholder="Card Nickname"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label class="control-label">Name on card *</label>
            <b-form-input
              type="text"
              v-model.lazy="form.billing.name"
              :disabled="loading"
              placeholder="Name on card"
              :state="getFormState('name')"
              @blur="$v.form.billing.name.$touch()"
              autocomplete="cc-name"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col
          v-if="!billingrecord"
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label class="control-label">Billing Address</label>
            <b-input-group>
              <b-form-checkbox v-model="form.billingAddressSame"></b-form-checkbox>
              <b-input-group-append>
                Billing address is the same as main address
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="!form.billingAddressSame || !isRegistration">
        <b-col cols="12">
          <hr />
          <AddressForm
            :countries="countries"
            :states="states"
            :disabled="form.billingAddressSame"
            :mainaddress="address"
            @dataChange="setAddress"
            :is-registration="isRegistration"
          ></AddressForm>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          class="text-center"
        >
          <hr class="mb-5">
          <span
            class="text-danger"
            style="font-size: 1.1rem"
          >
            <b>Please note: This charge will appear on your statement from &quot;SBN Musical Performances Online&quot;.</b>
          </span>
          <hr class="mt-5">
        </b-col>
      </b-row>

      <CreditCardForm
        :billing="billingrecord"
        @update="setCard"
        :is-registration="isRegistration"
      ></CreditCardForm>
    </b-card-text>
  </b-card>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import CreditCardForm from '@/components/creditcards/Form'
import AddressForm from '@/components/forms/Address'

export default {
  name: 'BillingForm',
  components: {
    CreditCardForm,
    AddressForm
  },

  props: {
    states: {
      type: Array,
      default () { return [] }
    },
    countries: {
      type: Array,
      default () { return [] }
    },
    name: {
      type: String,
      default: ''
    },
    address: {
      type: Object,
      default () { return null }
    },
    billingrecord: {
      type: Object,
      default () { return null }
    },
    isRegistration: {
      type: Boolean,
      default: false
    }
  },

  data: () => {
    return {
      loading: false,

      default: {
        billing: {
          billingtype_id: 1,
          creditcardtype: null,
          name: null,
          nickname: null,
          firstname: null,
          lastname: null,
          number: null,
          expmonth: null,
          expyear: null,
          cvc: null,
          valid: false,
          cardedit: 1
        },
        billingAddress: {
          address1: null,
          address2: null,
          city: null,
          state_id: null,
          zip: null,
          country_id: '184',
          valid: true
        }
      },

      form: {
        billing: {},
        billingAddress: {},
        billingAddressSame: true
      }
    }
  },

  mounted () {
    this.init()
  },

  methods: {
    init () {
      if (this.billingrecord) {
        this.form.billing = Object.assign({}, this.billingrecord)
        this.form.billing.name = this.form.billing.firstname + ' ' + this.form.billing.lastname
        this.form.billingAddress = Object.assign({}, this.billingrecord.address)
        this.form.billingAddressSame = false
      } else {
        this.form.billing = Object.assign({}, this.default.billing)
        this.form.billingAddress = Object.assign({}, (this.address) ? this.address : this.default.billingAddress)
      }

      this.$v.form.billing.$touch()
    },

    setAddress (data) {
      this.form.billingAddress = (data) || Object.assign({}, this.default.billingAddress)
    },

    setCard (data) {
      const temp = Object.assign({}, this.form.billing)
      if (data && data.valid) {
        temp.creditcardtype = data.creditcardtype
        temp.number = data.number
        temp.expmonth = data.expmonth
        temp.expyear = data.expyear
        temp.cvc = data.cvc
      } else {
        temp.creditcardtype = null
        temp.number = null
        temp.expmonth = null
        temp.expyear = null
        temp.cvc = null
      }
      temp.valid = data.valid
      temp.cardedit = data.cardedit

      this.form.billing = Object.assign({}, temp)
    },

    getFormState (field) {
      if (this.isRegistration) {
        return !this.$v.form.billing[field].$error
      } else {
        return !this.$v.form.billing[field].$error ? null : false
      }
    }
  },

  computed: {
    isNew () {
      return (!this.billingrecord || !this.billingrecord.id)
    },

    isValid () {
      return (!this.$v.form.$invalid && this.form.billing.valid && (this.form.billingAddressSame || this.form.billingAddress.valid))
    }
  },

  watch: {
    name () {
      this.form.billing.name = this.name
    },

    form: {
      deep: true,
      handler (n) {
        this.$emit('update', n)
      }
    }
  },

  mixins: [validationMixin],

  validations: {
    form: {
      billing: {
        name: {
          required
        }
      }
    }
  }

}
</script>
