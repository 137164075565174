import UserService from '@/services/UserService'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { bus } from '@/main'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { public: true, noAuth: true },
    component: require('@/views/Home').default
  },
  {
    path: '/inactive',
    name: 'Inactive Network',
    meta: { public: true, noAuth: true },
    component: require('@/views/Inactive').default
  },
  {
    path: '/faq',
    name: 'FAQ',
    meta: { title: 'FAQ', public: true },
    component: require('@/views/FAQ').default
  },
  {
    path: '/about',
    name: 'About',
    meta: { title: 'About Us', public: true },
    component: require('@/views/About').default
  },
  {
    path: '/privacy',
    name: 'Privacy',
    meta: { title: 'Privacy Policy', public: true },
    component: require('@/views/Privacy').default
  },
  {
    path: '/terms',
    name: 'Terms',
    meta: { title: 'Terms of Use', public: true },
    component: require('@/views/Terms').default
  },
  {
    path: '/copyright',
    name: 'Copyright',
    meta: { title: 'Copyright Policy', public: true },
    component: require('@/views/Copyright').default
  },
  {
    path: '/account',
    name: 'My Account',
    meta: { title: 'My Account' },
    component: require('@/views/account/MyAccount').default
  },
  {
    path: '/account/subscription',
    name: 'My Subscription',
    meta: { title: 'My Subscription' },
    component: require('@/views/account/Subscription').default
  },
  {
    path: '/account/billing',
    name: 'Billing Information',
    meta: { title: 'Billing Information' },
    component: require('@/views/account/Billing').default
  },
  {
    path: '/forgot',
    name: 'Forgot Password',
    meta: { title: 'Forgot Password', public: true, noAuth: true },
    component: require('@/views/ForgotPassword').default
  },
  {
    path: '/subscribe',
    name: 'Subscribe No Type',
    meta: { title: 'Subscribe', public: true, noAuth: true },
    component: require('@/views/Register').default
  },
  {
    path: '/subscribe/:subtype',
    props: true,
    name: 'Subscribe',
    meta: { title: 'Subscribe', public: true, noAuth: true },
    component: require('@/views/Register').default
  },
  {
    path: '/live/:id?',
    props: true,
    name: 'Schedule',
    meta: { title: 'Live Stream' },
    component: require('@/components/Live').default
  },
  {
    path: '/video',
    name: 'Video Search',
    meta: { title: 'Video Search' },
    component: require('@/views/Search').default
  },
  {
    path: '/video/channel',
    props: true,
    name: 'Channel List',
    meta: { title: 'Channels' },
    component: require('@/components/ChannelList').default
  },
  {
    path: '/video/channel/:id',
    props: true,
    name: 'Channel Videos',
    meta: { title: 'Videos' },
    component: require('@/views/Channel').default
  },
  {
    path: '/video/watch/:id',
    props: true,
    name: 'Watch Video',
    meta: { title: 'Videos', jumbotron: 'false' },
    component: require('@/views/Video').default
  },
  {
    path: '/sso/:id/:hash/:timestamp',
    props: true,
    name: 'SSO',
    meta: { title: 'SSO', public: true },
    component: require('@/views/account/SingleSignOn').default
  },
  {
    path: '/invalid-network',
    name: 'Invalid Network',
    meta: { title: 'Not Found', public: true },
    component: require('@/components/InvalidNetwork').default
  },
  {
    path: '*',
    name: 'Not Found',
    component: require('@/components/NotFound').default
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.path !== from.path) {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  const userInfo = UserService.getStorage('user')
  const networkInfo = UserService.getStorage('network')
  const loggedIn = (userInfo && userInfo.apikey !== null)

  if (networkInfo && +networkInfo.active !== 1) {
    localStorage.clear()
    bus.$emit('loggedOut')
    return next({ path: '/inactive' })
  }

  if (to.path.includes('/inactive') && networkInfo && +networkInfo.active === 1) {
    return next({
      path: '/'
    })
  }

  if (!to.meta.public && !loggedIn) {
    return next({
      path: '/',
      query: {
        errorTitle: 'Please Login',
        errorMessage: 'You need to login to access that page'
      }
    })
  }

  if (to.meta.noAuth && loggedIn) {
    return next('/video/channel')
  }

  if (!to.path.includes('video/channel')) {
    bus.$emit('clearVideo')
  }

  let channelId = null
  if (to.path === '/video/channel' || to.path === '/video/channel/') {
    channelId = 0
  } else if (to.path.includes('video/channel')) {
    channelId = to.params.id
  }

  bus.$emit('setChannel', channelId)

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  // const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title
  }
  if (networkInfo) {
    document.title += ' - ' + networkInfo.name
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next()

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key])
    })

    // We use this to track which meta tags we create, so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    // Add the meta tags to the document head.
    .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
