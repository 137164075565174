import axios from 'axios'
import Widgets from '@/services/Widgets'

const VideoService = {
  async channelVideos (data) {
    const params = Widgets.JSON_to_URLEncoded(data)
    return axios.post(axios.defaults.baseURL + 'video/videolist/channel',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async search (data) {
    const params = Widgets.JSON_to_URLEncoded(data)
    return axios.post(axios.defaults.baseURL + 'video/search',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async viewed (id, duration, viewed, adminView, Stopped) {
    const data = {
      videodistribution_id: id,
      duration: duration,
      id: +viewed,
      adminview: +adminView,
      stopped: Stopped
    }

    const params = Widgets.JSON_to_URLEncoded(data)
    return axios.post(axios.defaults.baseURL + 'video/viewed',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async fetch (id) {
    const data = {
      videodistribution_id: id
    }

    const params = Widgets.JSON_to_URLEncoded(data)
    return axios.post(axios.defaults.baseURL + 'video/fetch',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  },

  async favorite (id) {
    const data = {
      videodistribution_id: id
    }

    const params = Widgets.JSON_to_URLEncoded(data)
    return axios.post(axios.defaults.baseURL + 'video/toggle/favorite',
      params,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }
    )
  }
}

export default VideoService
