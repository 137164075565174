<template>
  <b-card
    v-if="subscription && (subscription.credit > 0 || subscription.remainingvalue > 0)"
    no-body
  >
    <b-card-header class="m-0">
      Payment Breakdown:
      <small v-show="subscription.localcredit.localcurrency !== 'USD'">* breakdown shown in USD to reflect the actual charges to be made</small>
    </b-card-header>

    <b-card-body class="p-0">
      <Loading :loading="loading"></Loading>
      <table
        v-show="!loading"
        class="table table-condensed m-0"
      >
        <colgroup>
          <col />
          <col width="0*" />
        </colgroup>
        <tbody>
          <tr
            v-if="subscription.remainingvalue > 0"
            class="border-top-0"
          >
            <td class="text-right border-top-0">
              Value remaining in current subscription:<br />
              <small>(Pro-rated off current subscription age and amount)</small>
            </td>
            <td class="text-right text-success align-middle border-top-0 font-weight-bold">
              {{ subscription.localremainingvalue.localcurrencyformatted }}
            </td>
          </tr>
          <tr v-if="subscription.existingCredit > 0">
            <td class="text-right">Account Credit:</td>
            <td class="text-right text-success align-middle font-weight-bold">
              {{ subscription.localexistingCredit.localcurrencyformatted }}
            </td>
          </tr>
          <tr v-if="subscription.remainingValue > 0 && subscription.existingCredit > 0">
            <td class="text-right">Total Credit</td>
            <td class="text-right text-success align-middle font-weight-bold">
              {{ subscription.localcredit.localcurrencyformatted }}
            </td>
          </tr>
          <tr>
            <td class="text-right">New Subscription Amount:</td>
            <td class="text-right align-middle font-weight-bold">
              {{ subscription.localamount.localcurrencyformatted }}
            </td>
          </tr>
          <tr v-if="discount && discount.amount > 0">
            <td class="text-right">Discount Applied:</td>
            <td class="text-right text-warning align-middle font-weight-bold">
              ({{ discount.localcurrencyformatted }})
            </td>
          </tr>
          <tr v-show="subscription.appliedCredit > 0">
            <td class="text-right">Credit Applied:</td>
            <td class="text-right text-warning align-middle font-weight-bold">
              ({{ subscription.localappliedCredit.localcurrencyformatted }})
            </td>
          </tr>
          <tr>
            <td class="text-right">Balance Due:</td>
            <td
              class="text-right align-middle font-weight-bold"
              :class="subscription.billingamount > 0 ? 'text-danger' : ''"
            >
              {{ subscription.localbillingamount.localcurrencyformatted }}
            </td>
          </tr>
          <tr
            class="success"
            v-if="subscription.remainingCredit > 0"
          >
            <td class="text-right">
              Credit left over:<br />
              <small>(This amount will be applied to the next billing cycle until depleted)</small>
            </td>
            <td class="text-right align-middle">
              {{ subscription.localremainingCredit.localcurrencyformatted }}
            </td>
          </tr>
        </tbody>
      </table>
    </b-card-body>
  </b-card>
</template>

<script>
import Loading from '@/components/widget/Loading'

export default {
  name: 'Breakdown',
  components: {
    Loading
  },
  props: {
    subscription: {
      type: Object,
      default () { return null }
    },
    discount: {
      type: Object,
      default () { return null }
    },
    loading: {
      type: Boolean,
      default () { return false }
    }
  }
}
</script>
